import { getCurrentLocale } from '@/app/bi/utils/formatDate';

const GENERAL = 'general';

const toPlural = (n: number, plural: object): string => {
  const locale = getCurrentLocale();

  if (GENERAL in plural) {
    return plural.general as string;
  }

  const pluralRules = new Intl.PluralRules(locale);
  const key = pluralRules.select(n);

  if (key in plural) {
    return plural[key as keyof typeof plural];
  }

  return '';
};

export default toPlural;
