import { ReactNode } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISideSliceStore {
  show: boolean,
  renderFn(): ReactNode
}

const initialState: ISideSliceStore = {
  show: false,
  renderFn: () => null,
};

export const sideSlice = createSlice({
  name: 'sideSlice',
  initialState,
  reducers: {
    setShow(state, action: PayloadAction<boolean>) {
      state.show = action.payload;
    },
    setRenderFn(state, action: PayloadAction<() => ReactNode>) {
      state.renderFn = action.payload;
    },
  },
});

export default sideSlice.reducer;
export const {
  setShow,
  setRenderFn,
} = sideSlice.actions;
