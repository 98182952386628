import { getText } from '@/i18n';

export const PLACEHOLDER_EMPLOYEE_INPUT = {
  FIRST_NAME: getText('settings:firstName'),
  LAST_NAME: getText('settings:lastName'),
  EMAIL: 'email@gmail.com',
};

export const INIT_GENERAL_STEP_ERROR = {
  email: '',
  birthDay: '',
  phoneNumber: '',
  citizenship: '',
};

export const INIT_FULL_NAME_ERROR = {
  firstName: '',
  lastName: '',
  firstNameTranslit: '',
  lastNameTranslit: '',
};
