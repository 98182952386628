import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, Price, StatusMarker } from 'yw-ui';
import { IconType } from 'yw-ui/components/Icon/types';

import { getText } from '@/i18n';

import { formatDate, formatRangeDateWithSimplicity, toLocalDate } from '@/app/bi/utils/formatDate.ts';

import ROUTES from '@/app/bi/constants/routes.ts';
import { SERVICE_ICON } from '@/app/bi/constants/trips.ts';
import { PATTERN } from '@/app/bi/constants/dateFormats.ts';
import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy.ts';

import { ITripPreview } from '@/app/bi/models/tripsTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './index.module.css';

const LABELS = {
  EMPOLOYEES_ON_TRIP: getText('trips:employeesOnTrip'),
  CHANGED: getText('trips:changed'),
};

interface ItemTripProps {
  data: ITripPreview
}

const ItemTrip = ({ data: {
  id,
  name,
  changedDate,
  checkinDate,
  checkoutDate,
  amount,
  employees,
  serviceTypes,
  status,
} }: ItemTripProps) => {
  const navigation = useNavigate();

  const localChangeDate = toLocalDate(changedDate);

  const countPerson = employees.length;
  const formingDateToTrip = formatRangeDateWithSimplicity(checkinDate, checkoutDate);
  const formingTravelCreateDate = `${LABELS.CHANGED}: ${formatDate(localChangeDate, PATTERN.DATE_TIME_WITHOUT_SECONDS)}`;

  const handleLinkItem = () => navigation(`${ROUTES.TRIP.MAIN}/${id}`);

  const renderPoint = (index: number) => (index < countPerson - 1 && (
    <div className={ styles.point_container }>
      <div className={ styles.point } />
    </div>
  ));

  const renderServiceType = () => serviceTypes.map((
    service,
    index,
  ) => (
    <Icon
      key={ `${service}_${index}` }
      type={ SERVICE_ICON[service] as IconType }
      size={ 24 }
    />
  ));

  const renderListPerson = () => (
    <div className={ styles.person_container }>
      {employees?.map(({ id: userId, fullName }, index) => (
        <div key={ userId } className={ styles.wrap_item_person }>
          <span className={ styles.item_person }>
            {fullName}
          </span>
          {renderPoint(index)}
        </div>
      ))}
    </div>
  );

  return (
    <div onClick={ handleLinkItem } className={ styles.container }>
      <div className={ styles.wrapper }>
        <div className={ styles.header }>
          <StatusMarker status={ status } />
          <div className={ styles.icon_container }>
            {renderServiceType()}
          </div>
        </div>
        <div className={ styles.container_info }>
          <div>
            <h2 className={ styles.title_trip }>{name}</h2>
            <div className={ styles.date }>
              {formingDateToTrip}
            </div>
          </div>
          <div className={ styles.travel_info_container }>
            <div className={ styles.travel_price }>
              <Price
                typeCurrency='bold_18'
                marginSmall
                costType={ CURRENCY_SYMBOLS[ECurrencyCode.TRY] }
                type='bold_18'
                value={ amount }
              />
            </div>
            <div className={ styles.travel_create }>
              {formingTravelCreateDate}
            </div>
          </div>
        </div>
        <div className={ styles.wrapper_person_info }>
          <span className={ styles.header_person_info }>
            {`${LABELS.EMPOLOYEES_ON_TRIP}: ${countPerson}`}
          </span>
          {renderListPerson()}
        </div>
      </div>
    </div>
  );
};

export { ItemTrip };
