import React from 'react';
import { Button, Dialog, Text } from 'yw-ui';

import { getText } from '@/i18n';

import styles from './index.module.css';

const LABELS = {
  DIALOG_DESCRIPTION: getText('components:tripName.dialog.description'),
  OK: getText('common:ok'),
  CANCEL: getText('common:cancel'),
  DIALOG_TITLE: getText('components:tripName.dialog.title'),
};

interface DialogRemoveAllProps {
  show: boolean,
  loading: boolean,
  onDelete(): void,
  onShowDialog(value: boolean): void,
}
const DialogRemoveAll = ({
  show = false,
  loading,
  onDelete,
  onShowDialog,
}: DialogRemoveAllProps) => (
  <Dialog show={ show } onChange={ onShowDialog } showClosing>
    <div className={ styles.wrapper }>
      <Text type='bold_20'>{ LABELS.DIALOG_TITLE }</Text>
      <Text className={ styles.text } type='NORMAL_14_130'>
        { LABELS.DIALOG_DESCRIPTION }
      </Text>
      <div className={ styles.actions }>
        <Button type='primary' onClick={ onDelete } loading={ loading }>
          { LABELS.OK }
        </Button>
        <Button
          className={ styles.cancel }
          type='text'
          onClick={ () => onShowDialog(false) }
        >
          { LABELS.CANCEL }
        </Button>
      </div>
    </div>
  </Dialog>
);

export { DialogRemoveAll };
