import * as zod from 'zod';

const bonusCardBaseSchema = {
  id: zod.string().optional(),
  employeeId: zod.string().optional(),
  airlineCode: zod.string().trim(),
  number: zod.string().trim(),
};

export const bonusCardSchema = zod.object({
  id: bonusCardBaseSchema.id,
  employeeId: bonusCardBaseSchema.employeeId,
  airlineCode: bonusCardBaseSchema.airlineCode.refine((value) => value !== ''),
  number: bonusCardBaseSchema.number.refine((value) => value !== ''),
});

export const schema = zod.object({
  bonusCards: zod.object({
    ...bonusCardBaseSchema,
  }).array(),
});

export type SchemaType = zod.infer<typeof schema>;
