import { ITotalPrice, TCartResponseItem } from '@/app/bi/models/cart.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

export const updateItem = (
  item: TCartResponseItem,
  list: TCartResponseItem[],
): TCartResponseItem[] => {
  const index = list.findIndex((cartItem) => cartItem.id === item.id);
  const newList = [...list];

  newList[index] = item;

  return newList;
};

const checkDocument = (
  item: TCartResponseItem,
) => (item.serviceType === EServiceTypes.Airline ? item.employees.every(({
  document,
}) => document) : true);

export const isEmployeeListValid = (item: TCartResponseItem) => {
  const employeesLength = item.employees.length;

  switch (item.serviceType) {
    case EServiceTypes.Airline: {
      return employeesLength === item.travellersCount && item.employees.length
        && item.employees.every(({
          company,
        }) => company)
        && checkDocument(item);
    }
    case EServiceTypes.Hotel: {
      return item.employees.length && item.employees.some(({
        company,
      }) => company);
    }
    default: return false;
  }
};

export const idUnlockCart = (items: TCartResponseItem[]) => items.every(isEmployeeListValid);

export const getTotalPrice = (items: TCartResponseItem[]): ITotalPrice =>
  items.reduce((acc, {
    price,
    serviceType,
  }) => {
    if (serviceType === EServiceTypes.Airline) {
      return { ...acc, airlinesPrice: acc.airlinesPrice + price };
    }

    if (serviceType === EServiceTypes.Hotel) {
      return { ...acc, hotelsPrice: acc.hotelsPrice + price };
    }

    return acc;
  }, { airlinesPrice: 0, hotelsPrice: 0 });

export const getShowPrice = (items: TCartResponseItem[]) => {
  const showHotelsPrice = items
    .some(({ serviceType }) => serviceType === EServiceTypes.Hotel);
  const showAirlinesPrice = items
    .some(({ serviceType }) => serviceType === EServiceTypes.Airline);

  return { showHotelsPrice, showAirlinesPrice };
};
