import { Dayjs } from 'dayjs';

import { ETypesSex } from '@/app/bi/constants/settings.ts';

export enum EEmployeeDocumentStatus {
  Active = 'Active',
  Archived = 'Archived',
}

export enum EEmployeeDocumentType {
  IdCard = 'IdCard',
  ForeignPassport = 'ForeignPassport', // загран и паспорт турции
  DomesticPassport = 'DomesticPassport', // паспорт иностранного граж
}

export enum EEmployeeSortedBy {
  Name = 'Name',
  Phone = 'Phone',
  Email = 'Email',
  Company = 'Company',
}

export enum EEmployeeFilterItem {
  SearchEmployee = 'SearchEmployee',
  Company = 'Company',
}

export interface IEmployeeObj {
  id: string;
  actualVersion: IEmployeeVersion;
  companies: ICompanyAutocomplete[];
  documents: IEmployeeDocument[];
  versions: IEmployeeVersion[];
  bonusCards: IEmployeeBonusCard[];
}

export interface IEmployeeBonusCard {
  id?: string;
  employeeId?: string;
  airlineCode: string;
  number: string;
}

export type IAddEmployeeBonusCard = Pick<IEmployeeBonusCard, 'airlineCode' | 'number'>;

export interface ICompany {
  id: number;
  accountId: number;
  name: string;
  shortName: string;
  vkn: string;
  address: string;
  seo: string;
}

export interface IEmployeeVersion {
  id: string;
  employeeId: string;
  createdDate: string;
  creatorUserId: string;
  phoneNumber: string;
  countryCode: string;
  birthday: string;
  sex: ETypesSex;
  firstName: string;
  lastName: string;
  email: string;
  status: EEmployeeDocumentStatus;
  travelPolicyId: string | null;
  isTurkeyCitizen: boolean;
}

export interface ICompanyAutocomplete {
  id: number,
  accountId: number,
  name: string,
  shortName: string,
  vkn: string,
  address: string,
  seo: string,
}

export interface IEmployeeDocumentVersion {
  id: string;
  employeeDocumentId: string;
  creatorUserId: string;
  createdDate: string;
  status: EEmployeeDocumentStatus;
  type: EEmployeeDocumentType;
  number: string;
  lastName: string;
  firstName: string;
  lastNameTranslit: string,
  firstNameTranslit: string,
  expiryDate: Dayjs | null;
  countryCode: string;
}

export interface IEmployeeUniversal {
  id: string;
  lastName: string;
  firstName: string;
  number: string | null;
  type: EEmployeeDocumentType | null;
  company: IEmployeeUniversalCompany | null;
}

export interface IEmployeeUniversalCompany {
  id: number;
  name: string;
  shortName: string | null;
}

export interface IEmployeeDocument {
  id: string;
  employeeId: string;
  actualVersion: IEmployeeDocumentVersion;
  versions: IEmployeeDocumentVersion[] | null;
}

export interface IEmployeeDocumentModified {
  id: string;
  employeeId: string;
  hasBeenChangedData: boolean;
  actualVersion: IEmployeeDocumentVersion;
  versions: IEmployeeDocumentVersion[] | null;
}

export interface IEmployeeFilter {
  filterSort: {
    sortedBy: EEmployeeSortedBy;
    descending: boolean;
  };
  companies: number[];
  page: number;
}

export interface IEmployeeFilterResponse {
  items: IEmployeeObj[];
  filterRequest: IEmployeeFilter;
  page: number;
  pages: number;
  pageLimit: number;
  itemsCount: number;
}
