import React from 'react';
import { Text } from 'yw-ui';

import { getTextPolicy } from '@/app/bi/utils/travelPolicy.ts';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { EAirlineValidationError } from '@/app/bi/models/airlineTypes.ts';
import { EHotelValidationError } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

interface IListPolicyErrorsProps {
  typeContent: EServiceTypes;
  policyErrors: EAirlineValidationError[] | EHotelValidationError[];
  listTravelPolicies: ITravelPolicy[];
}

export const ListPolicyErrors = ({
  typeContent,
  policyErrors = [],
  listTravelPolicies = [],
}: IListPolicyErrorsProps) => {
  const renderContent = () => policyErrors.map((
    textPolicy,
  ) => (
    <li key={ textPolicy }>
      <Text color='white' type='NORMAL_14'>
        {getTextPolicy(textPolicy, typeContent, listTravelPolicies)}
      </Text>
    </li>
  ));

  return renderContent();
};
