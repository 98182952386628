import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { cartApi } from '@/app/bi/api/cartApi.ts';

import {
  getTotalPrice,
  idUnlockCart,
  getShowPrice,
  updateItem,
  isEmployeeListValid,
} from '@/app/store/utils/cart.ts';
import { getCartErrors } from '@/app/bi/utils/cart.ts';

import { IBookResultResponse } from '@/app/bi/models/booking.ts';
import { ICartErrors, TCartResponseItem } from '@/app/bi/models/cart.ts';

interface CartState {
  airlinesPrice: number;
  hotelsPrice: number;
  totalPrice: number;
  unlock: boolean;
  showErrorDialog: boolean;
  bookingErrors: ICartErrors;
  items: TCartResponseItem[];
  showHotelsPrice: boolean;
  showAirlinesPrice: boolean;
}

const initialState: CartState = {
  airlinesPrice: 0,
  hotelsPrice: 0,
  totalPrice: 0,
  unlock: true,
  showErrorDialog: false,
  bookingErrors: {
    hasErrors: false,
    bookingErrors: [],
    cartItemErrors: [],
  },
  items: [],
  showHotelsPrice: false,
  showAirlinesPrice: false,
};

export const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    updateTripItem(state, action: PayloadAction<TCartResponseItem>) {
      state.items = updateItem(action.payload, state.items);
      state.unlock = state.items.every((item) => isEmployeeListValid(item));
    },
    removeTripItem(state, action: PayloadAction<{ itemId: string }>) {
      state.items = state.items.filter((item) => item.id !== action.payload.itemId);

      const {
        airlinesPrice,
        hotelsPrice,
      } = getTotalPrice(state.items);
      const {
        showHotelsPrice,
        showAirlinesPrice,
      } = getShowPrice(state.items);

      state.showHotelsPrice = showHotelsPrice;
      state.showAirlinesPrice = showAirlinesPrice;
      state.airlinesPrice = airlinesPrice;
      state.hotelsPrice = hotelsPrice;
      state.totalPrice = state.airlinesPrice + state.hotelsPrice;
      state.unlock = idUnlockCart(state.items);
    },
    updateCartErrors(state, action: PayloadAction<IBookResultResponse>) {
      state.bookingErrors = getCartErrors(action.payload) as ICartErrors;

      if (action.payload.hasErrors) {
        state.showErrorDialog = true;
      }
    },
    updateCartError(state, action: PayloadAction<string>) {
      state.bookingErrors.cartItemErrors = state.bookingErrors.cartItemErrors
        .filter(({ itemId }) => itemId !== action.payload);
    },
    setShowErrorDialog(state, { payload }: PayloadAction<boolean>) {
      state.showErrorDialog = payload;
    },
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      cartApi.endpoints.getCart.matchFulfilled,
      (state, { payload }) => {
        const {
          airlinesPrice,
          hotelsPrice,
          totalPrice,
          items,
        } = payload;
        state.airlinesPrice = airlinesPrice;
        state.hotelsPrice = hotelsPrice;
        state.totalPrice = totalPrice;
        state.items = items;

        const { showHotelsPrice, showAirlinesPrice } = getShowPrice(items);

        state.showHotelsPrice = showHotelsPrice;
        state.showAirlinesPrice = showAirlinesPrice;
        state.unlock = idUnlockCart(items);
      },
    );
  },
});

export default cartSlice.reducer;
export const {
  updateTripItem,
  updateCartError,
  removeTripItem,
  updateCartErrors,
  resetStore,
  setShowErrorDialog,
} = cartSlice.actions;
