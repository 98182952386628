import React from 'react';
import { Button, Icon, LinkButton, PageLoader, Price, Text } from 'yw-ui';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { getText } from '@/i18n';

import { ItemPanelCollapse } from '@/app/pages/Checkout/components/ItemPanelCollapse';
// import { SendToEmailDialog } from '@/app/pages/SuccessBooking/components/SendToEmailDialog';
import { CardAirLine } from '@/app/pages/TripsDetail/components/Cards/CardAirLine';
import { CardHotel } from '@/app/pages/TripsDetail/components/Cards/CardHotel';

// import { downloadFile } from '@/app/bi/utils/downloads.ts';

import ROUTES from '@/app/bi/constants/routes.ts';
import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy';

import { IAirlineTripItems, IHotelTripItems } from '@/app/bi/models/tripsTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum';

// import {
//   useDownloadMutation,
//   useLazyGetTripAirlineIdQuery,
//   useSendEmailAirlineMutation,
// } from '@/app/bi/api/documentApi.ts';
// import { EDocumentType, EPrefixFileName } from '@/app/bi/models/documents.ts';
import { useGetBookingByIdQuery } from '@/app/bi/api/bookingApi.ts';
// import { useAppSelector } from '@/app/store/hooks/redux.ts';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('checkout:successBooking.tripsPayed'),
  BACK_TO_MAIN_PAGE: getText('checkout:successBooking.backToMainPage'),
  GO_TO_TRIPS: getText('checkout:successBooking.backToTripsPage'),
  SEND_TO_EMAIL: getText('checkout:successBooking.sendToEmail'),
  DOWNLOAD: getText('checkout:successBooking.download'),
};

const SuccessBooking = () => {
  const navigate = useNavigate();
  const { bookId } = useParams();
  // const locale = useAppSelector((state) => state.appSlice.locale);
  //
  // const [showModal, setShowModal] = useState(false);
  // const [selectId, setSelectId] = useState('');

  const {
    data: trips = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetBookingByIdQuery(bookId as string);

  // const [sendEmail, { isLoading: isLoadingEmail }] =
  //   useSendEmailAirlineMutation();
  // const [downloadDocument] =
  //   useDownloadMutation();
  // const [getTripDocumentId] = useLazyGetTripAirlineIdQuery();

  if (isLoading && !isSuccess) return <PageLoader />;

  if (isError) return <Navigate to='/cart' />;

  const goToMainPage = () => navigate(ROUTES.HOMEPAGE);
  const goToTripsPage = () => navigate(ROUTES.TRIPS.MAIN);

  const renderActionsPanel = () => (
    <div className={ styles.action_panel }>
      <div className={ styles.content }>
        <div className={ styles.actions }>
          <Button
            type='secondary'
            size='small'
            onClick={ goToMainPage }
          >
            { LABELS.BACK_TO_MAIN_PAGE }
          </Button>
          <LinkButton
            theme='blue-without-border'
            onClick={ goToTripsPage }
          >
            <Text color='light-blue-4' type='NORMAL_14'>
              { LABELS.GO_TO_TRIPS }
            </Text>
          </LinkButton>
        </div>
      </div>
    </div>
  );

  // TODO Вернуть и привязать запрос на груповое скачивание (задача запланирована)
  // const handleCloseDialog = () => {
  //   setShowModal(false);
  // };

  // const handleOpenDialog = (id: string) => {
  //   setSelectId(id);
  //   setShowModal(!showModal);
  // };

  // const handleSendEmail = (email: string) => {
  //   const body = {
  //     itemId: selectId,
  //     email,
  //     locale,
  //     fileType: EDocumentType.Pdf,
  //   };
  //
  //   sendEmail(body);
  // };

  // const handleDownload = async (id: string) => {
  //   const documentId = await getTripDocumentId({
  //     itemId: id,
  //     locale,
  //     fileType: EDocumentType.Pdf,
  //   }).unwrap();
  //
  //   const responseFile = await downloadDocument(documentId).unwrap();
  //
  //   await downloadFile({
  //     fileBlob: responseFile,
  //     fileType: EDocumentType.Pdf,
  //     filenamePrefix: EPrefixFileName.Trip,
  //     identifier: 'downloadFile',
  //   });
  // };

  // const renderHeaderActions = (id: string) => (
  //   <div className={ styles.actions }>
  //     <Button
  //       type='text'
  //       onClick={ () => handleOpenDialog(id) }
  //       className={ styles.button_header }
  //     >
  //       {LABELS.SEND_TO_EMAIL}
  //     </Button>
  //
  //     <Button
  //       type='text'
  //       className={ styles.button_download }
  //       onClick={ () => handleDownload(id) }
  //     >
  //       {LABELS.DOWNLOAD}
  //       {/* {isResponseId ? ( */}
  //       {/*   <div className={ styles.loading_container }><ItemLoader /></div> */}
  //       {/* ) : LABELS.DOWNLOAD} */}
  //     </Button>
  //   </div>
  // );

  const renderHeader = (tripName: string | null, amount: number, currency: ECurrencyCode) => (
    <div className={ styles.header }>
      <div className={ styles.title } >
        <Text type='bold_22' fontWeight={ 700 }>{ tripName }</Text>
        <div className={ styles.icons } >
          <Icon type='serviceAir' circleColor='white'/>
        </div>
      </div>
      <div className={ styles.price }>
        <Price
          value={ amount }
          type='bold_22'
          typeCurrency='bold_22'
          costType={ CURRENCY_SYMBOLS[currency] }
          marginSmall
        />
      </div>
    </div>
  );

  // const renderDialog = () => (
  //   <SendToEmailDialog
  //     show={ showModal }
  //     isLoading={ isLoadingEmail }
  //     onClose={ handleCloseDialog }
  //     onSend={ handleSendEmail }
  //   />
  // );

  const renderAirlineTrip = (airlineTripItems: IAirlineTripItems[]) =>
    airlineTripItems.map((item) =>
      (
        <CardAirLine key={ item.id } data={ item } />
      ),
      // (
      //   <CheckoutItem
      //     index={ index }
      //     key={ actualVersion.id }
      //     // @ts-ignore
      //     item={ actualVersion }
      //     serviceType={ EServiceTypes.Airline }
      //   />
      // ),
    );

  const renderHotelTrip = (hotelTripItems: IHotelTripItems[]) => hotelTripItems.map((item) => (
    <CardHotel key={ item.id } data={ item } />
  ));

  const renderTrips = () => trips.map(({
    id,
    name,
    airlineTripItems,
    hotelTripItems,
    amount,
    currency,
  }) => (
    <ItemPanelCollapse
      key={ id }
      renderHeader={ () => renderHeader(name, amount, currency) }
      // renderActions={ () => renderHeaderActions(id) }
    >
      {renderAirlineTrip(airlineTripItems)}
      {renderHotelTrip(hotelTripItems)}
    </ItemPanelCollapse>
  ));

  const renderContent = () => (
    <>
      <div className={ styles.header }>
        <Text type='bold_32' fontWeight={ 700 } lineHeight={ '110%' }>
          {LABELS.TITLE}
        </Text>
      </div>
      <div className={ styles.content }>
        <div className={ styles.items_wrapper }>
          { renderTrips()}
        </div>
      </div>
    </>
  );

  return (
    <div className={ styles.wrapper }>
      { renderContent() }
      { renderActionsPanel() }
      {/* { renderDialog() } */}
    </div>
  );
};

export { SuccessBooking };
