import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ItemLoader } from 'yw-ui';

import { ItemTrip } from '@/app/pages/Trips/components/ItemTrip';
import { EmptyDataPanel } from '@/app/components/EmptyDataPanel';

import ROUTES from '@/app/bi/constants/routes.ts';

import { ITripPreview } from '@/app/bi/models/tripsTypes.ts';

import styles from './index.module.css';

const createLabels = (t: TFunction) => ({
  CREATE_TRIP: t('trips:createTrip'),
  NO_TRIPS_FOUND: t('trips:emptyData.noTripsFound'),
  CREATE_FIRST_TRIP: t('trips:emptyData.createFirstTrip'),
});

interface ListTripsProps {
  data: ITripPreview[];
  loading: boolean;
}

const ListTrips = ({ data = [], loading }: ListTripsProps) => {
  const navigation = useNavigate();

  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const handleClickButtonEmptyPanel = () => navigation(ROUTES.HOMEPAGE);

  if (loading) {
    return (
      <div className={ styles.loading }>
        <ItemLoader />
      </div>
    );
  }

  const renderList = () => (
    data.map((trip: ITripPreview) => <ItemTrip key={ trip.id } data={ trip } />)
  );

  if (!data.length) {
    return (
      <div className={ styles.empty_data_panel }>
        <EmptyDataPanel
          title={ LABELS.NO_TRIPS_FOUND }
          subTitle={ LABELS.CREATE_FIRST_TRIP }
          buttonTitle={ LABELS.CREATE_TRIP }
          onChangeButton={ handleClickButtonEmptyPanel }
        />
      </div>
    );
  }

  return (
    <div className={ styles.container }>
      {renderList()}
    </div>
  );
};

export { ListTrips };
