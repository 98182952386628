import React from 'react';
import { Button, Icon, Price, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy';

import { ICheckoutData } from '@/app/bi/models/booking.ts';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('components:totalBlock.title'),
  PURCHASE: getText('components:totalBlock.purchase'),
  TOTAL: getText('components:totalBlock.total'),
  PAY: getText('components:totalBlock.pay'),
  HOTELS: getText('components:totalBlock.types.hotel'),
  AIRLINE: getText('components:totalBlock.types.air'),
  ATTENTION: getText('components:totalBlock.attention'),
};

interface TotalBlockProps {
  hotelsPrice: number,
  airlinesPrice: number
  totalPrice: number,
  showHotelsPrice: boolean,
  showAirlinesPrice: boolean,
  disabledButton?: boolean,
  isCart?: boolean,
  loading?: boolean,
  checkoutData?: ICheckoutData,
  onClick():void,
}

const TotalBlock = ({
  hotelsPrice,
  airlinesPrice,
  totalPrice,
  showHotelsPrice,
  showAirlinesPrice,
  disabledButton = false,
  isCart = false,
  loading = false,
  checkoutData = {
    canBook: false,
    errors: [],
  },
  onClick,
}: TotalBlockProps) => {
  const buttonTitle = isCart ? LABELS.PURCHASE : LABELS.PAY;

  const renderBookErrorMsg = () => {
    if (isCart || checkoutData?.canBook) return null;

    return (
      <div className={ styles.book_unavailable }>
        <Icon type='warning' className={ styles.icon }/>
        <div className={ styles.messages }>
          {checkoutData.errors.map((message, index) => (
            <Text
              key={ `checkout-error-${index}}` }
              color='red'
              type='NORMAL_14'
              className={ styles.message }
            >
              {message}
            </Text>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_24' color='gray-7'>{LABELS.TITLE}</Text>
      <div className={ styles.content }>
        <div className={ styles.trip_items }>
          {showAirlinesPrice && (
            <div className={ styles.row }>
              <Text type='NORMAL_16' color='gray-7'>
                {LABELS.AIRLINE}
              </Text>
              <Price
                value={ airlinesPrice }
                type='bold_16'
                typeCurrency='bold_16'
                marginSmall
                color='gray-7'
                className={ styles.price }
                costType={ CURRENCY_SYMBOLS.TRY }
              />
            </div>
          )}
          {showHotelsPrice && (
            <div className={ styles.row }>
              <Text type='NORMAL_16' color='gray-7'>{LABELS.HOTELS}</Text>
              <Price
                value={ hotelsPrice }
                type='bold_16'
                typeCurrency='bold_16'
                marginSmall
                color='gray-7'
                costType={ CURRENCY_SYMBOLS.TRY }
              />
            </div>
          )}
        </div>
        <div className={ styles.row }>
          <Text type='bold_24'>{LABELS.TOTAL}</Text>
          <Price
            value={ totalPrice }
            type='bold_18'
            typeCurrency='bold_18'
            marginSmall
            color='gray-7'
            costType={ CURRENCY_SYMBOLS.TRY }
          />
        </div>
      </div>
      <Button
        type='primary'
        size='large'
        disabled={ disabledButton }
        onClick={ onClick }
        loading={ loading }
      >
        { buttonTitle }
      </Button>
      {!isCart && checkoutData?.canBook && (
        <Text type='NORMAL_14' color='light-blue-4' >{
          LABELS.ATTENTION}
        </Text>
      )}
      {renderBookErrorMsg()}
    </div>
  );
};

export { TotalBlock };
