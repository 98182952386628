import React, { useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { Suggest } from 'yw-ui';
import { SuggestItem, SuggestItems } from 'yw-ui/components/Suggest/types';

import { useGetAirlineInfoQuery } from '@/app/bi/api/airlineApi';

import { FieldLabel } from '@/app/components/FieldLabel';

import { AIRLINE_CODE, BONUS_CARDS } from '../../constant';

import { IAirlineInfo } from '@/app/bi/models/tripsTypes';

import { SchemaType } from '../../schema';

const createLabels = (t: TFunction) => ({
  AIRLINE: {
    PLACEHOLDER: t('settings:bonusCard.airline.placeholder'),
    LABEL: t('settings:bonusCard.airline.label'),
  },
});

interface SuggestFieldProps {
  index: number
}

const getOption =
  (value: string, airlines?: IAirlineInfo[]): string =>
    airlines?.find((item) => item.code === value)?.name ?? '';

const filterOptions =
  (option: string, airlines?: IAirlineInfo[]): SuggestItem[] =>
    airlines?.filter((airline) => airline.name.toLowerCase().includes(option.toLowerCase()))
      .map((airline) => ({ label: `${airline.name} — ${airline.code}`, value: airline.code })) ?? [];

const SuggestField = ({ index }: SuggestFieldProps) => {
  const { control } = useFormContext<SchemaType>();
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const { field: { onChange, value } } =
    useController({ control, name: `${BONUS_CARDS}.${index}.${AIRLINE_CODE}` });

  const { data, isLoading } = useGetAirlineInfoQuery(null);

  const [option, setOption] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOption(getOption(value, data));
  }, [data]);

  useEffect(() => {
    if (value === '') {
      setOption('');
    }
  }, [value]);

  const items = open ? filterOptions(option, data) : [];

  const handleChange = (item: string) => {
    setOpen(true);
    setOption(item);
  };

  const handleSelect = (item: SuggestItems) => {
    const selected = getOption(item.value as string, data);

    if (selected) {
      setOption(selected);
      onChange(item.value);
    }
  };

  return (
    <div>
      <FieldLabel text={ LABELS.AIRLINE.LABEL }/>
      <Suggest
        preventTab={ false }
        theme='border'
        withLabel={ false }
        value={ option }
        items={ items }
        onSelect={ handleSelect }
        onChange={ handleChange }
        placeholder={ LABELS.AIRLINE.PLACEHOLDER }
        loading={ isLoading }
      />
    </div>
  );
};

export default SuggestField;
