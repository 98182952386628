import React from 'react';
import { Text } from 'yw-ui';
import { getText, getTextArray } from '@/i18n';

import { EmployeeItemInfo } from '@/app/components/EmployeeInfoList/components/EmployeeItemInfo';

import toDecline from '@/app/bi/utils/toDecline.ts';

import { IEmployeeUniversal } from '@/app/bi/models/employees.ts';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('checkout:item.employees'),
  PERSONS: getTextArray('common:persons'),
};

interface EmployeeInfoListProps {
  showHeader?: boolean;
  employees: IEmployeeUniversal[];
}

const EmployeeInfoList = ({
  showHeader = true,
  employees,
}: EmployeeInfoListProps) => {
  const count = employees.length;
  const labelCountPerson = toDecline(count, LABELS.PERSONS);

  const renderEmployeeItemsInfo = () => employees.map((item) => (
    <EmployeeItemInfo
      key={ item.id }
      item={ item }
    />
  ));

  return (
    <div className={ styles.list_wrapper }>
      {showHeader && (
        <div className={ styles.header }>
          <Text type='bold_16'>
            {LABELS.TITLE}:
          </Text>
          <Text type='bold_16'>
            {count} {labelCountPerson}
          </Text>
        </div>
      )}
      <div className={ styles.employees }>
        {renderEmployeeItemsInfo()}
      </div>
    </div>
  );
};

export { EmployeeInfoList };
