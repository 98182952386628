import { Text } from 'yw-ui';
import React from 'react';

import { PassportInfo } from '@/app/components/EmployeeInfoList/components/PassportInfo';
import { CompanyInfo } from '@/app/components/EmployeeInfoList/components/CompanyInfo';

import { getEmployeeFullName } from '@/app/bi/utils/employees.ts';

import { IEmployeeUniversal } from '@/app/bi/models/employees.ts';

import styles from './index.module.css';

interface EmployeeItemInfoProps {
  index?: number;
  item: IEmployeeUniversal;
}

const EmployeeItemInfo = ({
  index,
  item: {
    firstName,
    lastName,
    number,
    type,
    company,
  },
}: EmployeeItemInfoProps) => {
  const fullName = getEmployeeFullName({
    firstName,
    lastName,
  });
  const text = index ? `${index}. ${fullName}` : fullName;

  return (<div className={ styles.item }>
    <Text type='NORMAL_14' fontWeight={ 600 }>
      {text}
    </Text>
    <PassportInfo
      number={ number }
      type={ type }
    />
    <CompanyInfo company={ company } />
  </div>
  );
};

export { EmployeeItemInfo };
