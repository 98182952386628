import React, { useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Text } from 'yw-ui';

import styles from './index.module.css';

const createLabels = (t: TFunction) => ({
  RULE: t('settings:bonusCard.rule'),
});

const RuleBox = () => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  return (
    <div className={ styles.rule_container }>
      <Text type='NORMAL_14' className={ styles.rule_title }>
        {LABELS.RULE}
      </Text>
    </div>
  );
};

export default RuleBox;
