import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAppSelector } from '@/app/store/hooks/redux';

import { bonusCardSchema, SchemaType } from './schema';

export const useBonusCards = () => {
  const bonusCards = useAppSelector((state) => state.employeeSlice.generalData.bonusCards);

  const methods = useForm<SchemaType>({
    defaultValues: {
      bonusCards,
    },
  });

  useEffect(() => {
    methods.reset({ bonusCards });
  }, [bonusCards]);

  const getBonusCards = () =>
    methods.getValues('bonusCards').filter((card) => bonusCardSchema.safeParse(card).success);

  return { methods, getBonusCards };
};
