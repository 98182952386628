import { Dayjs } from 'dayjs';

import { ELanguages } from '@/i18n/locales/consts';

import {
  EEmployeeDocumentType,
  IEmployeeDocument,
  IEmployeeVersion,
} from '@/app/bi/models/employees.ts';
import { IRouteSegment } from './common';
import { IAirlineVersion } from '@/app/bi/models/airline.ts';
import { IHotelVersion } from '@/app/bi/models/hotelsTypes.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { EAirlineClass, EAirlineValidationError, EFareServiceStatus } from '@/app/bi/models/airlineTypes.ts';
import { EHotelTypes, EHotelValidationError } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

export interface ISegmentMeta {
  basis: string,
  bookingClass: string,
  fareFamily: string,
  fareType: string,
}

export interface IRouteMeta {
  id: number,
  segments: ISegmentMeta[]
}

export interface IFlightToken {
  routes: IRouteMeta[],
  validatingAirline: string,
}

export interface IStopDetails {
  dateQualifier: string,
  date: string,
  equipementType: string,
  locationId: string,
}

export interface ITechnicalStop {
  stopDetails: IStopDetails[],
}

export interface IAirlineRouteSegment extends IRouteSegment {
  routeId: string;
}

export interface IAirlineRoute {
  id: string;
  airlineVersionId: string;
  segments: IAirlineRouteSegment[];
  transferDuration: number;
  duration: number;
  totalDuration: number;
}

export interface ICompany {
  id: number;
  accountId: number;
  name: string;
  shortName: string;
  vkn: string;
  address: string;
  seo: string;
}

export interface IEmployee {
  id: string;
  versions: IEmployeeVersion[];
  actualVersion: IEmployeeVersion;
  companies: ICompany[];
  documents: IEmployeeDocument[];
}

enum ETripItemStatus {
  Carted = 'Carted',
  AwaitingBooking = 'AwaitingBooking',
  Booked = 'Booked',
  Canceled = 'Canceled',
}

enum BookProvider {
  Amadeus = 'Amadeus',
  Expedia = 'Expedia',
}

interface ITripItemVersion {
  totalPrice: number;
}

export interface ICartTripItem {
  id: string;
  serviceType: EServiceTypes;
  tripItemStatus: ETripItemStatus;
  actualVersionId: string;
  bookProvider: BookProvider;
  companyId: number;
  checkinDate: Dayjs;
  checkoutDate: Dayjs;
  cartPrice: number;
  employees: IEmployee[];
  tripId: string;
  tripName: string;
  versions: ITripItemVersion[];
  errors: ECartItemErrorType[],
  data: IAirlineVersion | IHotelVersion;
}

export interface ICartWorkPlace {
  airlinesPrice: number;
  hotelsPrice: number;
  totalPrice: number;
  errors: ECartItemErrorType[]
  tripItems: ICartTripItem[]
}

export interface ICartWorkPlaceGroupByTrips {
  airlinesPrice: number;
  hotelsPrice: number;
  totalPrice: number;
  items: ICartItemGroupByTrips[]
}

export interface ICartItemGroupByTrips {
  tripId: string | null;
  tripName: string | null;
  totalPrice: number;
  serviceTypes: EServiceTypes[];
  items: TCartResponseItem[];
}

export enum ECartItemErrorType {
  EmptyCompany = 'EmptyCompany',
  EmptyDocument = 'EmptyDocument',
  EmptyEmployee = 'EmptyEmployee',
  PriceChanged = 'PriceChanged',
  ReserveFailed = 'ReserveFailed',
}

export interface ICartErrorsItem {
  itemId: string;
  errors: {
    error: ECartItemErrorType;
    message: string,
  }[];
}

export interface ICartErrors {
  hasErrors: boolean,
  bookingErrors: string[],
  cartItemErrors: ICartErrorsItem[]
}

export interface ICancelRules {
  rules: string[],
}

export interface IDeleteTripItemObj {
  serviceType: EServiceTypes;
  tripItemId: string;
  itemId: string;
}

export interface IAddEmployeesRequest {
  employeeId: string | null;
  tripItemId: string;
  serviceType: EServiceTypes;
}

export interface IRemoveEmployeesRequest {
  employeeId: string | null;
  tripItemId: string;
  serviceType: EServiceTypes;
}

export interface ISendReasonErrorPolicy {
  tripItemId: string;
  serviceType: EServiceTypes;
  body: { reason: string };
}

export interface IUpdateTripRequest {
  tripItemId: string,
  tripId: string | null;
  serviceType: EServiceTypes;
}

export interface IAddHotelCartRequest {
  bookId: string;
  searchId: string;
  roomCount: number;
  locale: ELanguages;
}

export interface ICartResponse {
  items: TCartResponseItem[];
  totalPrice: number;
  airlinesPrice: number;
  hotelsPrice: number;
}

export interface ICartResponseAirlineItemSegment extends IRouteSegment {
  departureCountryCode: string;
  duration: number;
}

export interface ICartResponseCompany {
  id: number;
  name: string;
  shortName: string | null;
}

export interface ICartResponseEmployeeDocument {
  id: string;
  type: EEmployeeDocumentType;
  number: string;
  lastName: string;
  firstName: string;
  lastNameTranslit: string;
  firstNameTranslit: string;
  middleNameTranslit: string | null;
  expiryDate: string;
}

export interface ICartResponseEmployee {
  id: string;
  firstName: string;
  lastName: string;
  company: ICartResponseCompany;
  document: ICartResponseEmployeeDocument;
  companies: ICartResponseCompany[];
  documents: ICartResponseEmployeeDocument[];
}

export interface CartResponseAirlineItemRoute {
  id: string;
  segments: ICartResponseAirlineItemSegment[];
  duration: number;
}

export interface ICartResponseAirlineItemTravelPolicyError {
  travelPolicyId: string;
  employeeId: string;
  validationErrors: EAirlineValidationError[];
}

export interface ICartResponseHotelItemTravelPolicyError {
  travelPolicyId: string;
  employeeId: string;
  validationErrors: EHotelValidationError[];
}

export interface ICartResponseItem {
  id: string;
  serviceType: EServiceTypes;
  checkinDate: Dayjs;
  checkoutDate: Dayjs;
  price: number;
  employees: ICartResponseEmployee[];
  tripId: string | null;
  tripName: string | null;
  travelPolicyReason: string | null;
  travellersCount: number;
}

export type TCartResponseItem = (ICartResponseAirlineItem | ICartResponseHotelItem);

export interface ICartResponseHotelItem extends ICartResponseItem {
  breakfast: boolean;
  breakfastName: string;
  companyId: number;
  latitude: number;
  longitude: number;
  distanceToCenter: number;
  hotelStars: number;
  hotelName: string;
  hotelDescription: string;
  hotelAddress: string;
  hotelCity: string;
  hotelPhone: string | null;
  hotelEmail: string;
  hotelCheckinTime: string;
  hotelCheckoutTime: string;
  hotelCountryCode: string;
  hotelType: EHotelTypes;
  roomName: string;
  roomFreeCancellation: string | null;
  roomAmenities: string[];
  images: string[];
  travelPoliciesErrors: ICartResponseHotelItemTravelPolicyError[];
}

export interface ICartResponseAirlineItem extends ICartResponseItem {
  routes: CartResponseAirlineItemRoute[];
  class: EAirlineClass;
  ticketReserveExpires: string | null;
  isCarryonIncluded: EFareServiceStatus;
  carryonPlaces: string | null;
  isBaggageIncluded: EFareServiceStatus;
  baggagePlaces: string | null;
  canBuyBaggage: EFareServiceStatus;
  isTicketRefundable: EFareServiceStatus;
  isTicketRefundableAfterDeparture: EFareServiceStatus;
  isTicketChangeable: EFareServiceStatus;
  isTicketChangeableAfterDeparture: EFareServiceStatus;
  canRegistrationSeat: EFareServiceStatus;
  isBusinessHallIncluded: EFareServiceStatus;
  haveInternetAccess: EFareServiceStatus;
  isPriorityRegistration: EFareServiceStatus;
  isPriorityBaggageReception: EFareServiceStatus;
  canUpgradeRate: EFareServiceStatus;
  transferDuration: number;
  duration: number;
  travelPoliciesErrors: ICartResponseAirlineItemTravelPolicyError[];
}

export interface IAddCartEmployeeRequest {
  employeeId: string;
  tripItemId: string;
  serviceType: EServiceTypes;
}

export interface IUpdateCartEmployeeRequest {
  employeeId: string;
  employeeDocumentId: string | null;
  companyId: number | null
  tripItemId: string;
  serviceType: EServiceTypes;
}

export interface ITotalPrice {
  airlinesPrice: number;
  hotelsPrice: number;
}
