import numeral from 'numeral';

import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { currencies } from '@/app/bi/constants/money.ts';

if (!numeral.locales.euro) {
  numeral.register('locale', 'euro', {
    delimiters: {
      thousands: ' ',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal(number) {
      return number.toString();
    },
    currency: {
      symbol: '€',
    },
  });
}
if (!numeral.locales.en) {
  numeral.register('locale', 'en', {
    delimiters: {
      thousands: ' ',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal(number) {
      return number === 1 ? 'er' : 'ème';
    },
    currency: {
      symbol: '$',
    },
  });
}

if (!numeral.locales.tr) {
  numeral.register('locale', 'tr', {
    delimiters: {
      thousands: ' ',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'bin',
      million: 'milyon',
      billion: 'milyar',
      trillion: 'trilyon',
    },
    ordinal() {
      return '.';
    },
    currency: {
      symbol: '₺',
    },
  });
}

numeral.locale('en');

const isInteger = (num: number): boolean => num === parseInt(num?.toString(), 10);

class MoneyFormat {
  static money = (value: number, withSymbol = false) => {
    const isInt = isInteger(value);
    let pattern = isInt ? '0,0' : '0,0.00';

    if (withSymbol) pattern = isInt ? '0,0$' : '0,0$.00';

    return numeral(value).format(pattern);
  };

  static moneyWithDecimal = (value: number, withSymbol = false) => {
    let pattern = '0,0[.]00';

    if (withSymbol) pattern = '0,0[.]00$';

    return numeral(value).format(pattern);
  };
}

export const formatCurrency = (value: number, currency: ECurrencyCode): string => {
  const currencyObj = currencies.find((
    curr,
  ) => curr.code === currency);

  if (!currencyObj) {
    console.error(`Unknown currency code: ${currency}`);

    return value.toString();
  }

  const formattedValue = numeral(value).format('0,0[.]00');

  return `${formattedValue} ${currencyObj.symbol}`;
};

export default MoneyFormat;
