import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Text,
  Stars,
  ItemPanel,
  NoPhoto,
  Tooltip } from 'yw-ui/src';
import { Link } from 'react-router-dom';

import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { ShowOnMapButton } from '@/app/components/ShowOnMapButton';
import { ImageItem } from '@/app/components/ImageGallery/components/ImageItem';
import { HotelItemRate } from '@/app/pages/HotelsResultPage/components/HotelItemRate';
import { HotelInfoAction } from './HotelInfoAction.tsx';

import { preloadOrigUrl } from '@/app/bi/utils/images.ts';
import { getTextPolicy } from '@/app/bi/utils/travelPolicy.ts';

import { HOTEL_TYPES } from '@/app/bi/constants/hotelsSearch.ts';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { IRegionSearchResponseItem } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './styles.module.css';

const createLabels = (t: TFunction) => ({
  DAYS_DECLINE: t('hotels:regionResult.item.daysDecline'),
  ROOM_DECLINE: t('hotels:regionResult.item.roomDecline'),
  ON: t('hotels:regionResult.item.on'),
  PER: t('hotels:regionResult.item.per'),
  CHOOSE_ROOM: t('hotels:regionResult.item.chooseRoom'),
  RANGE_TO_CENTER: (city: string, distance: string) =>
    t('hotels:regionResult.item.rangeToCenter', { city, distance }),
  HEADING_TOOLTIP: (policyName: string) => t('components:travelPolicies.headingTooltip', { policyName }),
  HEADING_TOOLTIP_CONTENT:
    (policyName: string) => t('components:travelPolicies.headingTooltipContent', { policyName }),
});

interface IHotelItemProps {
  item: IRegionSearchResponseItem;
  listTravelPolicies: ITravelPolicy[];
  selectPolicy: { id: string, policyName: string, isActive: boolean };
  diffDays: number;
  regionName: string;
  travellersCount: number;
  roomCount: number;
  hotelUrl: string;
  currencyCode: ECurrencyCode,
  onOpenMap(item: IRegionSearchResponseItem): void;
  onShowHotelOnMap(): void;
}

const HotelItem = ({
  travellersCount,
  listTravelPolicies,
  selectPolicy,
  roomCount,
  item,
  regionName,
  hotelUrl,
  diffDays,
  currencyCode,
  onOpenMap,
  onShowHotelOnMap,
}: IHotelItemProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const {
    address,
    thumbnailUrl,
    rating,
    ratingType,
    distanceToCenter,
    rate,
    type,
  } = item;

  const [animationClass, setAnimationClass] = useState('');
  const [mainImageValid, setMainImageValid] = useState(false);

  const handlePreloadImg = useCallback(async () => {
    const res = await preloadOrigUrl(thumbnailUrl);

    if (res) {
      setMainImageValid(true);
    }
  }, [thumbnailUrl]);

  useEffect(() => {
    handlePreloadImg();
  }, [handlePreloadImg, item]);

  const handleAnimationEnd = () => setAnimationClass('');

  const renderNameHotelDefault = ({ name }: { name: string, stars: number }) => (
    <Text type='NORMAL_16' className={ styles.title }>
      <span>{name}</span>
    </Text>
  );

  const renderNameHotel = ({ name, stars }: IRegionSearchResponseItem) => (
    renderNameHotelDefault({ name, stars })
  );

  const handleShowMap = () => {
    onOpenMap(item);
    onShowHotelOnMap();
  };

  const renderTooltipContent = () => {
    const renderListTextErrors = () => item.travelPolicyValidationErrors?.map((textPolicy) => (
      <li key={ textPolicy }>
        <Text color='white' type='NORMAL_14'>
          {getTextPolicy(textPolicy, EServiceTypes.Hotel, listTravelPolicies)}
        </Text>
      </li>
    ));

    return (
      <div className={ styles.tooltip_content }>
        <Text color='white' type='NORMAL_16'>
          {LABELS.HEADING_TOOLTIP_CONTENT(selectPolicy.policyName)}
        </Text>
        <ul className={ styles.list_policy_text }>
          {renderListTextErrors()}
        </ul>
      </div>
    );
  };

  const renderPolicyTooltip = () => (
    <Tooltip
      show
      position='bottom'
      renderContent={ () => renderTooltipContent() }
    >
      <Text type='NORMAL_14' className={ styles.name_policy }>
        {LABELS.HEADING_TOOLTIP(selectPolicy.policyName)}
      </Text>
    </Tooltip>
  );

  const renderTravelPolicyError = () => {
    if (!item.travelPolicyValidationErrors.length) return null;

    return (
      <div className={ styles.render_error_policy }>
        {renderPolicyTooltip()}
      </div>
    );
  };

  const renderHeader = () => (
    <div className={ styles.header }>
      <Link to={ hotelUrl } target='_blank' className={ `${styles.name_wrapper}` }>
        {renderNameHotel(item)}
      </Link>
      {renderTravelPolicyError()}
    </div>
  );

  const renderImage = () => {
    const itemImage = { original: thumbnailUrl };

    return (
      <div className={ styles.img_wrapper }>
        <ImageItem item={ itemImage } />
      </div>
    );
  };

  const renderNoPhoto = () => (
    <div className={ styles.no_photo }>
      <NoPhoto />
    </div>
  );

  const imageContent = thumbnailUrl && mainImageValid
    ? renderImage()
    : renderNoPhoto();

  const renderRate = () => (
    <HotelItemRate rate={ rate }/>
  );

  const renderContent = () => (
    <div className={ styles.content }>
      <div className={ styles.main }>
        <Link target='_blank' to={ hotelUrl }>
          {imageContent}
        </Link>
        <div className={ styles.info }>
          <div className={ styles.location }>
            <div className={ styles.stars }>
              {item.stars > 0 && <Stars count={ item.stars } color='blue1' size={ 8 } />}
              <Text type='SEMIBOLD_14' color='gray-7'>
                {HOTEL_TYPES[type]}
              </Text>
            </div>
            <Text type='NORMAL_14' className={ styles.address }>
              {address}
            </Text>
            <div className={ styles.distance_wrap }>
              <Text type='NORMAL_12' className={ styles.distance }>
                {LABELS.RANGE_TO_CENTER(regionName, distanceToCenter.toFixed(2))}
              </Text>
              <ShowOnMapButton onClick={ handleShowMap } />
            </div>
          </div>
          {renderRate()}
        </div>
      </div>
      <HotelInfoAction
        rate={ rate }
        rating={ rating }
        diffDays={ diffDays }
        hotelUrl={ hotelUrl }
        roomCount={ roomCount }
        ratingType={ ratingType }
        currencyCode={ currencyCode }
        travellersCount={ travellersCount }
      />
    </div>
  );

  return (
    <ItemPanel
      animationClass={ animationClass }
      className={ styles.item }
      renderHeader={ renderHeader }
      onAnimationEnd={ handleAnimationEnd }
    >
      { renderContent() }
    </ItemPanel>
  );
};

export { HotelItem };
