import { getText } from '@/i18n';

import {
  EPriceType,
  IAirlinePolicy,
  IHotelPolicy,
  IRestriction,
  ITravelPolicies,
  ITravelPolicy,
} from '@/app/bi/models/travelPolicies.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { EAirlineClass } from '@/app/bi/models/airlineTypes.ts';

const LABEL = {
  CURRENCY: {
    LEAR: getText('settings:travelPolicies.currency.lir'),
    RUBLES: getText('settings:travelPolicies.currency.rub'),
    EURO: getText('settings:travelPolicies.currency.euro'),
    DOLLAR: getText('settings:travelPolicies.currency.dollar'),
  },
  FLY_CLASS: {
    ECONOM: getText('settings:travelPolicies.flyClass.econom'),
    BUSINESS: getText('settings:travelPolicies.flyClass.business'),
    FIRST: getText('settings:travelPolicies.flyClass.first'),
    COMFORT: getText('settings:travelPolicies.flyClass.comfort'),
  },
  TAKING_ARRIVAL: getText('settings:travelPolicies.takingArrival'),
  EXCLUDING_EARLY_CHECK_IN: getText('settings:travelPolicies.excludingEarlyCheckIn'),
};

export interface IRoutesFromTo {
  label: string;
  selected: null | number;
}

export interface IRoutes {
  from: IRoutesFromTo,
  to: IRoutesFromTo,
}

export const INIT_ROUTE_AIRLINE: IRoutes = {
  from: {
    label: '',
    selected: null,
  },
  to: {
    label: '',
    selected: null,
  },
};

export const INIT_CITY_HOTEL: IRestriction = {
  name: '',
  code: '',
  code3: '',
};

export const INIT_COUNTRIES_HOTEL: IRestriction = {
  name: '',
  code: '',
  code3: '',
};

export const CURRENCY_SYMBOLS = {
  [ECurrencyCode.TRY]: '₺',
  [ECurrencyCode.USD]: '$',
  [ECurrencyCode.EUR]: '€',
  [ECurrencyCode.RUB]: '₽',
  [ECurrencyCode.Percent]: '%',
};

export const CURRENCY_NAME_AVIA = [
  { label: LABEL.CURRENCY.LEAR, value: ECurrencyCode.TRY },
  { label: LABEL.CURRENCY.RUBLES, value: ECurrencyCode.EUR },
  { label: LABEL.CURRENCY.DOLLAR, value: ECurrencyCode.USD },
  { label: LABEL.CURRENCY.EURO, value: ECurrencyCode.EUR },
  { label: '%', value: ECurrencyCode.Percent },
];

export const CURRENCY_NAME_LIST = [
  { label: LABEL.CURRENCY.LEAR, value: ECurrencyCode.TRY },
  { label: LABEL.CURRENCY.RUBLES, value: ECurrencyCode.RUB },
  { label: LABEL.CURRENCY.DOLLAR, value: ECurrencyCode.USD },
  { label: LABEL.CURRENCY.EURO, value: ECurrencyCode.EUR },
];

// TODO Привязать как бэк будет
export const CLASS_TICKET_LIST = [
  { label: LABEL.FLY_CLASS.ECONOM, value: EAirlineClass.Econom },
  { label: LABEL.FLY_CLASS.BUSINESS, value: EAirlineClass.Business },
  { label: LABEL.FLY_CLASS.COMFORT, value: EAirlineClass.Comfort },
  { label: LABEL.FLY_CLASS.FIRST, value: EAirlineClass.First },
];

export const INIT_AIRLINE_POLICY: IAirlinePolicy = {
  id: '',
  maxPrice: 5000,
  maxPriceCurrency: ECurrencyCode.TRY,
  priceDifferenceLimit: 3000,
  priceDifferenceLimitType: EPriceType.Currency,
  priceDifferenceLimitCurrency: ECurrencyCode.TRY,
  class: [EAirlineClass.Econom],
  basePolicyId: '',
  baggage: false,
  weekdays: false,
  flightHours: 0,
  directions: [],
  refundable: false,
  daysBuyLimit: 1,
  exceptions: [],
};

export const INIT_HOTEL_POLICY: IHotelPolicy = {
  id: '',
  maxPricePerNight: 5000,
  maxPricePerNightCurrency: ECurrencyCode.TRY,
  basePolicyId: '',
  weekdays: false,
  cities: [],
  exceptions: [],
  eclc: false,
  refundable: false,
  countryCodes: [],
};

export const INIT_TRAVEL_POLICY: ITravelPolicy = {
  id: '',
  name: '',
  description: '',
  accountId: 0,
  isActive: false,
  creatorUserId: '',
  airlinePolicy: INIT_AIRLINE_POLICY,
  hotelPolicy: INIT_HOTEL_POLICY,
};

// TODO Убрать как поправят бэк
export const INIT_TRAVEL_POLICIES: ITravelPolicies = {
  airline: {
    restrictionOnDirections: {
      onlyTurkey: true,
      onlySpecifiedDestinations: false,
      routes: [INIT_ROUTE_AIRLINE],
    },
  },
  hotel: {
    restrictionOnDirections: {
      selectCountry: true,
      onlySpecifiedCities: false,
      cities: [INIT_CITY_HOTEL],
      countries: [INIT_COUNTRIES_HOTEL],
    },
  },
};

export const DEPARTURES_NAME_LIST = [
  { label: LABEL.TAKING_ARRIVAL, value: 'true' },
  { label: LABEL.EXCLUDING_EARLY_CHECK_IN, value: 'false' },
];
