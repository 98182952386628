import React, { useCallback, useState } from 'react';
import { Button } from 'yw-ui';

import { getText } from '@/i18n';

import { HotelItem } from '@/app/components/HotelItem';
import { AirlineItem } from '@/app/components/AirlineItem';
import { CartPanel } from '@/app/pages/Cart/components/CartPanel';
import { ItemLayout } from '@/app/pages/Cart/components/ItemLayout';
import { AddInTripDialog } from '@/app/pages/Cart/components/AddInTripDialog';
import { CartHeader } from '@/app/pages/Cart/components/CartItem/components/CartHeader';
import { CartDetails } from '@/app/pages/Cart/components/CartItem/components/CartDetails';
import { EmployeeList } from '@/app/pages/Cart/components/CartItem/components/EmployeeList';
import { SelectCompany } from '@/app/pages/Cart/components/CartItem/components/SelectCompany';
import { TravelPolicyError } from '@/app/pages/Cart/components/CartItem/components/TravelPolicyError';

import { notification } from '@/app/bi/utils/not.ts';

import ANIMATION from '@/app/bi/constants/animation';
import { NOTIFICATION_TIMEOUT } from '@/app/bi/constants/cart.ts';
import { ENotificationActionType } from '@/app/bi/constants/settings.ts';

import {
  IAddEmployeesRequest,
  IRemoveEmployeesRequest,
  TCartResponseItem,
  ICartResponseAirlineItem,
  ICartResponseHotelItem,
  IUpdateCartEmployeeRequest,
  IUpdateTripRequest,
  ICartErrorsItem,
} from '@/app/bi/models/cart.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import styles from './index.module.css';

const LABELS = {
  MORE_HOTEL_INFORMATION: getText('cart:cartItem.moreAboutOfHotel'),
  MORE_AIR_INFORMATION: getText('common:moreInformationAboutFlight'),
  NOT_ADDED_TO_TRIP: getText('cart:cartItem.notAddedToTrip'),
  DELETE_CART_ITEM_ERROR_TITLE: getText('cart:cartItem.deleteCartItemErrorTitle'),
  ADD_CART_ITEM_TO_TRIP_ERROR_TITLE: getText('cart:cartItem.addCartItemToTripErrorTitle'),
  UNPIN_CART_ITEM_TO_TRIP_ERROR_TITLE: getText('cart:cartItem.unpinCartItemToTripErrorTitle'),
};

interface CartItemProps {
  item: TCartResponseItem,
  allItemsLoading: boolean;
  errors: ICartErrorsItem[];
  allItemsAnimatedClass?: string,
  onDeleteItem(item: { serviceType: EServiceTypes, tripItemId: string, itemId: string }): void;
  onAddEmployee(data: IAddEmployeesRequest): void;
  onRemoveEmployee(data: IRemoveEmployeesRequest): void;
  onAddCartItemToTrip(item: IUpdateTripRequest): void,
  onShowDetails?(serviceType: string, item: TCartResponseItem): void;
  onUpdateDataEmployee(data: IUpdateCartEmployeeRequest): void;
  onUpdateCompany({ tripItemId, companyId }: { tripItemId: string, companyId: number | null }): void;
}

const CartItem = ({
  item,
  errors,
  allItemsLoading,
  onShowDetails = () => {},
  onDeleteItem,
  onAddEmployee,
  onRemoveEmployee,
  onUpdateDataEmployee,
  onAddCartItemToTrip,
  onUpdateCompany,
  allItemsAnimatedClass = '',
}: CartItemProps) => {
  const {
    id,
    tripId,
    tripName,
    employees,
    serviceType,
    travellersCount,
    price,
    travelPoliciesErrors,
    travelPolicyReason,
  } = item;
  const currentError = errors.find(({
    itemId,
  }) => itemId === item.id) || null;
  const isError = !!currentError;
  const [loading, setLoading] = useState(false);
  const [
    showAddInTripDialog,
    setShowAddInTripDialog,
  ] = useState(false);
  const [animatedClass, setAnimatedClass] = useState('');

  const handleDeleteItem = async () => {
    setLoading(true);

    try {
      setAnimatedClass(ANIMATION.DELETE_CART);
      setTimeout(async () => {
        await onDeleteItem({
          serviceType,
          tripItemId: item.id,
          itemId: item.id,
        });
      }, 1000);
    } catch (e) {
      notification({
        title: LABELS.DELETE_CART_ITEM_ERROR_TITLE,
        type: ENotificationActionType.error,
      });
    } finally {
      setTimeout(() => { setLoading(false); }, 1000);
    }
  };

  const handleShowDetails = useCallback(() => onShowDetails(serviceType, item), []);

  const addEmployeeToCard = async (employeeId: string) => onAddEmployee({
    employeeId,
    tripItemId: item.id,
    serviceType,
  });

  const handleRemoveEmployee = async (employeeId: string) => onRemoveEmployee({
    employeeId,
    tripItemId: item.id,
    serviceType,
  });

  const handleUpdateDataEmployee = ({
    employeeId,
    employeeDocumentId,
    companyId,
  }: IUpdateCartEmployeeRequest) => onUpdateDataEmployee({
    employeeId,
    employeeDocumentId,
    companyId,
    tripItemId: item.id,
    serviceType,
  });

  const handleUpdateCompany = (companyId: number | null) =>
    onUpdateCompany({ tripItemId: item.id, companyId });

  const handleCloseAddInTripDialog = () => setShowAddInTripDialog(false);

  const onShowAddInTripDialog = () => setShowAddInTripDialog(true);

  const handleSaveAddingTrip = async (value: string | null) => {
    setLoading(true);

    try {
      await onAddCartItemToTrip({
        tripItemId: item.id,
        tripId: value,
        serviceType,
      });
    } catch (e) {
      notification({
        title: LABELS.ADD_CART_ITEM_TO_TRIP_ERROR_TITLE,
        type: ENotificationActionType.error,
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
        setShowAddInTripDialog(false);
      }, NOTIFICATION_TIMEOUT);
    }
  };

  const handleUnpin = async () => {
    setLoading(true);

    try {
      await onAddCartItemToTrip({
        tripItemId: item.id,
        tripId: null,
        serviceType,
      });
    } catch (e) {
      notification({
        title: LABELS.UNPIN_CART_ITEM_TO_TRIP_ERROR_TITLE,
        type: ENotificationActionType.error,
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, NOTIFICATION_TIMEOUT);
    }
  };

  const renderHeader = () => (
    <CartHeader
      disableAddInTrip={ !!tripId }
      onDeleteItem={ handleDeleteItem }
      onShowAddInTripDialog={ onShowAddInTripDialog }
    />
  );

  const renderItem = () => {
    switch (serviceType) {
      case EServiceTypes.Airline: {
        return (item as ICartResponseAirlineItem).routes.map((route) => (
          <AirlineItem
            key={ route.id }
            route={ route }
          />
        ));
      }
      case EServiceTypes.Hotel: {
        return (
          <HotelItem
            key={ item.id }
            item={ item as ICartResponseHotelItem }
          />
        );
      }
    }

    return null;
  };

  const renderCartDetails = () => {
    if (!tripId) return null;

    return (
      <CartDetails
        tripName={ tripName as string }
        tripId={ tripId }
        onUnpin={ handleUnpin }
      />
    );
  };

  const renderAddInTripDialog = () => {
    if (!showAddInTripDialog) return false;

    return (
      <AddInTripDialog
        show={ showAddInTripDialog }
        loading={ loading }
        onClose={ handleCloseAddInTripDialog }
        onSave={ handleSaveAddingTrip }
      />
    );
  };

  const renderCompany = () => {
    if (serviceType === EServiceTypes.Airline || !employees.length) return null;

    const arrayCompanies = employees.flatMap(({
      companies,
    }) => companies);

    const prepareCompany = arrayCompanies.map((
      companyItem,
    ) => ({
      label: companyItem.name,
      value: companyItem.id,
    }));

    const currentCompany = arrayCompanies.find(({
      id: companyId,
    }) => companyId === (item as ICartResponseHotelItem)?.companyId) || null;

    return (
      <div className={ styles.add_company }>
        <SelectCompany
          value={ currentCompany }
          serviceType={ item.serviceType }
          companies={ prepareCompany }
          onChange={ handleUpdateCompany }
        />
      </div>
    );
  };

  return (
    <CartPanel
      renderHeader={ renderHeader }
      headerClass={ styles.header }
      loading={ loading || allItemsLoading }
      animationClass={ animatedClass || allItemsAnimatedClass }
      warning={ isError }
    >
      <ItemLayout
        className={ styles.wrap_items }
        serviceType={ serviceType }
        price={ price }
      >
        {renderItem()}
        <div className={ styles.wrap_details } >
          <Button
            type='text'
            onClick={ handleShowDetails }
          >
            {serviceType === EServiceTypes.Airline
              ? LABELS.MORE_AIR_INFORMATION
              : LABELS.MORE_HOTEL_INFORMATION
            }
          </Button>
        </div>
      </ItemLayout>
      <EmployeeList
        employees={ employees }
        travellersCount={ travellersCount }
        errors={ currentError }
        serviceType={ serviceType }
        onAddEmployeeToCard={ addEmployeeToCard }
        onRemoveEmployee={ handleRemoveEmployee }
        onUpdateDataEmployee={ handleUpdateDataEmployee }
      />
      {renderCompany()}
      {renderCartDetails()}
      {renderAddInTripDialog()}
      <TravelPolicyError
        tripItemId={ id }
        travelPoliciesErrors={ travelPoliciesErrors }
        travelPolicyReason={ travelPolicyReason }
        serviceType={ serviceType }
      />
    </CartPanel>
  );
};

export { CartItem };
