import React, { useCallback, useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Datepicker, Input, MultiSelect, Suggest, Text } from 'yw-ui';
import { SuggestItem, SuggestItems } from 'yw-ui/components/Suggest/types';
import clsx from 'clsx';

import { getText } from '@/i18n';

import {
  setBirthDay,
  setCountry,
  setContactDataGeneral,
  setNameGeneral,
  setOrganization,
  setSex,
  setLastCountryCode,
} from '@/app/store/redusers/EmployeeSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';

import { FormWrapper } from '@/app/components/FormWrapper';
import { FieldLabel } from '@/app/components/FieldLabel';
import { SexSwitcher } from '@/app/components/SexSwitcher';
import { InputPhone } from '@/app/components/InputPhone';
import { BlockFormFio } from '@/app/pages/Employee/components/FormContainer/Components/BlockFormFio';

import { validateField } from '@/app/bi/utils/employee.ts';

import {
  INIT_GENERAL_STEP_ERROR,
  PLACEHOLDER_EMPLOYEE_INPUT,
} from '@/app/bi/constants/employee.ts';

import {
  DATEPICKER_BIRTH_DAY_PLACEHOLDER,
  EEmployeeFormParam,
  EEmployeeNameParam,
  ETypesSex,
  MAX_DATE,
} from '@/app/bi/constants/settings.ts';

import { ICustomSuggest } from '@/app/bi/models/app.ts';
import { ICitizenshipItem } from '@/app/bi/models/profile.ts';

import styles from './styles/index.module.css';

const LABELS = {
  CONTACT_DATA: getText('settings:contactData'),
  EMAIL: getText('settings:employees.employee.fieldNames.email'),
  MOBILE_PHONE: getText('settings:employees.employee.fieldNames.mobilePhone'),
  BIRTH_DAY: getText('settings:employees.employee.fieldNames.birthday'),
  SEX: getText('settings:employees.employee.fieldNames.sex'),
  CITIZENSHIP: getText('settings:employees.employee.citizenship'),
  ORGANIZATION: getText('settings:organization'),
  INPUT_NAME: getText('settings:inputName'),
  EMPLOYEE_FORM_ERROR: getText('settings:validationError'),
  INVALID_EMAIL_FORMAT: getText('settings:validationError'),
};

interface IValidError {
  birthDay: string;
  email: string;
  phoneNumber: string;
  citizenship: string;
}

interface GeneralStepProps {
  companies: SuggestItem[];
  listCountryCode: ICitizenshipItem[];
}

const GeneralStep = ({ companies, listCountryCode }: GeneralStepProps) => {
  const {
    lastName,
    firstName,
    birthday,
    contactData: {
      email,
      phoneNumber,
      organization,
    },
    sex,
    citizenship,
  } = useAppSelector((state) => state.employeeSlice.generalData);

  const dispatch = useAppDispatch();

  const [
    error,
    setError,
  ] = useState<IValidError>(INIT_GENERAL_STEP_ERROR);
  const [inputValue, setInputValue] = useState(citizenship.name);
  const [
    filteredItems,
    setFilteredItems,
  ] = useState<ICustomSuggest[]>([]);

  useEffect(() => {
    setInputValue(citizenship.name);
  }, [citizenship]);

  const handleChangeFirstName = (value: string) => {
    dispatch(setNameGeneral({ value, type: EEmployeeNameParam.First }));
  };

  const handleChangeLastName = (value: string) => {
    dispatch(setNameGeneral({ value, type: EEmployeeNameParam.Last }));
  };

  const handleBirthDay = (value: Dayjs | null) => {
    dispatch(setBirthDay(value));

    handleOnBlur(String(value), EEmployeeFormParam.BirthDay);
  };

  const handleSex = (value: ETypesSex) => dispatch(setSex(value));

  const handleOnBlur = useCallback((value: string, type: EEmployeeFormParam): void => {
    const errorMessage = validateField(value, type);

    setError(prevError => ({ ...prevError, [type]: errorMessage }));
  }, [validateField]);

  const handleContactData = useCallback((value: string, type: EEmployeeFormParam): void => {
    dispatch(setContactDataGeneral({ value, type }));

    handleOnBlur(value, type);
  }, [dispatch, validateField]);

  const handleSuggestCountry = useCallback((selectCountry: SuggestItems) => {
    const selectedCountry = listCountryCode.find(
      item => item.name === selectCountry.label,
    );

    if (selectedCountry) {
      dispatch(setLastCountryCode(citizenship?.code3));
      dispatch(setCountry(selectedCountry));
    }

    const citizenshipName = selectedCountry?.name || '';

    handleOnBlur(citizenshipName, EEmployeeFormParam.Country);
  }, [listCountryCode, dispatch, setCountry, citizenship]);

  const handleOrganization = (value: number[]) => dispatch(setOrganization(value));

  const handleSuggestChange = (value: string) => {
    setInputValue(value);

    const filtered = listCountryCode.filter(({
      name,
    }) => name.toLowerCase().includes(value.toLowerCase()));

    const transformationArray = filtered.map(({
      name,
      code3,
    }) => ({
      label: name,
      value: code3,
    }));

    setFilteredItems(transformationArray);

    handleOnBlur(inputValue, EEmployeeFormParam.Country);
  };

  const renderBirthDayCitizenshipSex = () => (
    <FormWrapper className={ styles.row }>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.BIRTH_DAY } isRequired />
        <Datepicker
          closeOnTabOut
          inputClassName={ clsx([styles.datepicker], {
            [styles.datepicker_error]: error.birthDay.length,
          }) }
          value={ birthday }
          max={ MAX_DATE }
          onChange={ handleBirthDay }
          onBlur={ () => handleOnBlur(String(birthday), EEmployeeFormParam.BirthDay) }
          placeholder={ DATEPICKER_BIRTH_DAY_PLACEHOLDER }
          error={ error.birthDay }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.CITIZENSHIP } isRequired />
        <Suggest
          preventTab={ false }
          theme='border'
          withLabel={ false }
          value={ inputValue }
          items={ filteredItems as never[] }
          onChange={ handleSuggestChange }
          onSelect={ handleSuggestCountry }
          error={ error.citizenship }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.SEX } isRequired />
        <SexSwitcher sex={ sex } onChange={ handleSex } />
      </div>
    </FormWrapper>
  );

  const renderContactsData = (
    <FormWrapper className={ styles.row }>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.MOBILE_PHONE } isRequired />
        <InputPhone
          value={ phoneNumber }
          onChange={ (value) => handleContactData(value, EEmployeeFormParam.PhoneNumber) }
          onBlur={ () => handleOnBlur(phoneNumber, EEmployeeFormParam.PhoneNumber) }
          error={ error.phoneNumber }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.EMAIL } isRequired />
        <Input
          type='email'
          value={ email }
          onChange={ (value: string) => handleContactData(value, EEmployeeFormParam.Email) }
          onBlur={ () => handleOnBlur(email, EEmployeeFormParam.Email) }
          placeholder={ PLACEHOLDER_EMPLOYEE_INPUT.EMAIL }
          error={ error.email }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.ORGANIZATION } isRequired />
        <MultiSelect
          theme='border'
          values={ organization }
          list={ companies }
          onChange={ handleOrganization }
          className={ styles.inputOrganization }
          overflow
          placeholder={ LABELS.INPUT_NAME }
        />
      </div>
    </FormWrapper>
  );

  return (
    <div className={ styles.main }>
      <BlockFormFio
        firstName={ firstName }
        lastName={ lastName }
        onChangeFirstName={ handleChangeFirstName }
        onChangeLastName={ handleChangeLastName }
      />
      {renderBirthDayCitizenshipSex()}
      <div className={ styles.subTitleContact }>
        <Text type='bold_18'>{LABELS.CONTACT_DATA}</Text>
      </div>
      {renderContactsData}
    </div>
  );
};

export { GeneralStep };
