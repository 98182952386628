import React from 'react';
import { Navigate } from 'react-router-dom';

import { LocaleContext } from 'yw-ui';
import { useAppSelector } from '@/app/store/hooks/redux.ts';

import { MainLayout } from '@/app/layout';
import { currentLng } from '@/i18n';
import { ELanguages } from '@/i18n/locales/consts';

const getCurrentLocale = () => ({
  locale: currentLng as ELanguages,
});

const ProtectedLayout = () => {
  const { isAuth } = useAppSelector((state) => state.auth);

  if (!isAuth) return <Navigate to='/' />;

  const currentLocale = getCurrentLocale();

  return (
    // @ts-ignore
    <LocaleContext.Provider value={ currentLocale }>
      <MainLayout />
    </LocaleContext.Provider>
  );
};

export { ProtectedLayout };
