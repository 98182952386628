import React, { useState } from 'react';
import { Button, IconButton, ItemPanel, Price, Text, Tooltip } from 'yw-ui';
import clsx from 'clsx';

import { getText } from '@/i18n';

import FareDetails from '../FareDetails';
import FareItem from './components/FareItem';
import { AirItem } from './components/AirItem';
import { TravellersAmount } from './components/TravellersAmount';

import { getTextPolicy } from '@/app/bi/utils/travelPolicy.ts';

import SYMBOLS from '../../bi/constants/symbols.ts';
import ANIMATION from '../../bi/constants/animation.ts';
import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy.ts';

import { Fares, Items } from '@/app/bi/models/airlineTypes.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './index.module.css';

const LABELS = {
  AVAILABLE: getText('components:airlineSearchItem.available'),
  HEADING_TOOLTIP: (policyName: string) => getText('components:travelPolicies.headingTooltip', { policyName }),
  HEADING_TOOLTIP_CONTENT: (policyName: string) => getText('components:travelPolicies.headingTooltipContent', { policyName }),
  NOT_SPECIFIED: getText('settings:travelPolicies.notSpecified'),
};

interface AirlineSearchItemProps {
  item: Items;
  travellers: number;
  isChangeAirTrip?: boolean;
  selectPolicy: { id: string, policyName: string, isActive: boolean };
  loadingPolicy: boolean;
  listTravelPolicies: ITravelPolicy[];
  onAddToCart(item: { item: Items, fareId: string, totalPrice: number }): void;
}

const AirlineSearchItem = ({
  item,
  travellers,
  isChangeAirTrip = false,
  selectPolicy,
  loadingPolicy,
  listTravelPolicies,
  onAddToCart,
}: AirlineSearchItemProps) => {
  const {
    fares,
    routes,
    // transferDuration
  } = item;
  const [animationClass, setAnimationClass] = useState('');
  const [currentFareId, setCurrentFareId] = useState(fares[0].id);
  const [currentPrice, setCurrentPrice] = useState(fares[0].price.totalPrice);
  const [showDetails, setShowDetails] = useState(false);

  // const [showHiddenFares, setShowHiddenFares] = useState(false);

  const handleAnimationEnd = () => setAnimationClass('');

  const handleAddToCart = (totalPrice: number) => {
    onAddToCart({ item, fareId: currentFareId, totalPrice });
    setAnimationClass(ANIMATION.MOVE_TO_CART);
  };

  const handleShowSegments = () => setShowDetails(!showDetails);

  const handleFareClick = (fare: Fares) => {
    const { id, price: { totalPrice } } = fare;

    setCurrentFareId(id);
    setCurrentPrice(totalPrice);
  };

  const handleTypeItem = (faresAmount: number, show: boolean) => {
    if (faresAmount > 1 && !show) {
      return 'FlexibleFareHide';
    }
    if (faresAmount === 1 && !show) {
      return 'FixedFareHide';
    }

    return null;
  };

  const handleSeparateSymbol = (faresAmount: number, show: boolean) => {
    const typeItem = handleTypeItem(faresAmount, show);

    switch (typeItem) {
      case 'FlexibleFareHide':
        return SYMBOLS.COMMA;
      case 'FixedFareHide':
        return SYMBOLS.DOT;
      default:
        return null;
    }
  };

  const renderAirItem = () => routes.map((route, index) => (
    <AirItem
      key={ `airline_route_${route.id}_${index}` }
      lastChild={ index === (routes.length - 1) }
      route={ route }
      showDetails={ showDetails }
      onShowSegments={ handleShowSegments }
    />
  ));

  // const renderFareMore = (isHiddenFares: boolean) => {
  //   if (!isHiddenFares) return null;
  //
  //   return (
  //     <IconButton
  //       disabled={ false }
  //       iconType='caretDown'
  //       onClick={ () => setShowHiddenFares(true) }
  //       iconSide='right'
  //     >
  //       { LABELS.FARE_MORE_BTN }
  //     </IconButton>
  //   );
  // };

  const renderFareItems = () => {
    // const isHiddenFares = false;
    const fareHtml = fares.reduce<React.ReactNode[]>((
      result,
      fare,
      ind,
    ) => {
      const currentFare = (
        <FareItem
          key={ `airline_fare_${ind}` }
          fare={ fare }
          currentFareId={ currentFareId }
          currentPrice={ currentPrice }
          isChangeAirTrip={ isChangeAirTrip }
          onFareClick={ handleFareClick }
        />
      );

      result.push(currentFare);

      return result;
    }, []);

    return (
      <>
        <div className={ styles.fare }>
          { fareHtml }
        </div>
        {/* { renderFareMore(isHiddenFares) } */}
      </>
    );
  };

  const renderFaresDetails = () => {
    const findFares = fares.find((fare) => currentFareId === fare.id);
    const separateSymbol = handleSeparateSymbol(fares.length, showDetails);

    return !!findFares && (
      <Text type='NORMAL_14_130'>
        <FareDetails
          title={ showDetails }
          fare={ findFares }
          className={ !showDetails ? styles.fare_details : '' }
          separateSymbol={ separateSymbol }
        />
      </Text>
    );
  };

  const renderTravellersAmount = () => {
    if (travellers <= 1) return null;

    return (
      <TravellersAmount
        travellers={ travellers }
        currentFareId={ currentFareId }
        fares={ fares }
      />
    );
  };

  const renderAvailableCount = () => {
    const availableCount = Math.min.apply(null, routes.map((route) => (
      Math.min.apply(null, route.segments.map((segment) => segment.availableSeats))
    )));
    const visible = !isChangeAirTrip;

    return visible && (
      <Text type='NORMAL_14' color='gray-7'>
        {`${LABELS.AVAILABLE} ${availableCount}`}
      </Text>
    );
  };

  const renderPriceOrChange = () => {
    const value = fares.find((fare) => currentFareId === fare.id)?.price;
    const optionsHtml = renderTravellersAmount();

    return (
      <div className={ styles.price }>
        <Button
          className={ styles.button }
          type='secondary'
          onClick={ () => handleAddToCart(value?.totalPrice as number) }
        >
          <Price type='bold_20' color='white' value={ value?.totalPrice as number } costType={ CURRENCY_SYMBOLS[value?.currency as ECurrencyCode] } marginSmall />
        </Button>
        <div className={ styles.options } >
          {optionsHtml}
          { renderAvailableCount() }
        </div>
      </div>
    );
  };

  const manyFares = fares.length > 1;
  const faresHtml = manyFares && renderFareItems();

  const classNameFares = clsx(styles.wrapper_fare, {
    [styles.flexible]: (manyFares && !showDetails),
  });

  const typeIcon = showDetails ? 'upCircle' : 'downCircle';

  const renderTooltipContent = () => {
    const renderListTextErrors = () => item.travelPolicyValidationErrors?.map((
      textPolicy,
    ) => (
      <li key={ textPolicy }>
        <Text color='white' type='NORMAL_14'>
          {getTextPolicy(textPolicy, EServiceTypes.Airline, listTravelPolicies)}
        </Text>
      </li>
    ));

    return (
      <div className={ styles.tooltip_content }>
        <Text color='white' type='NORMAL_16'>
          {LABELS.HEADING_TOOLTIP_CONTENT(selectPolicy.policyName)}
        </Text>
        <ul className={ styles.list_policy_text }>
          {renderListTextErrors()}
        </ul>
      </div>
    );
  };

  const renderPolicyTooltip = () => (
    <Tooltip
      show
      position='bottom'
      renderContent={ renderTooltipContent }
    >
      <Text type='NORMAL_14' className={ styles.name_policy }>
        {LABELS.HEADING_TOOLTIP(selectPolicy.policyName)}
      </Text>
    </Tooltip>
  );

  const renderTravelPolicyError = () => {
    if (!item.travelPolicyValidationErrors.length || (selectPolicy.policyName === LABELS.NOT_SPECIFIED && !loadingPolicy)) return null;

    return (
      <div className={ styles.render_error_policy }>
        {renderPolicyTooltip()}
      </div>
    );
  };

  return (
    <ItemPanel
      animationClass={ animationClass }
      className={ styles.item_panel }
      onAnimationEnd={ handleAnimationEnd }
      renderHeader={ renderTravelPolicyError }
    >
      <div className={ styles.wrapper_item }>
        <div className={ styles.item }>
          { renderAirItem() }
          <div className={ classNameFares }>
            { faresHtml }
            { renderFaresDetails() }
          </div>
          <div className={ styles.arrow }>
            <IconButton
              className={ styles.icon }
              iconType={ typeIcon }
              iconColor='blue1'
              onClick={ handleShowSegments }
            />
          </div>
        </div>
        <div className={ styles.action }>
          { renderPriceOrChange() }
        </div>
      </div>
    </ItemPanel>
  );
};

export { AirlineSearchItem };
