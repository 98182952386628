import React from 'react';
import { LinkButton, Select, Text } from 'yw-ui';
import { Dayjs } from 'dayjs';

import { getText } from '@/i18n';

import { formatDate } from '@/app/bi/utils/formatDate.ts';

import { SearchSubMenu } from './components/SearchSubMenu';
import { AirlineSearchRoute } from '../AirlineSearchRoute';

import { TRAVELLERS_OPTIONS } from '@/app/bi/constants/airline.ts';
import { getClassOptions } from '@/app/bi/utils/airlineSearch.ts';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import { useAppSelector } from '@/app/store/hooks/redux.ts';

import styles from './index.module.css';

const LABELS = {
  ADD_FLIGHT: getText('components:menu.air.main.addFlight'),
  SEARCH: getText('common:search'),
  EDIT: getText('common:edit'),
  TRAVELLERS: getText('components:menu.air.main.passengers'),
  CLASS: getText('components:menu.air.main.class'),
  DIRECT: getText('components:menu.air.main.direct'),
  COMPLEX: getText('components:menu.air.main.complex'),
};

interface AirlineSubMenuProps {
  onChangeAdult(value: number): void,
  onChangeFlightClass(value: string): void,
  onSearch(): void,
  onReturnToSearch(): void,
}

const AirlineSubMenu = ({
  onChangeAdult,
  onChangeFlightClass,
  onSearch,
  onReturnToSearch,
}: AirlineSubMenuProps) => {
  const {
    routes,
    travellers,
    flightClass,
    isComplex,
    isValid,
  } = useAppSelector((state) => state.searchAirlineSlice);

  const classOptions = getClassOptions();

  // const handleChangeComplexCheckbox = (value: boolean) => dispatch(updateForm({ isComplex: value }));
  // const handleChangeDirectCheckbox = (value: boolean) => dispatch(updateForm({ isDirect: value }));
  const renderComplex = () => {
    const routesHtml = routes.map((route, ind) => {
      const { from, to, date } = route;

      return (
        <div key={ `airline_readonly_${ind}` } className={ styles.route }>
          <Text color='white' type='NORMAL_14'>
            { `${from.label}` }
            {' '}
            &rarr;
            {' '}
            { `${to.label}` }
          </Text>
          <Text
            type='NORMAL_12'
            color='blue'
            className={ styles.text }
          >
            { `${formatDate(date as Dayjs)}` }
          </Text>
        </div>
      );
    });

    return (
      <SearchSubMenu
        serviceType={ EServiceTypes.Airline }
        disabled={ !isValid }
        buttonLabel={ LABELS.SEARCH }
        searchButton={ false }
        onClick={ onSearch }
      >
        <div className={ styles.submenu }>
          <div className={ styles.readonly }>
            { routesHtml }
          </div>
          <div className={ styles.action }>
            <LinkButton
              theme='large-white'
              onClick={ onReturnToSearch }
            >
              { LABELS.EDIT }
            </LinkButton>
          </div>
        </div>
      </SearchSubMenu>
    );
  };
  if (isComplex) return renderComplex();

  const routesHtml = routes.map((route, ind) => (
    <AirlineSearchRoute
      key={ `airline__row__${ind}` }
      ind={ ind }
      theme='dark'
      route={ route }
      isComplex={ isComplex }
    />
  ));

  return (
    <SearchSubMenu
      serviceType={ EServiceTypes.Airline }
      disabled={ !isValid }
      buttonLabel={ LABELS.SEARCH }
      onClick={ onSearch }
    >
      <div className={ styles.submenu }>
        { routesHtml }
        <div className={ `${styles.select} ${styles.passengers}` }>
          <Select
            withLabel
            className={ styles.wrapper }
            placeholder={ LABELS.TRAVELLERS }
            theme='dark'
            value={ travellers }
            items={ TRAVELLERS_OPTIONS }
            onChange={ onChangeAdult }
          />
        </div>
        <div className={ styles.select }>
          <Select
            withLabel
            className={ styles.wrapper }
            placeholder={ LABELS.CLASS }
            theme='dark'
            value={ flightClass }
            items={ classOptions }
            onChange={ onChangeFlightClass }
          />
        </div>
      </div>
    </SearchSubMenu>
  );
};

export { AirlineSubMenu };
