import { Dayjs } from 'dayjs';

import { getText } from '@/i18n';

import { isValidateEmail } from '@/app/bi/utils/login.ts';
import { dayjsObject, formatDate, getDayjs } from '@/app/bi/utils/formatDate.ts';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';
import { ID_CARD_NUMBER, PASSPORT_NUMBER } from '@/app/bi/constants/regExp.ts';
import {
  EEmployeeFormParam,
  INPUT_PLACEHOLDER_ID_CARD,
  INPUT_PLACEHOLDER_PASSPORT,
  MAX_COUNT_ID_CARD_LENGTH,
  MAX_COUNT_PASSPORT_LENGTH,
  MAX_DATE,
} from '@/app/bi/constants/settings.ts';

import {
  EEmployeeDocumentStatus,
  EEmployeeDocumentType,
  ICompany,
  IEmployeeDocument,
  IEmployeeDocumentModified,
} from '@/app/bi/models/employees.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { IEmployeeGeneralDataState } from '@/app/bi/models/employee.ts';

const LABELS = {
  EMPLOYEE_FORM_ERROR: getText('settings:validationError'),
  INVALID_EMAIL_FORMAT: getText('settings:validationError'),
};

export const mapCompaniesToData = (comp: ICompany[]) => comp.map(({
  name,
  id,
}) => ({
  label: name,
  value: +id,
}));

export const mapTravelPoliciesToData = (comp: ITravelPolicy[]) => comp?.map(({
  id,
  name,
}) => ({
  label: name,
  value: id,
}));

export const transformationDocuments = (
  arrayDocuments: IEmployeeDocument[],
  countryCode: string,
) => arrayDocuments.map(({
  actualVersion: {
    type,
    number,
    lastName,
    firstName,
    firstNameTranslit,
    lastNameTranslit,
    expiryDate,
  },
}) => ({
  type,
  number,
  lastName,
  firstName,
  firstNameTranslit,
  lastNameTranslit,
  countryCode,
  expiryDate: formatDate(expiryDate || getDayjs(), PATTERN.YEAR_MONTH_DAY),
}));

export const isValidDocumentNumber = (value: string, documentType: EEmployeeDocumentType) => {
  if (documentType === EEmployeeDocumentType.IdCard) {
    const regExpIdCard = ID_CARD_NUMBER(MAX_COUNT_ID_CARD_LENGTH);

    return regExpIdCard.test(value);
  }

  return PASSPORT_NUMBER.test(value);
};

export const isValidIdCard = (
  {
    id,
    actualVersion: {
      lastName,
      firstName,
      lastNameTranslit,
      firstNameTranslit,
      countryCode,
      expiryDate,
      number,
    },
  }: IEmployeeDocumentModified,
  isTurkeyCountry: boolean,
): boolean => (
  id === '0' &&
  !!lastName.length &&
  !!firstName.length &&
  !!lastNameTranslit.length &&
  !!firstNameTranslit.length &&
  !!countryCode.length &&
  !!expiryDate &&
  !isTurkeyCountry ? number.length > 0 : number.length === MAX_COUNT_ID_CARD_LENGTH
);

export const isDocumentsFilled = (
  documents: IEmployeeDocumentModified[],
  idCard: IEmployeeDocumentModified,
  isNotPassportLength: boolean,
): boolean => {
  const isDocumentDataValid = (doc: IEmployeeDocumentModified, lengthCondition: number): boolean => {
    const actualVersion = doc.actualVersion;
    if (!actualVersion) return false;

    const {
      firstName,
      lastName,
      lastNameTranslit,
      firstNameTranslit,
      number,
      expiryDate,
    } = actualVersion;

    const isNumberValid = isNotPassportLength ? number.length > 0 : number.length === lengthCondition;

    return (
      Boolean(firstName) &&
      Boolean(lastName) &&
      Boolean(lastNameTranslit) &&
      Boolean(firstNameTranslit) &&
      isNumberValid &&
      Boolean(expiryDate)
    );
  };

  const isDocumentFilled = documents.every(doc => isDocumentDataValid(doc, MAX_COUNT_PASSPORT_LENGTH));
  const isIdCardFilled = isDocumentDataValid(idCard, MAX_COUNT_ID_CARD_LENGTH);

  const isIdCardUntouchedOrEmpty = !idCard.hasBeenChangedData || (
    !idCard.actualVersion?.firstName &&
    !idCard.actualVersion?.lastName &&
    !idCard.actualVersion?.firstNameTranslit &&
    !idCard.actualVersion?.lastNameTranslit &&
    !idCard.actualVersion?.number &&
    !idCard.actualVersion?.expiryDate
  );

  if (isIdCardUntouchedOrEmpty) {
    return isDocumentFilled;
  }

  return isDocumentFilled && isIdCardFilled;
};

export const transformationUpdateVersion = (
  arrayDocuments: IEmployeeDocumentModified[],
  countryCode: string,
) => arrayDocuments.filter(({
  hasBeenChangedData,
  actualVersion: {
    status,
  } },
) => status === EEmployeeDocumentStatus.Archived || (status === EEmployeeDocumentStatus.Active && hasBeenChangedData)).map(({
  actualVersion: {
    employeeDocumentId,
    status,
    type,
    number,
    lastName,
    firstName,
    lastNameTranslit,
    firstNameTranslit,
    expiryDate,
  },
}) => ({
  employeeDocumentId,
  documentStatus: status,
  type,
  number,
  lastName,
  firstName,
  lastNameTranslit,
  firstNameTranslit,
  countryCode,
  expiryDate: formatDate(expiryDate || getDayjs(), PATTERN.YEAR_MONTH_DAY),
}));

export const transformationAddedVersion = (
  arrayDocuments: IEmployeeDocumentModified[],
  countryCode: string,
) => arrayDocuments.filter(({ id }) => id === '0' || !id.length).map(({
  actualVersion: {
    type,
    number,
    lastName,
    firstName,
    lastNameTranslit,
    firstNameTranslit,
    expiryDate,
  } }) => ({
  type,
  number,
  lastName,
  firstName,
  firstNameTranslit,
  lastNameTranslit,
  countryCode,
  expiryDate: formatDate(expiryDate || getDayjs(), PATTERN.YEAR_MONTH_DAY),
}));

export const formationDocumentsAddedVersion = (
  arrayDocuments: IEmployeeDocumentModified[],
) => arrayDocuments.filter(({ id }) => !id.length || id === '0');

export const formationDocumentsUpdateVersion = (arrayDocuments: IEmployeeDocumentModified[]) =>
  arrayDocuments.filter(({ id, hasBeenChangedData, actualVersion }) =>
    (!!id.length && hasBeenChangedData) ||
    (id.length && actualVersion.status === EEmployeeDocumentStatus.Archived),
  );

export const getPlaceholderPassport = (
  docType: EEmployeeDocumentType,
  isTurkeyCountry: boolean,
): string => {
  if (!isTurkeyCountry) {
    return docType === EEmployeeDocumentType.IdCard ? INPUT_PLACEHOLDER_ID_CARD : INPUT_PLACEHOLDER_PASSPORT;
  }

  return INPUT_PLACEHOLDER_ID_CARD;
};

export const isGeneralFilled = ({
  lastName,
  firstName,
  sex,
  birthday,
  citizenship,
  contactData: {
    email,
    phoneNumber,
    organization,
  },
}: IEmployeeGeneralDataState): boolean => (
  !!firstName &&
  !!lastName &&
  !!birthday &&
  !!citizenship.name.length &&
  !!sex &&
  !!phoneNumber &&
  !!email &&
  !!organization.length
);

const validateBirthDay = (day: Dayjs | null): boolean => (day ? day.isBefore(MAX_DATE) : false);

export const validateField = (value: string, type: EEmployeeFormParam): string => {
  switch (type) {
    case EEmployeeFormParam.Email:
      return isValidateEmail(value) ? '' : LABELS.INVALID_EMAIL_FORMAT;
    case EEmployeeFormParam.PhoneNumber:
      return value.length ? '' : LABELS.EMPLOYEE_FORM_ERROR;
    case EEmployeeFormParam.BirthDay:
      return validateBirthDay(dayjsObject(value)) ? '' : LABELS.EMPLOYEE_FORM_ERROR;
    case EEmployeeFormParam.Country:
      return value.length ? '' : LABELS.EMPLOYEE_FORM_ERROR;
    default:
      return value.trim().length > 0 ? '' : LABELS.EMPLOYEE_FORM_ERROR;
  }
};
