import { getText } from '@/i18n';

import { EMAIL_REGEXP } from '@/app/bi/constants/regExp.ts';

import { IPrepareUser } from '@/app/bi/models/app.ts';

const MAX_PASSWORD_LENGTH = 4;

const LABELS = {
  EMAIL_ERROR: getText('auth:emailError'),
  PASSWORD_EMPTY_ERROR: getText('auth:passwordEmptyError'),
  PASSWORD_MAX_LENGTH_ERROR: (max: number) => getText('auth:passwordMaxLengthError', { max }),
};

export const isValidateEmail = (email: string) => EMAIL_REGEXP.test(email);

export const isValidatePassword = (password: string): string => {
  if (password === '') return LABELS.PASSWORD_EMPTY_ERROR;

  if (password.length < MAX_PASSWORD_LENGTH) return LABELS.PASSWORD_MAX_LENGTH_ERROR(MAX_PASSWORD_LENGTH);

  return '';
};

export const getValidationLoginForm = (email: string, password: string) => {
  const validation = {
    password: '',
    email: '',
  };

  validation.email = isValidateEmail(email) ? '' : LABELS.EMAIL_ERROR;
  validation.password = isValidatePassword(password);

  const isValid = validation.password === '' && validation.email === '';

  return { validation, isValid };
};

export const getValidationSetPasswordForm = (password: string) => {
  const validation = {
    password: '',
  };

  validation.password = isValidatePassword(password);

  const isValid = validation.password === '';

  return { validation, isValid };
};

export const getPayload = (token: string): IPrepareUser => {
  const payload = token.split('.')[1];
  const decodePayload = atob(payload);
  const {
    email,
    user_id,
    account_id,
    is_backoffice_manager,
    employee_id,
  } = JSON.parse(decodePayload);

  return {
    email,
    userId: user_id,
    accountId: account_id,
    isBackofficeManager: is_backoffice_manager === 'true',
    employeeId: employee_id,
  };
};
