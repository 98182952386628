import React from 'react';
import { Checkbox, Collapse } from 'yw-ui';
import { getText, getTextArray } from '@/i18n';

import toDecline from '@/app/bi/utils/toDecline.ts';

import styles from '@/app/pages/AirlineResult/components/Filters/index.module.css';

const LABELS = {
  TRANSFERS: getText('air:result.filters.transfers'),
  WITHOUT_TRANSFERS: getText('services:airline.withoutTransfers'),
  TRANSFERS_DECLINES: getTextArray('services:airline.transferDeclines'),
};
interface DirectCountFilterProps {
  transfersCount: number[],
  values: number[],
  onChange(checkedCities: number[]): void;
}
const DirectCountFilter = ({
  transfersCount,
  values,
  onChange,
}: DirectCountFilterProps) => {
  const handleChange = (value: number) => {
    const valuesSet = new Set(values);

    if (valuesSet.has(value)) {
      valuesSet.delete(value);

      return onChange([...valuesSet]);
    }

    valuesSet.add(value);

    return onChange([...valuesSet]);
  };

  const directCountString = (value: string | number) => {
    if (value === 0 || value === '0') {
      return LABELS.WITHOUT_TRANSFERS;
    }

    const formattedValue = value === 2 ? '2+' : value;
    const declinedWord = toDecline(value as number, LABELS.TRANSFERS_DECLINES);

    return `${formattedValue} ${declinedWord}`;
  };

  const html = transfersCount.map((count, index) => {
    const checked = values.includes(count);

    return (
      <Checkbox
        className={ styles.checkbox }
        key={ `direct_count__${count}_${index}` }
        // @ts-ignore
        id={ `direct_count__${count}_${index}` }
        value={ checked }
        themes='dark'
        onChange={ () => handleChange(count) }
      >
        { directCountString(count) }
      </Checkbox>
    );
  });

  return (
    <Collapse
      opened
      className={ styles.item }
      label={ LABELS.TRANSFERS }
    >
      { html }
    </Collapse>
  );
};

export { DirectCountFilter };
