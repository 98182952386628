import React, { useCallback, useState } from 'react';
import { Input } from 'yw-ui';

import { getText } from '@/i18n';

import { FieldLabel } from '@/app/components/FieldLabel';
import { FormWrapper } from '@/app/components/FormWrapper';

import { ID_CARD_NAME, LATIN_NAME, PASSPORT_NAME } from '@/app/bi/constants/regExp.ts';
import {
  EEmployeeNameParam,
  EEmployeeNameTransliterate,
  INPUT_DEBOUNCE,
} from '@/app/bi/constants/settings.ts';

import { EEmployeeDocumentType } from '@/app/bi/models/employees.ts';

import styles from './styles/index.module.css';
import { INIT_FULL_NAME_ERROR } from '@/app/bi/constants/employee.ts';

const LABELS = {
  FIRST_NAME: getText('settings:firstName'),
  FIRST_NAME_TRANSLIT: getText('settings:firstNameTranslit'),
  LAST_NAME: getText('settings:lastName'),
  EMPLOYEE_FORM_ERROR: getText('settings:validationError'),
  VALIDATION_ERROR: getText('settings:translit.validation'),
};

interface BlockFormFioProps {
  firstName: string;
  lastName: string;
  documentType?: EEmployeeDocumentType;
  isTransliterate?: boolean;
  onChangeFirstName(value: string): void;
  onChangeLastName(value: string): void;
}

const BlockFormFio = ({
  firstName,
  lastName,
  documentType,
  isTransliterate = false,
  onChangeFirstName,
  onChangeLastName,
}: BlockFormFioProps) => {
  const {
    Last: LastNameType,
    First: FirstNameType,
  } = EEmployeeNameParam;
  const {
    LastNameTranslit: LastNameTranslitType,
    FirstNameTranslit: FirstNameTranslitType,
  } = EEmployeeNameTransliterate;
  const labelFirstName = isTransliterate ? LABELS.FIRST_NAME_TRANSLIT : LABELS.FIRST_NAME;

  const [
    error,
    setError,
  ] = useState(INIT_FULL_NAME_ERROR);

  const labelErrorFirstName = isTransliterate ? error.firstNameTranslit : error.firstName;
  const labelErrorLastName = isTransliterate ? error.lastNameTranslit : error.lastName;

  const validateField = (value: string, fieldName: EEmployeeNameParam | EEmployeeNameTransliterate) => {
    const isFieldEmpty = !value.trim().length;
    let errorMessage = isFieldEmpty ? LABELS.EMPLOYEE_FORM_ERROR : '';

    if (isTransliterate && !isFieldEmpty) {
      const isValidValue = LATIN_NAME.test(value);

      if (!isValidValue) errorMessage = LABELS.VALIDATION_ERROR;
    }

    setError({ ...error, [fieldName]: errorMessage });
  };

  const getFilteredValue = (value: string) => {
    if (!isTransliterate) {
      if (documentType && documentType === EEmployeeDocumentType.ForeignPassport) {
        const validationRexExp = documentType === EEmployeeDocumentType.ForeignPassport ? ID_CARD_NAME : PASSPORT_NAME;

        return value.replace(validationRexExp, '');
      }

      return value.replace(ID_CARD_NAME, '');
    }

    return value.replace(PASSPORT_NAME, '');
  };

  const handleOnChangeLastName = useCallback((value: string) => {
    const fieldName = isTransliterate ? LastNameTranslitType : LastNameType;

    validateField(value, fieldName);

    const filteredValue = getFilteredValue(value);

    onChangeLastName(filteredValue);
  }, [validateField]);

  const handleOnChangeFirstName = useCallback((value: string) => {
    const fieldName = isTransliterate ? FirstNameTranslitType : FirstNameType;

    validateField(value, fieldName);

    const filteredValue = getFilteredValue(value);

    onChangeFirstName(filteredValue);
  }, [validateField]);

  const handleOnBlur = (value: string, fieldName: EEmployeeNameParam) => validateField(value, fieldName);

  return (
    <div className={ styles.main }>
      <FormWrapper className={ styles.row }>
        <div className={ styles.item }>
          <FieldLabel text={ LABELS.LAST_NAME } isRequired/>
          <Input
            value={ lastName }
            onChange={ handleOnChangeLastName }
            onBlur={ () => handleOnBlur(lastName, LastNameType) }
            debounceMs={ INPUT_DEBOUNCE }
            placeholder={ LABELS.LAST_NAME }
            max={ 1000 }
            error={ labelErrorLastName }
          />
        </div>
        <div className={ styles.item }>
          <FieldLabel text={ LABELS.FIRST_NAME } isRequired/>
          <Input
            value={ firstName }
            onChange={ handleOnChangeFirstName }
            onBlur={ () => handleOnBlur(firstName, FirstNameType) }
            debounceMs={ INPUT_DEBOUNCE }
            placeholder={ labelFirstName }
            max={ 1000 }
            error={ labelErrorFirstName }
          />
        </div>
      </FormWrapper>
    </div>
  );
};

export { BlockFormFio };
