import React, { useEffect, useState } from 'react';
import { BackLink, PageLoader, Text } from 'yw-ui';
import { useNavigate, useParams } from 'react-router-dom';

import { getText } from '@/i18n';

import { useGetTripByIdQuery } from '@/app/bi/api/tripApi.ts';
import {
  useDownloadMutation,
  useLazyGetTripAirlineIdQuery,
  useSendEmailAirlineMutation,
} from '@/app/bi/api/documentApi.ts';
import { useAppSelector } from '@/app/store/hooks/redux.ts';

import { TripHeader } from './components/TripHeader';
import { TripCardLayout } from './components/Layout/TripCardLayout';
import { CardAirLine } from '@/app/pages/TripsDetail/components/Cards/CardAirLine';
import { CardHotel } from '@/app/pages/TripsDetail/components/Cards/CardHotel';

import { getDayjs } from '@/app/bi/utils/formatDate.ts';
import { notification } from '@/app/bi/utils/not.ts';
import { scrollToTop } from '@/app/bi/utils/scrollToTop.ts';
import { downloadFile } from '@/app/bi/utils/downloads.ts';

import ROUTES from '@/app/bi/constants/routes.ts';
import { SERVICE_ICON } from '@/app/bi/constants/trips.ts';
import { SERVICE_TYPE } from '@/app/bi/constants/serviceType.ts';
import { ENotificationActionType } from '@/app/bi/constants/settings.ts';

import {
  ETripStatus,
  IAirlineTripItems,
  ICustomTripItem,
  IHotelTripItems,
} from '@/app/bi/models/tripsTypes.ts';
import { EDocumentType, EPrefixFileName } from '@/app/bi/models/documents.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum';

import styles from './index.module.css';

const LABELS = {
  LOADER: getText('trip:loader'),
  BACK: getText('trip:back'),
  NUMBER_TICKET: getText('trip:numberTicket'),
  BOOKING_APPROVE: getText('trip:documents.bookingApprove'),
  TICKET: getText('trip:documents.ticket'),
  NOTIFICATION: {
    TITLE: getText('trip:notification.title'),
    DESCRIPTION: getText('trip:notification.description'),
  },
  NOTIFICATION_SEND_EMAIL: {
    SUCCESS: {
      TITLE: getText('trip:notificationSendEmail.success.title'),
      DESCRIPTION: getText('trip:notificationSendEmail.success.description'),
    },
    ERROR: {
      TITLE: getText('trip:notificationSendEmail.error.title'),
      DESCRIPTION: getText('trip:notificationSendEmail.error.description'),
    },
  },
};

const currentDate = getDayjs();

const defaultStateTripData = {
  id: '',
  checkoutDate: currentDate,
  checkinDate: currentDate,
  createdDate: currentDate,
  name: '',
  status: ETripStatus.AwaitingConfirmation,
  hotelTripItems: [],
  airlineTripItems: [],
  amount: 0,
  accountId: 0,
  creatorUserId: '',
  customTripItems: [],
  currency: ECurrencyCode.USD,
};

const TripsDetailPage = () => {
  const navigate = useNavigate();

  const { tripId = '' } = useParams();

  const { locale } = useAppSelector((state) => state.appSlice);

  const {
    data: tripDetails = defaultStateTripData,
    isLoading,
  } = useGetTripByIdQuery(tripId);

  const [getTripDocumentId] = useLazyGetTripAirlineIdQuery();
  const [downloadDocument] = useDownloadMutation();
  const [
    sendVoucherAirline,
    {
      isError,
      isLoading: isLoadingSendEmail,
    },
  ] = useSendEmailAirlineMutation();

  const [
    tickedId,
    setTickedId,
  ] = useState<string | null>(null);
  const [
    inputEmailValue,
    setInputEmailValue,
  ] = useState<string>('');
  const [
    modalActive,
    setModalActive,
  ] = useState<boolean>(false);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (isLoading) {
    return <PageLoader text={ LABELS.LOADER } />;
  }

  const handleBackLink = () => navigate(ROUTES.TRIPS.MAIN, { state: { preserveFilters: true } });

  const handleTripRename = (value: string) => console.log(value);

  const handleOpenModal = (value: boolean) => {
    setModalActive(value);
    setInputEmailValue('');
  };

  const getServiceIcons = (
    airlineTripItems: IAirlineTripItems[] = [],
    hotelTripItems: IHotelTripItems[] = [],
    customTripItems: ICustomTripItem[] = [],
  ) => {
    const serviceIcons = [];

    if (airlineTripItems.length) serviceIcons.push(SERVICE_ICON[EServiceTypes.Airline]);

    if (hotelTripItems.length) serviceIcons.push(SERVICE_ICON[EServiceTypes.Hotel]);

    if (customTripItems.length) serviceIcons.push(SERVICE_ICON[EServiceTypes.Custom]);

    return serviceIcons;
  };

  const arrayIconService = () => getServiceIcons(
    tripDetails.airlineTripItems,
    tripDetails.hotelTripItems,
    tripDetails.customTripItems,
  );

  const amountPriceTrips = () => ((tripDetails && tripDetails.amount) ? String(tripDetails.amount) : '');

  const handleDownloadTrip = async (ticketID: string, tripNumber: string, serviceType: EServiceTypes) => {
    setTickedId(ticketID);

    try {
      const documentId = await getTripDocumentId({
        itemId: ticketID,
        locale,
        serviceType,
        fileType: EDocumentType.Pdf,
      }).unwrap();

      const responseFile = await downloadDocument(documentId).unwrap();

      await downloadFile({
        fileBlob: responseFile,
        fileType: EDocumentType.Pdf,
        filenamePrefix: EPrefixFileName.Trip,
        identifier: tripNumber,
      });
    } catch (error) {
      notification({
        title: LABELS.NOTIFICATION.TITLE,
        content: (
          <Text type='NORMAL_16' color='gray-7'>
            {LABELS.NOTIFICATION.DESCRIPTION}
          </Text>
        ),
        type: ENotificationActionType.error,
      });
    } finally {
      setTickedId(null);
    }
  };

  const handleSendEmailTrip = async (id: string, serviceType: EServiceTypes) => {
    try {
      if (inputEmailValue.length) {
        await sendVoucherAirline({
          itemId: id,
          email: inputEmailValue,
          locale,
          serviceType,
          fileType: EDocumentType.Pdf,
        });
      }

      if (!isError) {
        notification({
          title: LABELS.NOTIFICATION_SEND_EMAIL.SUCCESS.DESCRIPTION,
          type: ENotificationActionType.success,
        });
      }
    } catch (error) {
      notification({
        title: LABELS.NOTIFICATION_SEND_EMAIL.ERROR.DESCRIPTION,
        type: ENotificationActionType.error,
      });
    }

    handleOpenModal(false);
  };

  const renderCardLayoutHeader = (typeTrip: EServiceTypes, ticketNumber?: string | null) => (
    typeTrip === SERVICE_TYPE.AIR ? (
      <span className={ styles.ticketNumber }>
        {`${LABELS.TICKET} #${ticketNumber || LABELS.NUMBER_TICKET}`}
      </span>
    ) : (
      <span className={ styles.ticketNumber }>{LABELS.BOOKING_APPROVE}</span>
    ));

  const renderCardsAirline = () => tripDetails?.airlineTripItems?.map((
    card,
  ) => {
    const checkIdCondition = tickedId === card.id;

    return (
      <TripCardLayout
        key={ card.id }
        type={ EServiceTypes.Airline }
        tickedId={ card.id }
        inputValue={ inputEmailValue }
        ticketNumber={ card.actualVersion.ticketNumber }
        isSendEmail={ isLoadingSendEmail }
        isResponseId={ checkIdCondition }
        modalActive={ modalActive }
        renderHeader={ renderCardLayoutHeader(EServiceTypes.Airline, card.actualVersion.ticketNumber) }
        onDownloadTrip={ handleDownloadTrip }
        onSendEmail={ handleSendEmailTrip }
        onChangeInput={ setInputEmailValue }
        onChangeIsActiveModal={ handleOpenModal }
      >
        <CardAirLine data={ card } />
      </TripCardLayout>
    );
  });

  const renderCardsHotel = () => tripDetails?.hotelTripItems?.map((
    card,
  ) => {
    const checkIdCondition = tickedId === card.id;

    return (
      <TripCardLayout
        key={ card.id }
        type={ EServiceTypes.Hotel }
        tickedId={ card.id }
        inputValue={ inputEmailValue }
        isSendEmail={ isLoadingSendEmail }
        isResponseId={ checkIdCondition }
        modalActive={ modalActive }
        renderHeader={ renderCardLayoutHeader(EServiceTypes.Hotel) }
        onDownloadTrip={ handleDownloadTrip }
        onSendEmail={ handleSendEmailTrip }
        onChangeInput={ setInputEmailValue }
        onChangeIsActiveModal={ handleOpenModal }
      >
        <CardHotel data={ card } />
      </TripCardLayout>
    );
  });

  return (
    <div className={ styles.wrapper }>
      <BackLink
        textColor='blue-1'
        iconColor='blue1'
        text={ LABELS.BACK }
        link={ ROUTES.TRIPS.MAIN }
        onClick={ handleBackLink }
      />
      <div className={ styles.header_container }>
        <TripHeader
          onTripRename={ handleTripRename }
          tripStatus={ tripDetails.status }
          price={ amountPriceTrips() }
          name={ tripDetails.name }
          currency={ tripDetails.currency }
          createDate={ tripDetails.createdDate }
          checkinDate={ tripDetails.checkinDate }
          checkoutDate={ tripDetails.checkoutDate }
          servicesIcon={ arrayIconService() }
        />
      </div>
      <div className={ styles.list_container }>
        {renderCardsAirline()}
        {renderCardsHotel()}
      </div>
    </div>
  );
};

export { TripsDetailPage };
