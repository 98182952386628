import { ywApiPrivate } from './index.ts';

import {
  IAddCartEmployeeRequest,
  IAddHotelCartRequest,
  ICancelRules,
  ICartResponse,
  IRemoveEmployeesRequest,
  ISendReasonErrorPolicy,
  IUpdateCartEmployeeRequest,
  IUpdateTripRequest,
  TCartResponseItem,
} from '@/app/bi/models/cart.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

export const cartApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getCart: build.query<ICartResponse, void>({
      query: () => ({
        url: '/cart',
        method: 'GET',
      }),
    }),
    cartDelete: build.mutation<any[], void>({
      query: () => ({
        url: '/cart',
        method: 'DELETE',
      }),
    }),
    // Airline
    // Добавление в корзину
    addAirline: build.mutation<void, any>({
      query(body) {
        return {
          url: '/cart/airline',
          method: 'POST',
          body,
        };
      },
    }),
    // Hotels
    // Добавление в корзину
    addHotel: build.mutation<void, IAddHotelCartRequest>({
      query(body) {
        return {
          url: '/cart/hotel',
          method: 'POST',
          body,
        };
      },
    }),
    updateCompanyHotelCartItem: build.mutation<TCartResponseItem, { tripItemId: string, companyId: number | null }>({
      query({ tripItemId, companyId }) {
        return {
          url: `/cart/hotel/${tripItemId}/company/${companyId}`,
          method: 'PUT',
        };
      },
    }),
    getCancelRules: build.query<ICancelRules[], void>({
      query: () => ({
        url: '/cart/cancel-rules',
        method: 'GET',
      }),
    }),
    // NEW
    addEmployeeToCartItem: build.mutation<TCartResponseItem, IAddCartEmployeeRequest>({
      query: (body: IAddCartEmployeeRequest) => ({
        url: '/cart/items/employee',
        method: 'POST',
        body,
      }),
    }),
    removeEmployeeToCartItem: build.mutation<TCartResponseItem, IRemoveEmployeesRequest>({
      query: ({ tripItemId, employeeId, serviceType }) => ({
        url: `/cart/items/${tripItemId}/employee/${employeeId}/${serviceType}`,
        method: 'DELETE',
      }),
    }),
    updateEmployeeCartItem: build.mutation<TCartResponseItem, IUpdateCartEmployeeRequest>({
      query: (body: IUpdateCartEmployeeRequest) => ({
        url: '/cart/items/employee',
        method: 'PUT',
        body,
      }),
    }),
    removeCartItem: build.mutation<void, { serviceType: EServiceTypes, tripItemId: string }>({
      query: ({ serviceType, tripItemId }) => ({
        url: `/cart/items/${serviceType}/${tripItemId}`,
        method: 'DELETE',
      }),
    }),
    addCartItemByTrip: build.mutation<void, IUpdateTripRequest>({
      query: (body) => ({
        url: '/cart/items/trip',
        method: 'PUT',
        body,
      }),
    }),
    sendReasonErrorPolicy: build.mutation<TCartResponseItem, ISendReasonErrorPolicy>({
      query: ({ tripItemId, serviceType, body }) => ({
        url: `/cart/items/${serviceType}/${tripItemId}/policy/reason`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetCartQuery,
  useAddHotelMutation,
  useCartDeleteMutation,
  useAddAirlineMutation,
  useGetCancelRulesQuery,
  useRemoveCartItemMutation,
  useAddCartItemByTripMutation,
  useSendReasonErrorPolicyMutation,
  useAddEmployeeToCartItemMutation,
  useUpdateEmployeeCartItemMutation,
  useRemoveEmployeeToCartItemMutation,
  useUpdateCompanyHotelCartItemMutation,
} = cartApi;
