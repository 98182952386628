import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackLink, Button, Checkbox, Icon, PageLoader, Price, Text } from 'yw-ui';
import { getText } from '@/i18n';

import { DialogRules } from '@/app/pages/Checkout/components/DialogRules';
import { CheckoutItem } from '@/app/pages/Checkout/components/CheckoutItem';
import { EmptyCart } from '@/app/pages/Cart/components/EmptyCart';
import { TotalBlock } from '@/app/components/TotalBlock';
import { ProgressBar } from '@/app/pages/Checkout/components/ProgressBar';
import { ItemPanelCollapse } from '@/app/pages/Checkout/components/ItemPanelCollapse';

import { BookingErrorDialog } from './components/BookingErrorDialog';
import { GeneralErrorDialog } from '@/app/pages/Checkout/components/GeneralErrorDialog';

import ROUTES from '@/app/bi/constants/routes.ts';

import { useLazyGetCheckoutCartQuery } from '@/app/bi/api/checkoutApi.ts';
import { useLazyBookingQuery } from '@/app/bi/api/bookingApi.ts';

import { EBookProgressStatus } from '@/app/bi/models/booking.ts';

import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import { checkoutSlice } from '@/app/store/redusers/СheckoutSlice.ts';

import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy';

import { TCartResponseItem } from '@/app/bi/models/cart.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { NEW_SERVICES_ICON } from '@/app/bi/constants/serviceType.ts';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('checkout:title'),
  CART: getText('checkout:finish.cart'),
  ERROR_DIALOG_TITLE: getText('booking:bookingErrorDialog.title'),
  GENERAL_ERROR_DIALOG_TITLE: getText('booking:generalErrorDialog.title'),
  CHECKBOX: {
    ONE: getText('checkout:finish.checkbox.one'),
    TWO: getText('checkout:finish.checkbox.two'),
  },
};

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [accept, setAccept] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(true);

  const dispatch = useAppDispatch();
  const {
    airlinesPrice,
    hotelsPrice,
    totalPrice,
    showErrorDialog,
    showGeneralErrorDialog,
    progressValue,
    progressSpeed,
    showProgressBar,
    progressStatus,
    bookId,
    bookingErrors,
    checkoutData: {
      canBook,
    },
    checkoutData,
    itemsGroupByTrips,
    showHotelsPrice,
    showAirlinesPrice,
  } = useAppSelector((state) => state.checkoutSlice);
  const {
    setShowErrorDialog,
    setShowProgressBar,
    resetStore,
    setProgressValue,
  } = checkoutSlice.actions;

  const [
    getCart,
    { isLoading, isSuccess },
  ] = useLazyGetCheckoutCartQuery({ refetchOnReconnect: true });
  const [booking] = useLazyBookingQuery();
  // const [
  //   getBookingAccessStatus,
  //   { isLoading: isLoadingBookingAccessStatus },
  // ] = useLazyGetBookingAccessStatusQuery();

  const initSearch = async () => {
    setIsCheckoutLoading(true);
    try {
      // const bookingStatus = await getBookingStatus().unwrap();
      await getCart().unwrap();
    } catch (e) {} finally {
      setIsCheckoutLoading(false);
    }
  };

  useEffect(() => {
    initSearch();

    return () => {
      dispatch(resetStore());
    };
  }, []);

  useEffect(() => {
    if (!isLoading && isSuccess && !itemsGroupByTrips.length) {
      // navigate(ROUTES.CART.MAIN);
    }
  }, [itemsGroupByTrips, isLoading, isSuccess]);

  useEffect(() => {
    const isFailed = progressStatus === EBookProgressStatus.Failed;
    const isBooked = progressStatus === EBookProgressStatus.Booked;

    window.onbeforeunload =
      showProgressBar && !isFailed && !isBooked
        ? (e) => {
          e.preventDefault();

          return '';
        }
        : null;

    if (progressStatus === EBookProgressStatus.Booked) {
      showSuccess();
    }
  }, [progressStatus]);

  const handleChangeShowDialog = (value: boolean) => setShowDialog(value);

  const showSuccess = () => navigate(`${ROUTES.CART.SUCCESS}/${bookId}`);

  const handleBooking = async () => {
    dispatch(setShowProgressBar(true));
    await booking();
    dispatch(setProgressValue(0.25));
  };

  const renderRulesDialog = () => {
    if (!showDialog) return null;

    return (
      <DialogRules
        show={ showDialog }
        onClose={ () => handleChangeShowDialog(false) }
      />
    );
  };

  const renderTripItems = (items: TCartResponseItem[]) => items.map((item, index) => (
    <CheckoutItem
      index={ index }
      key={ item.id }
      item={ item }
    />
  ),
  );

  const renderTrips = () => {
    if (!itemsGroupByTrips.length) return null;

    return itemsGroupByTrips.map(({
      tripId,
      tripName,
      totalPrice: totalPriceTripItems,
      serviceTypes,
      items,
    }) => (
      <ItemPanelCollapse
        key={ tripId || 'new_trip' }
        renderHeader={ () =>
          renderHeader(tripName, totalPriceTripItems, serviceTypes)
        }
      >
        {renderTripItems(items)}
      </ItemPanelCollapse>
    ));
  };

  const renderLoading = () => (
    <PageLoader />
  );

  const renderHeader = (tripName: string | null, price: number, serviceTypes: EServiceTypes[]) => (
    <>
      <div className={ styles.title } >
        <Text type='bold_22' fontWeight={ 700 }>{ tripName }</Text>
        <div className={ styles.icons } >
          {serviceTypes.map((serviceType) => (
            <Icon key={ serviceType } type={ NEW_SERVICES_ICON[serviceType] } circleColor='white'/>
          ))}
        </div>
      </div>
      <div className={ styles.actions }>
        <Price
          value={ price }
          type='bold_22'
          typeCurrency='bold_22'
          costType={ CURRENCY_SYMBOLS.TRY }
          marginSmall
        />
      </div>
    </>
  );

  const renderEmpty = () => <EmptyCart />;

  const renderAcceptRulesCheckbox = () => {
    if (!canBook) return null;

    return (
      <div className={ styles['accept-wrapper'] }>
        <Checkbox
          value={ accept }
          themes='dark'
          onChange={ setAccept }
        />
        <div>
          <Text type='NORMAL_12'>{LABELS.CHECKBOX.ONE}</Text>
          <Button
            type='text'
            onClick={ () => {
              handleChangeShowDialog(true);
            } }
          >
            { LABELS.CHECKBOX.TWO }
          </Button>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (!itemsGroupByTrips.length) return renderEmpty();

    return (
      <>
        <div className={ styles.content }>
          <div className={ styles.items_wrapper }>
            {renderTrips()}
          </div>
          <div className={ styles.total_block_wrapper }>
            <TotalBlock
              showHotelsPrice={ showHotelsPrice }
              showAirlinesPrice={ showAirlinesPrice }
              hotelsPrice={ hotelsPrice }
              airlinesPrice={ airlinesPrice }
              totalPrice={ totalPrice }
              disabledButton={ !accept }
              loading={ showProgressBar }
              checkoutData={ checkoutData }
              onClick={ handleBooking }
            />
            {renderAcceptRulesCheckbox()}
          </div>
        </div>
        { renderRulesDialog() }
      </>
    );
  };

  const handleClose = () => {
    dispatch(setShowErrorDialog(!showErrorDialog));
    navigate(ROUTES.CART.MAIN);
  };

  const renderBookingErrorDialog = () =>
    (
      <BookingErrorDialog
        show={ showErrorDialog }
        title={ LABELS.ERROR_DIALOG_TITLE }
        bookingErrors={ bookingErrors }
        onClose={ handleClose }
      />
    );

  const renderGeneralErrorDialog = () =>
    (
      <GeneralErrorDialog
        show={ showGeneralErrorDialog }
        title={ LABELS.GENERAL_ERROR_DIALOG_TITLE }
        onClose={ handleClose }
      />
    );

  const renderProgressBar = () => showProgressBar && <ProgressBar
    progressSpeed={ progressSpeed }
    progressValue={ progressValue }
    onClick={ () => setShowProgressBar(false) }
  />;

  if (isLoading || isCheckoutLoading) return renderLoading();

  const overlayHtml = renderProgressBar();
  const bookingErrorDialogHtml = showErrorDialog && renderBookingErrorDialog();
  const generalErrorDialogHtml = showGeneralErrorDialog && renderGeneralErrorDialog();

  return (
    <div className={ styles.wrapper }>
      <BackLink
        text={ LABELS.CART }
        link={ ROUTES.CART.MAIN }
        iconColor='blue1'
        textColor='light-blue-4'
        className={ styles.back }
      />
      <div className={ styles.header }>
        <Text type='bold_32' fontWeight={ 700 } lineHeight={ '110%' }>{LABELS.TITLE}</Text>
      </div>
      {renderContent()}
      {overlayHtml}
      {bookingErrorDialogHtml}
      {generalErrorDialogHtml}
    </div>
  );
};

export { CheckoutPage };
