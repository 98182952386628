import React from 'react';
import { Button, Dialog, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { ICartErrors } from '@/app/bi/models/cart.ts';

import styles from './index.module.css';

const LABELS = {
  BACK_TO_CART: getText('booking:backToCart'),
  REASON_ERROR: getText('booking:bookingErrorDialog.reasonError'),
};

interface BookingErrorDialogProps {
  show: boolean,
  title: string;
  bookingErrors: ICartErrors,
  onClose(): void,
}

const BookingErrorDialog = ({
  show,
  title,
  bookingErrors,
  onClose,
}: BookingErrorDialogProps) => {
  const renderMainErrors = () => (
    <div className={ styles.rules }>
      <Text type='NORMAL_14' fontWeight={ 600 }>{LABELS.REASON_ERROR}</Text>
      <div className={ styles.info }>
        {bookingErrors.bookingErrors.map((error, idx) =>
          <Text key={ `error-${idx}` } type='NORMAL_12'> {error}</Text>,
        )}
      </div>
    </div>
  );

  // const renderError = (error: ICartErrorsItem) => {
  //   if (typeError === EServiceTypes.Airline) {
  //     const dataError = error.item as IAirlineVersion;
  //
  //     return (
  //       <div className={ styles.header }>
  //         <Text type='NORMAL_14'>{CART_ITEM_TITLE[error.serviceType]}</Text>
  //         {
  //           dataError.routes.map((route) => {
  //             const { segments } = route;
  //             const firstSegment = segments[0];
  //             const lastSegment = segments[segments.length - 1];
  //
  //             return (
  //               <Text type='NORMAL_14' key={ route.id }>
  //                 {`${firstSegment.departureCity}, ${firstSegment.departureAirportCode} — `}
  //                 {`${lastSegment.arrivalCity}, ${firstSegment.arrivalAirportCode}`}
  //               </Text>
  //             );
  //           })
  //         }
  //         <Text type='NORMAL_14'>
  //           {formatDate(dayjsObject(dataError.departureDate))} —
  //           {formatDate(dayjsObject(dataError.arrivalDate)) }
  //         </Text>
  //       </div>
  //     );
  //   }
  //
  //   return null;
  // };

  const renderCartItemsErrors = () => bookingErrors?.cartItemErrors?.map((
    error,
  ) => (
    <div key={ error.itemId } className={ styles.error }>
      <div className={ styles.content }>
        <Text type='NORMAL_14' fontWeight={ 600 }>
          {LABELS.REASON_ERROR}
        </Text>
        <div className={ styles.info }>
          {error.errors.map((textError, idx) =>
            <Text key={ `error-${idx}` } type='NORMAL_12' color='gray-7'>
              {textError.message}
            </Text>)}
        </div>
      </div>
    </div>
  ));

  const renderErrors = () => {
    if (!bookingErrors?.bookingErrors?.length && !bookingErrors?.cartItemErrors?.length) return null;

    if (bookingErrors?.bookingErrors?.length) return renderMainErrors();

    return renderCartItemsErrors();
  };

  return (
    <Dialog
      showClosing
      show={ show }
      className={ styles.dialog }
      onChange={ onClose }
    >
      <div className={ styles.wrapper }>
        <div className={ styles.content }>
          <Text type='bold_20' fontWeight={ 700 } >
            {title}
          </Text>
          <div className={ styles.errors }>
            { renderErrors() }
          </div>
        </div>
        <div className={ styles.action }>
          <Button type='secondary' onClick={ onClose }>
            {LABELS.BACK_TO_CART}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { BookingErrorDialog };
