import { getText } from '@/i18n';

import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy.ts';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { EAirlineValidationError } from '@/app/bi/models/airlineTypes.ts';
import { EHotelValidationError } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import {
  ICartResponseAirlineItemTravelPolicyError,
  ICartResponseHotelItemTravelPolicyError,
} from '@/app/bi/models/cart.ts';

const LABELS = {
  POLICIES_TEXTS: {
    MAX_PRICE: (
      maxPrice: string,
      doublePrice: string,
    ) => getText('components:travelPolicies.policiesTexts.maxPrice', { maxPrice, doublePrice }),
    PRICE_DIFFERENCE_LIMIT: (
      price: string,
    ) => getText('components:travelPolicies.policiesTexts.priceDifferenceLimit', { price }),
    CLASS: getText('components:travelPolicies.policiesTexts.class'),
    REFUNDABLE: getText('components:travelPolicies.policiesTexts.refundable'),
    DAY_BUY_LIMIT: (
      day: number,
    ) => getText('components:travelPolicies.policiesTexts.daysBuyLimit', { day }),
    BAGGAGE: getText('components:travelPolicies.policiesTexts.baggage'),
    WEEKDAYS: getText('components:travelPolicies.policiesTexts.weekdays'),
    MAX_PRICE_HOTEL: (
      maxPrice: number,
    ) => getText('components:travelPolicies.policiesTexts.maxPriceHotel', { maxPrice }),
    WEEKDAYS_HOTEL: getText('components:travelPolicies.policiesTexts.weekdaysHotel'),
  },
  TAKING_ARRIVAL: getText('components:travelPolicies.takingArrival'),
  EXCLUDING_EARLY_CHECK_IN: getText('components:travelPolicies.excludingEarlyCheckIn'),
};

export const getTextPolicy = (
  typePolicy: EAirlineValidationError | EHotelValidationError,
  typeError: EServiceTypes,
  listTravelPolicies: ITravelPolicy[],
) => {
  if (!listTravelPolicies.length) return '';

  const policyInfo = listTravelPolicies.find(({ id }) => id);

  if (!policyInfo) return '';

  switch (typeError) {
    case EServiceTypes.Airline: {
      const {
        maxPrice,
        priceDifferenceLimit,
        maxPriceCurrency,
        daysBuyLimit,
      } = policyInfo.airlinePolicy || {};

      const priceValue = `${maxPrice} ${CURRENCY_SYMBOLS[maxPriceCurrency]}`;
      const priceFromAndTo = `${maxPrice * 2} ${CURRENCY_SYMBOLS[maxPriceCurrency]}`;
      const priceDifference = `${priceDifferenceLimit} ${CURRENCY_SYMBOLS[maxPriceCurrency]}`;

      switch (typePolicy) {
        case EAirlineValidationError.MaxPrice:
          return LABELS.POLICIES_TEXTS.MAX_PRICE(priceValue, priceFromAndTo);
        case EAirlineValidationError.PriceDifferenceLimit:
          return LABELS.POLICIES_TEXTS.PRICE_DIFFERENCE_LIMIT(priceDifference);
        case EAirlineValidationError.Class:
          return LABELS.POLICIES_TEXTS.CLASS;
        case EAirlineValidationError.Refundable:
          return LABELS.POLICIES_TEXTS.REFUNDABLE;
        case EAirlineValidationError.DaysBuyLimit:
          return LABELS.POLICIES_TEXTS.DAY_BUY_LIMIT(daysBuyLimit);
        case EAirlineValidationError.Baggage:
          return LABELS.POLICIES_TEXTS.BAGGAGE;
        case EAirlineValidationError.Weekdays:
          return LABELS.POLICIES_TEXTS.WEEKDAYS;
        default:
          return '';
      }
    }
    case EServiceTypes.Hotel: {
      const { eclc, maxPricePerNight } = policyInfo.hotelPolicy || {};

      switch (typePolicy) {
        case EHotelValidationError.MaxPricePerNight:
          return LABELS.POLICIES_TEXTS.MAX_PRICE_HOTEL(maxPricePerNight);
        case EHotelValidationError.Refundable:
          return LABELS.POLICIES_TEXTS.REFUNDABLE;
        case EHotelValidationError.Weekdays:
          return LABELS.POLICIES_TEXTS.WEEKDAYS_HOTEL;
        case EHotelValidationError.Eclc:
          return eclc ? LABELS.TAKING_ARRIVAL : LABELS.EXCLUDING_EARLY_CHECK_IN;
        default:
          return '';
      }
    }
    default:
      return '';
  }
};

export const getPoliciesNames = (
  listTravelPolicies: ITravelPolicy[],
  travelPoliciesErrors: ICartResponseAirlineItemTravelPolicyError[] | ICartResponseHotelItemTravelPolicyError[],
): string => {
  const filteredPolicies = listTravelPolicies.filter((policy) =>
    travelPoliciesErrors.some((error) => error.travelPolicyId === policy.id),
  );

  const policyNames = filteredPolicies.map(({ name }) => name);

  return policyNames.filter(name => name !== '').join(', ');
};
