import { Dayjs } from 'dayjs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getText } from '@/i18n';

import { dayjsObject } from '@/app/bi/utils/formatDate.ts';
import transliterate from '@/app/bi/utils/transliterate.ts';

import {
  EEmployeeFormParam,
  EEmployeeNameParam,
  EEmployeeNameTransliterate,
  ETypesSex,
} from '@/app/bi/constants/settings.ts';
import COUNTRIES from '@/app/bi/constants/countries.ts';

import { IEmployeeInitialState, IEmployeePolicyItem } from '@/app/bi/models/employee.ts';
import {
  EEmployeeDocumentType,
  EEmployeeDocumentStatus,
  IEmployeeDocument,
  IEmployeeDocumentModified,
  IEmployeeObj,
} from '@/app/bi/models/employees.ts';
import { ICitizenshipItem } from '@/app/bi/models/profile.ts';

const {
  Active,
  Archived,
} = EEmployeeDocumentStatus;

const {
  LastNameTranslit,
  FirstNameTranslit,
} = EEmployeeNameTransliterate;

const {
  IdCard,
  ForeignPassport,
  DomesticPassport,
} = EEmployeeDocumentType;

const {
  TR: {
    CODE,
    CODE3,
  },
} = COUNTRIES;

const LABELS = {
  TURKEY: getText('settings:turkey'),
};

const initialDocumentPassport: IEmployeeDocumentModified = {
  id: '',
  employeeId: '',
  hasBeenChangedData: false,
  actualVersion: {
    id: '',
    firstName: '',
    lastName: '',
    lastNameTranslit: '',
    firstNameTranslit: '',
    number: '',
    status: Active,
    type: ForeignPassport,
    createdDate: '',
    employeeDocumentId: '',
    creatorUserId: '',
    expiryDate: null,
    countryCode: CODE,
  },
  versions: null,
};

export const initialIdCard = {
  id: '',
  employeeId: '',
  hasBeenChangedData: false,
  actualVersion: {
    id: '',
    firstName: '',
    lastName: '',
    lastNameTranslit: '',
    firstNameTranslit: '',
    number: '',
    status: Active,
    type: IdCard,
    createdDate: '',
    employeeDocumentId: '',
    creatorUserId: '',
    expiryDate: null,
    countryCode: '',
  },
  versions: null,
};

const initialState: IEmployeeInitialState = {
  generalData: {
    employeeId: '',
    firstName: '',
    lastName: '',
    birthday: null,
    countryCode: '',
    citizenship: {
      name: LABELS.TURKEY,
      code: CODE,
      code3: CODE3,
    },
    sex: null,
    contactData: {
      phoneNumber: '',
      email: '',
      organization: [],
    },
    travelPolicyId: {
      label: '',
      value: null,
    },
    bonusCards: [{ airlineCode: '', number: '' }],
  },
  idCard: initialIdCard,
  idCardArchive: initialIdCard,
  lastCountryCode: '',
  documents: [],

};

export const employeeSlice = createSlice({
  name: 'employeeSlice',
  initialState,
  reducers: {
    setBirthDay: (state, action: PayloadAction<Dayjs | null>) => {
      state.generalData.birthday = action.payload;
    },
    setSex: (state, action: PayloadAction<ETypesSex>) => {
      state.generalData.sex = action.payload;
    },
    setExpiredDateIdCard: (state, action: PayloadAction<Dayjs | null>) => {
      state.idCard = updateDocumentVersion(state.idCard, { expiryDate: action.payload });
    },
    setExpiredDateDocument: (
      state,
      action: PayloadAction<{ id: string | number, value: Dayjs | null }>,
    ) => {
      const { id, value } = action.payload;

      if (typeof id === 'number') {
        state.documents[id] = updateDocumentVersion(state.documents[id], { expiryDate: value });
      } else {
        state.documents = state.documents.map((document) =>
          (document.id === id ? updateDocumentVersion(document, { expiryDate: value }) : document),
        );
      }
    },
    setNameGeneral: (
      state,
      action: PayloadAction<{ value: string, type: EEmployeeNameParam }>,
    ) => {
      state.generalData[action.payload.type] = action.payload.value;
    },
    setContactDataGeneral: (
      state,
      action: PayloadAction<{ value: string, type: EEmployeeFormParam }>,
    ) => {
      state.generalData.contactData = {
        ...state.generalData.contactData,
        [action.payload.type]: action.payload.value,
      };
    },
    setOrganization: (state, action: PayloadAction<number[]>) => {
      state.generalData.contactData.organization = action.payload;
    },
    setCountry: (state, action: PayloadAction<ICitizenshipItem>) => {
      state.generalData.citizenship = action.payload;
    },
    setLastCountryCode: (state, action: PayloadAction<string>) => {
      state.lastCountryCode = action.payload;
    },
    setDataNamePassport: (
      state,
      action: PayloadAction<{ index: number, value: string, type: EEmployeeNameParam }>,
    ) => {
      const { index, value, type } = action.payload;
      const changesDocumentVersion = {
        [type]: value,
        [type === EEmployeeNameParam.First ? FirstNameTranslit : LastNameTranslit]: transliterate(value),
      };

      state.documents[index] = updateDocumentVersion(state.documents[index], changesDocumentVersion);
    },
    setFioTransliteratePassport: (
      state,
      action: PayloadAction<{ index: number, value: string, type: EEmployeeNameTransliterate }>,
    ) => {
      const { index, value, type } = action.payload;

      state.documents[index] = updateDocumentVersion(state.documents[index], {
        [type]: transliterate(value),
      });
    },
    setPassportNumber: (
      state,
      action: PayloadAction<{ id: number, value: string }>,
    ) => {
      const { id, value } = action.payload;

      state.documents[id] = updateDocumentVersion(state.documents[id], { number: value });
    },
    addPassport: (state) => {
      state.documents.push({ ...initialDocumentPassport });
    },
    deletePassport: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      const document = state.documents[index];

      if (!document.id) {
        state.documents = state.documents.filter((
          _,
          ind,
        ) => ind !== index);
      } else {
        state.documents[index] = updateDocumentVersion(document, { status: Archived });
      }
    },
    setDataIdCard: (
      state,
      action: PayloadAction<{ value: string, type: EEmployeeNameParam }>,
    ) => {
      const { type, value } = action.payload;
      const creationDocumentParam = '0';
      const transliteratedValue = transliterate(value);

      if (state.idCard.actualVersion.status === Archived) {
        const changesDocumentVersion = {
          [type]: value,
          [type === EEmployeeNameParam.First ? FirstNameTranslit : LastNameTranslit]: transliteratedValue,
          status: Active,
        };

        state.idCard = updateDocumentVersion(state.idCardArchive, changesDocumentVersion);
        state.idCardArchive = { ...initialIdCard };
      } else {
        const isCreateOrActiveIdCard = state.idCard.id !== '' ? state.idCard.id : creationDocumentParam;
        const currentDocumentVersion = { ...state.idCard, id: isCreateOrActiveIdCard };
        const changesDocumentVersion = {
          type: state.generalData.citizenship.code3 === CODE3 ? IdCard : DomesticPassport,
          [type]: value,
          [type === EEmployeeNameParam.First ? FirstNameTranslit : LastNameTranslit]: transliteratedValue,
        };

        state.idCard = updateDocumentVersion(currentDocumentVersion, changesDocumentVersion);
      }
    },
    setFioTransliterateIdCard: (
      state,
      action: PayloadAction<{ value: string, type: EEmployeeNameTransliterate }>,
    ) => {
      const { value, type } = action.payload;
      const transliteratedValue = transliterate(value);

      if (state.idCard.actualVersion.status === Archived) {
        state.idCard = updateDocumentVersion(state.idCardArchive, {
          [type]: transliteratedValue,
          status: Active,
        });
        state.idCardArchive = { ...initialIdCard };
      } else {
        state.idCard = updateDocumentVersion(state.idCard, { [type]: transliteratedValue });
      }
    },
    setPassportNumberIdCard: (state, action: PayloadAction<string>) => {
      state.idCard = updateDocumentVersion(state.idCard, { number: action.payload });
    },
    clearDataIdCard: (state) => {
      const creationDocumentParam = '0';

      if (state.idCard.id && state.idCard.id !== creationDocumentParam) {
        state.idCardArchive = updateDocumentVersion(state.idCard, { status: Archived });
        state.idCard = {
          ...initialIdCard,
          id: state.idCard.id,
          actualVersion: {
            ...state.idCard.actualVersion,
            employeeDocumentId: state.idCard.actualVersion.employeeDocumentId,
          },
        };
      } else {
        state.idCard = initialIdCard;
      }
    },
    clearDocuments: (state) => {
      state.documents = state.documents.map((doc) =>
        (doc.actualVersion.status === Archived ? doc : updateDocumentVersion(doc, { status: Archived })),
      );
    },
    setTravelPolicy: (state, action: PayloadAction<IEmployeePolicyItem>) => {
      state.generalData.travelPolicyId = action.payload;
    },
    setInitialState: (state) => { Object.assign(state, initialState); },
    setEmployeeData: (state, action: PayloadAction<IEmployeeObj>) => {
      const {
        actualVersion: {
          employeeId,
          firstName,
          lastName,
          sex,
          birthday,
          phoneNumber,
          email,
          countryCode,
          travelPolicyId,
        },
        documents,
        bonusCards,
      } = action.payload;

      state.generalData = {
        ...state.generalData,
        employeeId,
        firstName,
        lastName,
        sex,
        countryCode,
        birthday: dayjsObject(birthday),
        contactData: {
          ...state.generalData.contactData,
          phoneNumber,
          email,

        },
        travelPolicyId: { label: '', value: travelPolicyId },
        bonusCards: bonusCards.length === 0 ? initialState.generalData.bonusCards : bonusCards,
      };

      if (documents) {
        const transformedDocuments = transformArrayDocuments(documents);

        const idCardDocument = transformedDocuments.find(
          ({ actualVersion: { type } }) => type === IdCard || type === DomesticPassport,
        );

        if (idCardDocument) {
          state.idCard = idCardDocument;

        }
        state.documents = transformedDocuments.filter(
          ({ actualVersion: { type } }) => type === ForeignPassport,
        );
      }
    },
  },
});

const transformArrayDocuments = (
  documents: IEmployeeDocument[],
): IEmployeeDocumentModified[] => documents.map((doc) => ({
  ...doc,
  hasBeenChangedData: false,
}));

const updateDocumentVersion = (
  document: IEmployeeDocumentModified,
  changes: Partial<IEmployeeDocumentModified['actualVersion']>,
) => ({
  ...document,
  hasBeenChangedData: true,
  actualVersion: {
    ...document.actualVersion,
    ...changes,
  },
});

export default employeeSlice.reducer;
export const {
  setPassportNumber,
  setPassportNumberIdCard,
  setExpiredDateIdCard,
  setExpiredDateDocument,
  setDataNamePassport,
  setDataIdCard,
  setFioTransliterateIdCard,
  setFioTransliteratePassport,
  setNameGeneral,
  setBirthDay,
  setSex,
  setContactDataGeneral,
  setEmployeeData,
  setInitialState,
  setOrganization,
  setCountry,
  deletePassport,
  addPassport,
  clearDataIdCard,
  clearDocuments,
  setTravelPolicy,
  setLastCountryCode,
} = employeeSlice.actions;
