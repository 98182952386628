import { Dayjs } from 'dayjs';

import { IEmployeeDocumentVersion, IEmployeeVersion } from '@/app/bi/models/employees.ts';
import { IAirlineRoute, ICompany, IEmployee } from '@/app/bi/models/cart.ts';
import { EAirlineClass, EFareServiceStatus, IFlightToken } from '@/app/bi/models/airlineTypes.ts';
import { IAirlineEmployees } from '@/app/bi/models/airline.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { EHotelVersionStatus, IHotelVersion } from '@/app/bi/models/hotelsTypes.ts';

export enum ETripStatus {
  Carted = 'Carted',
  AwaitingBooking = 'AwaitingBooking',
  AwaitingConfirmation = 'AwaitingConfirmation',
  Booked = 'Booked',
  Canceled = 'Canceled',
  Ended = 'Ended',
}

export enum ETripStatusFilter {
  AwaitingBooking = 'AwaitingBooking',
  Booked = 'Booked',
  Canceled = 'Canceled',
  Ended = 'Ended',
}

export enum EOperationDetailType {
  Incoming = 'Incoming',
  Outcoming = 'Outcoming',
  Booking = 'Booking',
  Surcharge = 'Surcharge',
  Penalty = 'Penalty',
  Return = 'Return',
}

export enum EProvider {
  Amadeus = 'Amadeus',
}

export enum EAirlineVersionStatus {
  Carted = 'Carted',
  AwaitingBooking = 'AwaitingBooking',
  Booked = 'Booked',
}

export enum EServicePlatform {
  Workplace = 'Workplace',
  Backoffice = 'Backoffice',
}

export enum ECustomVersionStatus {
  Created = 'Created',
}

export enum ESortedByTrips {
  CreatedDate = 'CreatedDate',
}

export enum EFilterItemType {
  TripCities = 'tripCities',
  TripEmployees = 'tripEmployees',
  TripServices = 'tripServices',
  TripStatus = 'tripStatus',
  TripDates = 'tripDates',
  CreatedOnlyByMe = 'createdOnlyByMe',
}

export interface ITripPreview {
  id: string;
  status: ETripStatus;
  name: string;
  accountId: number;
  changedDate: Dayjs;
  checkinDate: Dayjs;
  checkoutDate: Dayjs;
  amount: number;
  serviceTypes: EServiceTypes[];
  employees: TEmployees[]
}

export type TEmployees = {
  id: string;
  fullName: string;
};

export interface ITrip {
  id: string;
  creatorUserId: string;
  createdDate: Dayjs;
  checkinDate: Dayjs;
  checkoutDate: Dayjs;
  status: ETripStatus;
  name: string | null;
  amount: number;
  currency: ECurrencyCode;
  accountId: number;
  airlineTripItems: IAirlineTripItems[];
  hotelTripItems: IHotelTripItems[];
  customTripItems: ICustomTripItem[]
}

export interface IAirlineTripItems {
  id: string;
  tripId: string;
  actualVersion: IAirlineTripVersion;
  versions: IAirlineTripVersion[];
}

export interface IAirlineInfo {
  code: string;
  name: string;
}

// TODO когда появятся отели заменить на тип приходящих данных
export interface IHotelTripItems {
  id: string;
  tripId: string;
  serviceType: EServiceTypes;
  tripItemStatus: EHotelVersionStatus;
  checkoutDate: Dayjs;
  checkinDate: Dayjs;
  actualVersion: IHotelVersion;
  versions: IHotelVersion[];
  isCarted: boolean;
}

export interface IAirlineTripVersion {
  id: string;
  airlineEmployees: IAirlineEmployees[];
  airlineTripItemId: string;
  employeeVersion: IEmployeeVersion;
  employeeDocumentVersion: IEmployeeDocumentVersion;
  company: ICompany;
  operationDetails: IOperationDetails;
  provider: EProvider
  createdDate: Dayjs;
  departureDate: Dayjs;
  arrivalDate: Dayjs;
  status: EAirlineVersionStatus;
  routes: IAirlineRoute[];
  creatorUserId: string;
  servicePlatform: EServicePlatform;
  description: string | null;
  class: EAirlineClass;
  officeId: string | null;
  ticketNumber: string;
  ticketPnr: string | null;
  ticketPnrLocator: string | null;
  ticketVoidTimeLimit: string | null;
  ticketVoidUntilEndOfDay: string | null;
  ticketReserveExpires: Dayjs | null;
  fareId: string | null;
  fareFlightToken: IFlightToken;
  fareName: string | null;
  farePrice: number;
  fareAirlineBonusInformation: string | null;
  isCarryonIncluded: EFareServiceStatus;
  carryonPlaces: string | null;
  isBaggageIncluded: EFareServiceStatus;
  baggagePlaces: string | null;
  canBuyBaggage: EFareServiceStatus;
  isTicketRefundable: EFareServiceStatus;
  isTicketRefundableAfterDeparture: EFareServiceStatus;
  isTicketChangeable: EFareServiceStatus;
  isTicketChangeableAfterDeparture: EFareServiceStatus;
  canRegistrationSeat: EFareServiceStatus;
  isBusinessHallIncluded: EFareServiceStatus;
  haveInternetAccess: EFareServiceStatus;
  isPriorityRegistration: EFareServiceStatus;
  isPriorityBaggageReception: EFareServiceStatus;
  canUpgradeRate: EFareServiceStatus;
  cartPrice: number;
  currency: ECurrencyCode;
  totalPrice: number;
}

export interface IOperationDetails {
  id: string;
  type: EOperationDetailType;
  operationId: string;
  name: string | null;
  base: number;
  commission: number;
  currency: ECurrencyCode;
}

export interface ICustomTripItem {
  id: string;
  tripId: string;
  actualVersion: IVersionCustomTrip;
  versions: IVersionCustomTrip;
}

export interface IVersionCustomTrip {
  id: string;
  customTripItemId: string;
  employeeVersions: IEmployeeVersion[] | null;
  name: string | null;
  creatorUserId: string;
  servicePlatform: EServicePlatform;
  createdDate: Dayjs;
  providerName: string | null;
  status: ECustomVersionStatus;
  companyId: number;
  type: '';
  reservationNumber: string | null;
  reservationDate: Dayjs;
  checkinDate: Dayjs;
  checkoutDate: Dayjs;
  priceFee: number;
  priceTax: number;
  priceTaxes: number;
  priceCommission: number;
}

export interface ITripByStatusesRequest {
  statuses: ETripStatus[];
}

export interface ITripLite {
  id: string;
  creatorUserId: string;
  createdDate: string;
  status: ETripStatus;
  name: string | null;
  accountId: number;
}

export interface ISharedTripsFilter {
  serviceTypes: EServiceTypes[];
  tripStatuses: ETripStatusFilter[];
  cities: string[];
  createdOnlyByMe: boolean;
  dateInTripPeriod: Dayjs;
  bookingDate: Dayjs;
  tripDates: ITripDates | null;
  page: number;
  sort: {
    sortedBy: ESortedByTrips;
    descending: boolean;
  };
}

export interface ITripsFilterRequest extends ISharedTripsFilter {
  employees: string[];
}

export interface ITripsFilter extends ISharedTripsFilter {
  employees: IEmployee[];
}

export interface ITripDates {
  max: Dayjs;
  min: Dayjs;
}

export interface ITripFilterResponse {
  items: ITripPreview[];
  totalAmount: number;
  filterRequest: ITripsFilterRequest;
  page: number;
  pages: number;
  pageLimit: number;
  itemsCount: number;
}

export interface ITripFilterDefault {
  employees: IEmployeeFilter[];
  tripFilter: ITripsFilterRequest;
}

export interface IEmployeeFilter {
  id: string;
  fullName: string;
}
