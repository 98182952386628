import React, { useCallback, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Datepicker, Input, LinkButton, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { useAppDispatch } from '@/app/store/hooks/redux.ts';
import {
  setDataIdCard,
  setDataNamePassport,
  setExpiredDateDocument,
  setExpiredDateIdCard,
  setFioTransliterateIdCard,
  setFioTransliteratePassport,
  setPassportNumber,
  setPassportNumberIdCard,
} from '@/app/store/redusers/EmployeeSlice.ts';

import { FieldLabel } from '@/app/components/FieldLabel';
import { FormWrapper } from '@/app/components/FormWrapper';
import { BlockFormFio } from '@/app/pages/Employee/components/FormContainer/Components/BlockFormFio';

import { removeSpaces } from '@/app/bi/utils/text.ts';
import { getPlaceholderPassport, isValidDocumentNumber } from '@/app/bi/utils/employee.ts';
import { dayjsObject, getKeepLocalDate } from '@/app/bi/utils/formatDate.ts';

import {
  DATEPICKER_BIRTH_DAY_PLACEHOLDER,
  EEmployeeNameParam,
  EEmployeeNameTransliterate,
  MAX_COUNT_ID_CARD_LENGTH,
  MAX_COUNT_PASSPORT_LENGTH,
  MAX_DATE,
} from '@/app/bi/constants/settings.ts';
import COUNTRIES from '@/app/bi/constants/countries.ts';

import { EEmployeeDocumentType, IEmployeeDocument } from '@/app/bi/models/employees.ts';

import styles from './styles/index.module.css';

const LABELS = {
  PASSPORT_NUMBER: getText('settings:numberPassport'),
  ID_CARD_NUMBER: getText('settings:numberIdCard'),
  FORM_ERROR: getText('settings:validationError'),
  INCOMPLETE: getText('settings:incorrectPassport'),
  EXPIRY_DATE: getText('settings:validityPeriod'),
  EDIT_TRANSLIT_TITLE: getText('settings:translit.title'),
  CHECK_TRANLITIRATION: getText('settings:translit.checkTransliteration'),
  SHOW_EDIT_TRANSLIT: getText('settings:translit.show'),
  HIDE_EDIT_TRANSLIT: getText('settings:translit.edit'),
};

interface IDocumentProps {
  data: IEmployeeDocument;
  index: number;
  type: EEmployeeDocumentType;
  countryCode: string;
}

const Document = ({
  data,
  index,
  type,
  countryCode,
}: IDocumentProps) => {
  const dispatch = useAppDispatch();

  const {
    actualVersion: {
      firstName,
      lastName,
      firstNameTranslit,
      lastNameTranslit,
      number,
      expiryDate,
    },
  } = data;
  const isNotTurkeyCountry = countryCode !== COUNTRIES.TR.CODE3;
  const formattedExpiryDate = expiryDate ? getKeepLocalDate(dayjsObject(expiryDate)) : null;
  const label = type === EEmployeeDocumentType.ForeignPassport ? LABELS.PASSPORT_NUMBER : LABELS.ID_CARD_NUMBER;
  const passportPlaceholder = getPlaceholderPassport(type, isNotTurkeyCountry);

  const [
    errors,
    setErrors,
  ] = useState<{ number: string; expiryDate: string }>({
    number: '',
    expiryDate: '',
  });
  const [showForm, setShowForm] = useState(false);

  const formationDocumentType =
    type === EEmployeeDocumentType.ForeignPassport ? EEmployeeDocumentType.ForeignPassport : undefined;

  const classnameInputExpiredDate = errors.expiryDate.length ? styles.datepicker_error : styles.datepicker;

  const validateNumberAndSetError = useCallback((value: string) => {
    const isDocumentValid = isValidDocumentNumber(value, type);
    const requiredLength = type === EEmployeeDocumentType.IdCard
      ? MAX_COUNT_ID_CARD_LENGTH
      : MAX_COUNT_PASSPORT_LENGTH;

    const errorMessage = isDocumentValid && value.length === requiredLength ? '' : LABELS.FORM_ERROR;

    setErrors(prevErrors => ({
      ...prevErrors,
      number: errorMessage,
    }));
  }, [type]);

  const updateDocumentNumber = useCallback((value: string) => {
    if (type === EEmployeeDocumentType.ForeignPassport) {
      dispatch(setPassportNumber({ id: index, value }));
    } else {
      dispatch(setPassportNumberIdCard(value));
    }

    if (!isNotTurkeyCountry) {
      validateNumberAndSetError(value);
    }
  }, [type, index, dispatch, validateNumberAndSetError]);

  const updateExpiryDate = useCallback((value: Dayjs) => {
    if (type === EEmployeeDocumentType.ForeignPassport) {
      const formattedDocumentId = data.id ? data.id : index;

      dispatch(setExpiredDateDocument({ id: formattedDocumentId, value }));
    } else {
      dispatch(setExpiredDateIdCard(value));
    }
  }, [dispatch, type, data.id, index]);

  const handleNumberChange = useCallback((inputValue: string) => {
    let cleanedInput = removeSpaces(inputValue);

    if (cleanedInput.length) {
      cleanedInput = cleanedInput.charAt(0).toUpperCase() + cleanedInput.slice(1);
    }

    if (type !== EEmployeeDocumentType.IdCard && isNotTurkeyCountry) {
      updateDocumentNumber(cleanedInput);
    } else if (isValidDocumentNumber(cleanedInput, type)) {
      updateDocumentNumber(cleanedInput);
    } else if (cleanedInput.length === 0) {
      updateDocumentNumber('');
    }
  }, [type, isNotTurkeyCountry, updateDocumentNumber]);

  const handleDateChange = useCallback((value: Dayjs) => {
    let errorMessage = '';

    if (!value) { errorMessage = LABELS.INCOMPLETE; }

    setErrors(prevErrors => ({
      ...prevErrors,
      expiryDate: errorMessage,
    }));

    updateExpiryDate(value);
  }, [updateExpiryDate]);

  const setDataName = (value: string, documentType: EEmployeeNameParam) => {
    if (type === EEmployeeDocumentType.ForeignPassport) {
      dispatch(setDataNamePassport({ index, value, type: documentType }));
    } else {
      dispatch(setDataIdCard({ value, type: documentType }));
    }
  };

  const setDataNameTranslit = (value: string, documentType: EEmployeeNameTransliterate) => {
    if (type === EEmployeeDocumentType.ForeignPassport) {
      dispatch(setFioTransliteratePassport({ index, value, type: documentType }));
    } else {
      dispatch(setFioTransliterateIdCard({ value, type: documentType }));
    }
  };

  const handleChangeFirstName = (value: string) => {
    setDataName(value, EEmployeeNameParam.First);
  };

  const handleChangeLastName = (value: string) => {
    setDataName(value, EEmployeeNameParam.Last);
  };

  const handleFirstNameTranslit = (value: string) => {
    setDataNameTranslit(value, EEmployeeNameTransliterate.FirstNameTranslit);
  };

  const handleLastNameTranslit = (value: string) => {
    setDataNameTranslit(value, EEmployeeNameTransliterate.LastNameTranslit);
  };

  const showTransliterated = firstName.length || lastName.length;

  const handleShowTransliteration = () => setShowForm(!showForm);

  const renderTransliteration = () => {
    if (!showTransliterated) return null;

    const editTitle = showForm ? LABELS.HIDE_EDIT_TRANSLIT : LABELS.SHOW_EDIT_TRANSLIT;

    return (
      <>
        <div className={ styles.container_transliteration }>
          <div className={ styles.title }>
            <Text type='NORMAL_14'>
              {LABELS.EDIT_TRANSLIT_TITLE}
            </Text>
            <Text type='bold_14'>
              {lastNameTranslit} {firstNameTranslit}
            </Text>
          </div>
          <Text type='NORMAL_14'>
            {LABELS.CHECK_TRANLITIRATION}
          </Text>
          <div>
            <LinkButton tabIndex={ -1 } color='light-blue-4' onClick={ handleShowTransliteration }>
              { editTitle }
            </LinkButton>
          </div>
        </div>
        {showForm && (
          <BlockFormFio
            firstName={ firstNameTranslit }
            lastName={ lastNameTranslit }
            documentType={ formationDocumentType }
            isTransliterate
            onChangeFirstName={ handleFirstNameTranslit }
            onChangeLastName={ handleLastNameTranslit }
          />
        )}
      </>
    );
  };

  return (
    <div className={ styles.container }>
      <BlockFormFio
        firstName={ firstName }
        lastName={ lastName }
        onChangeFirstName={ handleChangeFirstName }
        onChangeLastName={ handleChangeLastName }
        documentType={ formationDocumentType }
      />
      {renderTransliteration()}
      <FormWrapper className={ styles.row }>
        <div className={ styles.item }>
          <FieldLabel text={ label } isRequired />
          <Input
            value={ number }
            placeholder={ passportPlaceholder }
            onChange={ handleNumberChange }
            error={ errors.number }
          />
        </div>
        <div className={ styles.item }>
          <FieldLabel text={ LABELS.EXPIRY_DATE } isRequired />
          <Datepicker
            closeOnTabOut
            min={ MAX_DATE }
            value={ formattedExpiryDate }
            inputClassName={ classnameInputExpiredDate }
            placeholder={ DATEPICKER_BIRTH_DAY_PLACEHOLDER }
            onChange={ handleDateChange }
            error={ errors.expiryDate }
          />
        </div>
      </FormWrapper>
    </div>
  );
};

export { Document };
