import React, { useState } from 'react';
import { CircleLoading, IconButton, Select, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { ICartResponseCompany } from '@/app/bi/models/cart.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import styles from './index.module.css';

const LABELS = {
  ORGANIZATION: getText('components:selectCompany.organization'),
  SELECT_COMPANY: getText('components:selectCompany.selectCompany'),
};

interface SelectCompanyProps {
  value: ICartResponseCompany | null;
  serviceType: EServiceTypes;
  companies: { label: string, value: number }[];
  onChange(value: number | null): void;
}

const SelectCompany = ({
  value,
  companies,
  serviceType,
  onChange,
}: SelectCompanyProps) => {
  const [loading, setLoading] = useState(false);

  const handleChange = async (newValue: number) => {
    setLoading(true);

    try {
      await onChange(newValue);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCompany = async () => {
    setLoading(true);

    await onChange(null);

    setLoading(false);
  };

  const deleteIcon = (
    <IconButton
      onClick={ handleRemoveCompany }
      iconType='closeOn'
      size={ 16 }
      iconColor='gray5'
      className={ styles.remove }
    />
  );

  const renderDeleteIcon = () => {
    if (companies.length > 1) {
      return loading
        ? <CircleLoading size={ 20 } className={ styles.loading } />
        : deleteIcon;
    }

    return null;
  };

  const renderCompanyInfo = () => {
    const currentCompany = companies.find((
      item,
    ) => item.value === value?.id);

    return (
      <div className={ styles['company-name'] }>
        <Text>{currentCompany?.label}</Text>
        {renderDeleteIcon()}
      </div>
    );
  };

  const renderSelectCompanies = () => (
    <Select
      excludeValue
      placeholder={ LABELS.SELECT_COMPANY }
      value={ value?.id }
      theme='default-border'
      items={ companies }
      onChange={ handleChange }
      className={ styles.select }
      loading={ loading }
    />
  );

  const renderContent = () => {
    if (serviceType === EServiceTypes.Hotel) {
      if (companies.length > 1) return renderSelectCompanies();

      return renderCompanyInfo();
    }

    if (value) { return renderCompanyInfo(); }

    return renderSelectCompanies();
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.row }>
        <Text type='NORMAL_14' fontWeight={ 600 }>
          {LABELS.ORGANIZATION}
        </Text>
        {renderContent()}
      </div>
    </div>
  );
};
export { SelectCompany };
