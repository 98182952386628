import { IMappedSegments } from '@/app/bi/utils/airline.ts';
import { dateWithoutDayjs } from '@/app/bi/utils/formatDate.ts';

import { DATE_FORMATS, PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { ITerminals } from '@/app/bi/models/airline.ts';
import { IRouteSegment } from '@/app/bi/models/common';

const infoTerminal = {
  terminal: '',
  different: false,
};

export const anotherSegment = <T extends IRouteSegment>(segments: T[], prevOrNext: number) => segments[prevOrNext];

export const nextSegmentId = (index: number) => index + 1;

export const prevSegmentId = (index: number) => index - 1;

export const isArrivalChanged = <T extends IRouteSegment>(
  index: number,
  segment: T,
  segments: T[],
) => {
  if (nextSegmentId(index) < segments.length) {
    const arrival = segment.arrivalAirportCode;
    const departure = segments[nextSegmentId(index)].departureAirportCode;

    return arrival !== departure;
  }

  return false;
};

export const isDepartureChanged = <T extends IRouteSegment>(
  index: number,
  segment: T,
  segments: T[],
) => {
  if (prevSegmentId(index) >= 0) {
    const arrival = segments[prevSegmentId(index)].arrivalAirportCode;
    const departure = segment.departureAirportCode;

    return arrival !== departure;
  }

  return false;
};

export const getTerminals = <T extends IRouteSegment>(
  item: T,
  index: number,
  segments: T[],
): ITerminals => {
  const arrival = infoTerminal;
  const departure = infoTerminal;
  const { arrivalTerminal, departureTerminal } = item;

  arrival.terminal = arrivalTerminal || '';
  departure.terminal = departureTerminal || '';

  if (segments.length > 1) {
    const nextSegment = anotherSegment(segments, nextSegmentId(index));
    const prevSegment = anotherSegment(segments, prevSegmentId(index));

    arrival.different = nextSegmentId(index) < segments.length
      && !!arrivalTerminal
      && !!nextSegment.departureTerminal
      && arrivalTerminal !== nextSegment.departureTerminal
      && !isArrivalChanged(index, item, segments);

    departure.different = prevSegmentId(index) >= 0
      && !!departureTerminal
      && !!prevSegment.arrivalTerminal
      && departureTerminal !== prevSegment.arrivalTerminal
      && !isDepartureChanged(index, item, segments);
  }

  return {
    arrival,
    departure,
  };
};

export const segmentsArrivalCity = <T extends IRouteSegment>(segments: T[]) =>
  segments.reduce<string[]>((acc, { arrivalAirportCode, arrivalCity }, index) => {
    if (index !== segments.length - 1
      && arrivalAirportCode !== segments[index + 1].departureAirportCode
      && arrivalCity === segments[index + 1].departureCity
    ) {
      return [...acc, arrivalCity];
    }

    return acc;
  }, []);

export const getMappedSegments = <T extends IRouteSegment>(segment: T): IMappedSegments => ({
  airlineId: segment.marketingAirlineCode,
  departureAirportId: segment.departureAirportCode,
  arrivalAirportId: segment.arrivalAirportCode,
  departureTime: dateWithoutDayjs(segment.departureDate, DATE_FORMATS.TIME),
  arrivalTime: dateWithoutDayjs(segment.arrivalDate, DATE_FORMATS.TIME),
  departureDate: dateWithoutDayjs(segment.departureDate, PATTERN.DAY_OF_MONTH),
  arrivalDate: dateWithoutDayjs(segment.arrivalDate, PATTERN.DAY_OF_MONTH),
  departureWeek: dateWithoutDayjs(segment.departureDate, PATTERN.WEEK),
  arrivalWeek: dateWithoutDayjs(segment.arrivalDate, PATTERN.WEEK),
});
