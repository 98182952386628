import React from 'react';
import { LinkButton } from 'yw-ui';

import { getText } from '@/i18n';

import { sideSlice } from '@/app/store/redusers/SideSlice';
import { useAppDispatch } from '@/app/store/hooks/redux';

import { EmployeeInfoList } from '@/app/components/EmployeeInfoList';
import CardAirLineBody from './ui/CardAirLineBody';
import SidePanel from './ui/SidePanel';

import { preparedFares } from '@/app/bi/utils/fares.ts';
import { transformEmployeesUniversal } from '@/app/bi/utils/trip.ts';

import { IAirlineTripItems } from '@/app/bi/models/tripsTypes.ts';
import { IAirlineEmployees } from '@/app/bi/models/airline.ts';

import styles from './index.module.css';

interface CardAirLineProps {
  data: IAirlineTripItems;
}

const LABELS = {
  MORE_AIR_INFORMATION: getText('common:moreInformationAboutFlight'),
};

const CardAirLine = ({ data }: CardAirLineProps) => {
  const tripActualVersion = data.actualVersion;

  // TODO переделеать это
  const labelValueArray = preparedFares({
    isBaggageIncluded: tripActualVersion.isBaggageIncluded,
    baggagePlaces: tripActualVersion.baggagePlaces,
    isCarryonIncluded: tripActualVersion.isCarryonIncluded,
    carryonPlaces: tripActualVersion.carryonPlaces,
    isTicketRefundable: tripActualVersion.isTicketRefundable,
    isTicketChangeable: tripActualVersion.isTicketChangeable,
    canRegistrationSeat: tripActualVersion.canRegistrationSeat,
    isBusinessHallIncluded: tripActualVersion.isBusinessHallIncluded,
    isTicketRefundableAfterDeparture: tripActualVersion.isTicketRefundableAfterDeparture,
    canUpgradeRate: tripActualVersion.canUpgradeRate,
    airlineBonusInformation: tripActualVersion.fareAirlineBonusInformation,
    isTicketChangeableAfterDeparture: tripActualVersion.isTicketChangeableAfterDeparture,
    // @ts-ignore
    baggageIncludedWithCondition: tripActualVersion.isBaggageIncluded,
    // @ts-ignore
    refundTicketWithCondition: tripActualVersion.isTicketRefundableAfterDeparture,
    // @ts-ignore
    changeTicketWithCondition: tripActualVersion.isTicketChangeableAfterDeparture,
  });

  const dispatch = useAppDispatch();
  const { setShow, setRenderFn } = sideSlice.actions;

  const handleShowDetails = () => {
    dispatch(setShow(true));
    dispatch(setRenderFn(renderSidePanel));
  };

  const renderSidePanel = () => (
    <SidePanel
      routes={ tripActualVersion.routes }
      rulesList={ labelValueArray }
      airlineClass={ tripActualVersion.class }
    />
  );

  const renderEmployees = (airlineEmployees: IAirlineEmployees[]) => {
    if (!airlineEmployees.length) return null;

    const arrayEmployee = transformEmployeesUniversal(data.actualVersion.airlineEmployees);

    return <EmployeeInfoList showHeader={ false } employees={ arrayEmployee }/>;
  };

  return <div className={ styles.airline_card }>
    <div className={ styles.airline_card_body }>
      <div className={ styles.airline_card }>
        {data.actualVersion.routes.map((route, idx) =>
          <CardAirLineBody
            route={ route }
            isPriceRequired={ idx === 0 }
            currency={ tripActualVersion.currency }
            price={ tripActualVersion.totalPrice }
            key={ route.id }
            rulesList={ labelValueArray }
          />,
        )}
      </div>
      <LinkButton color='light-blue-4' type='NORMAL_14' onClick={ handleShowDetails }>{LABELS.MORE_AIR_INFORMATION}</LinkButton>
    </div>
    {renderEmployees(tripActualVersion.airlineEmployees)}
  </div>;
};

export { CardAirLine };
