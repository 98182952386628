import React from 'react';
import { Text } from 'yw-ui';

import { getText } from '@/i18n';
import { AirItem } from '@/app/components/AirItem';

import { preparedDetailsByAirlineVersion } from '@/app/bi/utils/fares.ts';

import { ICartResponseAirlineItem } from '@/app/bi/models/cart.ts';

import styles from './index.module.css';

const LABELS = {
  FLIGHT_DETAILS: getText('components:airlineSidePanel.title'),
  RATE: getText('components:airlineSidePanel.rate'),
};

interface AirlineSidePanelProps {
  item: ICartResponseAirlineItem,
}

const AirlineSidePanel = ({ item }: AirlineSidePanelProps) => {
  const renderAirItem = () => item.routes.map((route) => (
    <AirItem
      key={ `airline_route_${route.id}` }
      route={ route }
      duration={ route.duration }
    />
  ));

  const renderDetails = () => {
    const detailsList = preparedDetailsByAirlineVersion(item);

    return (
      <div className={ styles.details_list } >
        <Text type='NORMAL_14' fontWeight={ 600 } line-height={ '105%' }>
          {LABELS.RATE}&nbsp;
        </Text>
        <div className={ styles.details_content } >
          <div className={ styles.column }>
            {detailsList.map((detail, index) => (
              <Text key={ index } className={ styles.row } type='NORMAL_14'>
                {detail}</Text>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={ styles.details_wrapper }>
      <div className={ styles.header }>
        <Text type='bold_24'>{LABELS.FLIGHT_DETAILS}</Text>
      </div>
      <div className={ styles.content } >
        {renderAirItem()}
      </div>
      {renderDetails()}
    </div>
  );
};

export { AirlineSidePanel };
