import React from 'react';

import { getText } from '@/i18n';

import { clearDataIdCard, initialIdCard } from '@/app/store/redusers/EmployeeSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';

import { Document } from '@/app/pages/Employee/components/Document';
import { FormContainer } from '@/app/pages/Employee/components/FormContainer';

import COUNTRIES from '@/app/bi/constants/countries.ts';

import { EEmployeeDocumentType } from '@/app/bi/models/employees.ts';

const LABELS = {
  ID_CARD: getText('settings:idCard'),
  PASSPORT_FOREIGN_CITIZEN: getText('settings:passportForeignCitizen'),
};

export const IdCard = () => {
  const dispatch = useAppDispatch();

  const {
    idCard,
    idCardArchive,
    generalData: {
      citizenship: {
        code3,
      },
    },
  } = useAppSelector((state) => state.employeeSlice);
  const isTurkeyCountry = code3 === COUNTRIES.TR.CODE3;
  const documentType = isTurkeyCountry ? EEmployeeDocumentType.IdCard : EEmployeeDocumentType.DomesticPassport;
  const documentName = isTurkeyCountry ? LABELS.ID_CARD : LABELS.PASSPORT_FOREIGN_CITIZEN;

  const prepareData = () => {
    const isArchived = idCard.id.length ? idCard.id === idCardArchive.id : false;

    if (isArchived) {
      if (isTurkeyCountry) {
        return initialIdCard;
      }

      return {
        ...initialIdCard,
        actualVersion: {
          ...initialIdCard.actualVersion,
          type: EEmployeeDocumentType.DomesticPassport,
        },
      };
    }

    return idCard;
  };

  const data = prepareData();

  const handleClearIdCard = () => dispatch(clearDataIdCard());

  return (
    <FormContainer
      subTitle={ documentName }
      isDeleteButton
      handleDeletePassword={ handleClearIdCard }
    >
      <Document
        index={ 0 }
        data={ data }
        type={ documentType }
        countryCode={ code3 }
      />
    </FormContainer>
  );
};
