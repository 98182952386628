import React from 'react';
import { Text, Tooltip } from 'yw-ui';

import { getText } from '@/i18n';

import { ListPolicyErrors } from './ListPolicyErrors';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { EAirlineValidationError } from '@/app/bi/models/airlineTypes.ts';
import { EHotelValidationError } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import {
  ICartResponseAirlineItemTravelPolicyError,
  ICartResponseHotelItemTravelPolicyError,
} from '@/app/bi/models/cart.ts';

import styles from './index.module.css';

const LABELS = {
  HEADING_TOOLTIP_CONTENT: (
    policyName: string,
  ) => getText('components:travelPolicies.headingTooltipContent', { policyName }),
  HEADING_TOOLTIP: (
    policyName: string,
  ) => getText('components:travelPolicies.headingTooltip', { policyName }),
  SAVE: getText('common:save'),
};

interface IPolicyTooltipProps {
  serviceType: EServiceTypes;
  namesErrorPolicies: string;
  travelPoliciesErrors: ICartResponseHotelItemTravelPolicyError[] | ICartResponseAirlineItemTravelPolicyError[]
  listTravelPolicies: ITravelPolicy[];
}

export const PolicyTooltip = ({
  serviceType,
  namesErrorPolicies,
  travelPoliciesErrors,
  listTravelPolicies = [],
}: IPolicyTooltipProps) => {
  const renderTooltipContent = (
    typeContent: EServiceTypes,
    policyErrors: EAirlineValidationError[] | EHotelValidationError[],
  ) => (
    <div className={ styles.tooltip_content }>
      <Text color='white' type='NORMAL_16'>
        {LABELS.HEADING_TOOLTIP_CONTENT(namesErrorPolicies)}
      </Text>
      <ul className={ styles.list_policy_text }>
        <ListPolicyErrors
          typeContent={ typeContent }
          policyErrors={ policyErrors }
          listTravelPolicies={ listTravelPolicies }
        />
      </ul>
    </div>
  );

  const renderPolicyTooltip = (
    type: EServiceTypes,
    policyErrors: EAirlineValidationError[] | EHotelValidationError[],
    index: number,
  ) => (
    <Tooltip
      key={ `${type}_${index}` }
      show
      position='bottom'
      renderContent={ () => renderTooltipContent(type, policyErrors) }
    >
      <Text type='NORMAL_14' className={ styles.name_policy }>
        {LABELS.HEADING_TOOLTIP(namesErrorPolicies)}
      </Text>
    </Tooltip>
  );

  const renderContent = () => travelPoliciesErrors.map((
    error,
    index,
  ) =>
    renderPolicyTooltip(serviceType, error.validationErrors, index),
  );

  return renderContent();
};
