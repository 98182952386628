import React from 'react';
import { Icon, Text, Tooltip } from 'yw-ui';

import { getText } from '@/i18n';

import { TechnicalStopText } from '@/app/components/AirlineTechnicalStop/components/Text';

import { getImageLogo, IMappedSegments } from '@/app/bi/utils/airline.ts';
import { secondsToLabel } from '@/app/bi/utils/time.ts';

import { IInfoTerminal } from '@/app/bi/models/airline.ts';
import { IRouteSegment } from '@/app/bi/models/common';

import styles from './index.module.css';

const LABELS = {
  ATTENTION: getText('components:airlineItemSegment.attention'),
  INFO_AIRPORT: getText('components:airlineItemSegment.infoAirport'),
  INFO_TERMINAL: getText('components:airlineItemSegment.infoTerminal'),
  TRANSFER: getText('components:airlineItemSegment.transfer'),
  TERMINAL: getText('components:airlineItemSegment.terminal'),
  AIRLINE: getText('components:airlineItemSegment.airline'),
};

const renderTooltip = (info: string, city: string) => (
  <Text
    color='white'
    type='NORMAL_14_130'
    className={ styles.tooltip_box }
  >
    <div>{ LABELS.ATTENTION }</div>
    <div>
      {`${info} ${city}`}
    </div>
  </Text>
);

const renderCity = (city: string, id: string | null, changed: boolean) => {
  const color = changed ? 'red' : 'default';

  return (
    <Tooltip show={ changed } renderContent={ () => renderTooltip(LABELS.INFO_AIRPORT, city) }>
      <Text type='SEMIBOLD_16' color={ color }>
        {`${city}, ${id}`}
      </Text>
    </Tooltip>
  );
};

const renderTerminal = (terminal: string, changed: boolean, city: string) => {
  if (!terminal || terminal.trim().length === 0) return null;

  const color = changed ? 'red' : 'default';

  return (
    <Tooltip
      show={ changed }
      renderContent={ () => renderTooltip(LABELS.INFO_TERMINAL, city) }
      className={ styles.airport_name }
    >
      <Text type='NORMAL_14' color={ color }>
        { LABELS.TERMINAL }
        {' '}
        { terminal }
      </Text>
    </Tooltip>
  );
};

interface AirItemSegmentProps <T extends IRouteSegment> {
  fromTrip?: boolean,
  segment: T,
  arrivalChanged?: boolean,
  departureChanged?: boolean,
  arrivalTerminal: IInfoTerminal,
  departureTerminal: IInfoTerminal,
  opts: IMappedSegments,
  transferPlace: 'top' | 'bottom'
}

const AirItemSegment = <T extends IRouteSegment>({
  segment,
  opts,
  arrivalTerminal,
  departureTerminal,
  fromTrip = false,
  arrivalChanged = false,
  departureChanged = false,
  transferPlace,
}: AirItemSegmentProps<T>) => {
  const {
    transferDuration,
    arrivalCity,
    departureCity,
    operatingAirlineCode,
    operatingAirlineName,
    departureAirportName,
    marketingAirlineName,
    marketingAirlineCode,
    arrivalAirportName,
    flightNumber,
    aircraftName,
    technicalStop,
  } = segment;

  const wrappedStyles = [styles.wrapped];
  const {
    airlineId,
    departureAirportId,
    arrivalAirportId,
    departureTime,
    arrivalTime,
    departureDate,
    arrivalDate,
    departureWeek,
    arrivalWeek,
  } = opts;

  if (fromTrip) {
    wrappedStyles.push(styles.not_count);
  }

  const arrivalCityHtml = renderCity(arrivalCity, arrivalAirportId, arrivalChanged);
  const departureCityHtml = renderCity(departureCity, departureAirportId, departureChanged);

  const technicalStopStyles = `${styles.row} ${styles.technical_stop}`;

  const arrivalTerminalHtml = renderTerminal(arrivalTerminal.terminal, arrivalTerminal.different, arrivalCity);
  const departureTerminalHtml = renderTerminal(departureTerminal.terminal, departureTerminal.different, departureCity);

  const changeDurationHtml = transferDuration > 0 ? (
    <div className={ styles.change }>
      <Text
        type='NORMAL_14'
        color='gray-6'
        className={ styles.title }
      >
        { LABELS.TRANSFER }
      </Text>
      <Text
        type='NORMAL_14'
        color='gray-6'
        className={ styles.duration }
      >
        { secondsToLabel(transferDuration, true) }
      </Text>
    </div>
  ) : null;

  const differentOperatingAirline = marketingAirlineCode !== operatingAirlineCode && (
    <div className={ styles.operating_airline }>
      <img
        className={ styles.logo }
        src={ getImageLogo(operatingAirlineCode) }
        title={ operatingAirlineName }
        alt={ operatingAirlineName }
      />
      <Text
        type='NORMAL_14'
        color='gray'
      >
        { LABELS.AIRLINE }
        { operatingAirlineName }
      </Text>
    </div>
  );

  return (
    <div className={ wrappedStyles.join(' ') }>
      { transferPlace === 'top' && changeDurationHtml }
      <div className={ styles.row }>
        <div className={ styles.logo }>
          <img
            className={ styles.img }
            src={ getImageLogo(airlineId) }
            title={ marketingAirlineName }
            alt={ marketingAirlineName }
          />
        </div>
        <div className={ styles.item }>
          <div className={ styles.col_departure }>
            <Text>
              {`${departureDate}, ${departureWeek}`}
            </Text>
            <Text type='bold_22' className={ styles.time }>{departureTime}</Text>
            {departureCityHtml}
            <Text
              type='NORMAL_14'
              className={ styles.airport_name }
            >
              {departureAirportName}
            </Text>
            {departureTerminalHtml}
          </div>
          <div className={ styles.col_airline }>
            <Text
              type='NORMAL_14'
              color='gray'
              className={ styles.airline_name }
            >
              <span>
                {marketingAirlineName}
              </span>&ensp;
              <span>({airlineId}{' '}{flightNumber})
              </span>
            </Text>
            <Text
              type='NORMAL_14'
              color='gray'
            >
              {aircraftName}
            </Text>
            <Icon type='serviceAir' circleColor='gray1' className={ styles.icon }/>
          </div>
          <div className={ styles.col_arrival }>
            <Text>
              {`${arrivalDate}, ${arrivalWeek}`}
            </Text>
            <Text type='bold_22' className={ styles.time }>{arrivalTime}</Text>
            {arrivalCityHtml}
            <Text
              type='NORMAL_14'
              className={ styles.airport_name }
            >
              {arrivalAirportName}
            </Text>
            {arrivalTerminalHtml}
          </div>
        </div>
      </div>
      {!!technicalStop?.length && (
        <TechnicalStopText
          // @ts-ignore
          technicalStop={ technicalStop }
          className={ technicalStopStyles }
        />
      )}
      { transferPlace === 'bottom' && changeDurationHtml }
      { differentOperatingAirline }
    </div>
  );
};

export { AirItemSegment };
