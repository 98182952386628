import React, { useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { Input } from 'yw-ui';

import { FieldLabel } from '@/app/components/FieldLabel';

import { BONUS_CARDS, NUMBER } from '../../constant';
import { SchemaType } from '../../schema';

const createLabels = (t: TFunction) => ({
  NUMBER: {
    PLACEHOLDER: t('settings:bonusCard.number.placeholder'),
    LABEL: t('settings:bonusCard.number.label'),
  },
});

interface InputFieldProps {
  index: number
}

const InputField = ({ index }: InputFieldProps) => {
  const { control } = useFormContext<SchemaType>();
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const { field: { onChange, value } } = useController({ control, name: `${BONUS_CARDS}.${index}.${NUMBER}` });

  return (
    <div>
      <FieldLabel text={ LABELS.NUMBER.LABEL }/>
      <Input
        withLabel={ false }
        value={ value }
        onChange={ onChange }
        placeholder={ LABELS.NUMBER.PLACEHOLDER }
      />
    </div>);
};

export default InputField;
