import React, { useEffect, useState } from 'react';
import { PageLoader, Paginate, Price, Text } from 'yw-ui';
import { useLocation } from 'react-router-dom';

import { getText } from '@/i18n';

import {
  useCitiesAutocompleteQuery,
  useEmployeesAutocompleteQuery,
  useGetDefaultTripsFilterQuery,
  useGetTripsQuery,
} from '@/app/bi/api/tripApi.ts';
import { useDebounce } from '@/app/hooks/useDebounce.ts';
import { tripsSlice } from '@/app/store/redusers/TripsSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';

import { FilterStatus } from '@/app/pages/Trips/components/FilterStatus';
import { EmptyDataPanel } from '@/app/components/EmptyDataPanel';
import { TripsFilters } from './components/TripsFilters';
import { ListTrips } from './components/ListTrips';

import {
  updateFilterStatus,
  deleteFilterStatus,
  isEmptyTripsScreen,
  deleteFilterValues,
} from '@/app/bi/utils/trip.ts';
import { scrollToTop } from '@/app/bi/utils/scrollToTop.ts';

import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy.ts';
import { DEFAULT_FILTERS_TRIPS, INPUT_EMPLOYEE_DEBOUNCE_MS } from '@/app/bi/constants/trips.ts';

import { ITagFilter } from '@/app/bi/models/shared.ts';
import { ITripsFilter, ITripsFilterRequest } from '@/app/bi/models/tripsTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('trips:title'),
  SUM_OF_ALL_TRIPS: getText('trips:sumOfAllTrips'),
  OPEN_TRIPS_LIST: getText('trips:openTripsList'),
  MINE_CHECKBOX: getText('trips:mineCheckbox'),
  EMPTY_TRIPS_SCREEN: getText('trips:emptyTripsScreen'),
};

const TripsPage = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const filterState = useAppSelector((state) => state.tripsSlice.filter);
  const updateFilter = tripsSlice.actions.updateFilter;
  const [
    filter,
    setFilters,
  ] = useState(location.state?.preserveFilters ? filterState : null);
  const [isChangeDate, setIsChangeDate] = useState(false);
  const skipDefaultTripsFilter = !!filter && !!location.state?.preserveFilters;
  const [filterStatus, setFilterStatus] = useState<ITagFilter[]>([]);
  const [queryEmployee, setQueryEmployee] = useState<string>('');
  const [queryCities, setQueryCities] = useState<string>('');

  const debouncedQueryEmployeeValue = useDebounce(queryEmployee, INPUT_EMPLOYEE_DEBOUNCE_MS);
  const debouncedQueryCitiesValue = useDebounce(queryCities, INPUT_EMPLOYEE_DEBOUNCE_MS);

  const {
    data: autocompleteEmployeeList = [],
  } = useEmployeesAutocompleteQuery(debouncedQueryEmployeeValue);

  const {
    data: autocompleteCitiesList = [],
  } = useCitiesAutocompleteQuery(debouncedQueryCitiesValue);

  const {
    isLoading: isLoadingDefaultTrips,
  } = useGetDefaultTripsFilterQuery(undefined, { skip: skipDefaultTripsFilter });

  const filterData = {
    ...filter,
    employees: filter?.employees?.map((employee) => employee.id) ?? [],
  } as ITripsFilterRequest;

  const {
    data,
    isLoading: isLoadingTrips,
  } = useGetTripsQuery(filterData, { skip: !filter });

  useEffect(() => {
    if (!filter && !isLoadingDefaultTrips) {
      setFilters(filterState);
    }

    if (filter) {
      const statuses = updateFilterStatus(filter, autocompleteCitiesList);

      setFilterStatus(statuses);
    }
  }, [filterState, isLoadingDefaultTrips]);

  const handleChangeFilters = (value: ITripsFilter) => {
    setFilters({ ...value });

    dispatch(updateFilter(value));
  };

  const handleResetAllFilter = () => {
    setFilters(DEFAULT_FILTERS_TRIPS);
    setFilterStatus([]);
  };

  if ((isLoadingDefaultTrips && isLoadingTrips) || !filter || !data) {
    return <PageLoader text={ LABELS.OPEN_TRIPS_LIST } />;
  }

  if (!data?.items.length && isEmptyTripsScreen(filter) && !isChangeDate) {
    return (
      <div className={ styles.empty_data_panel }>
        <EmptyDataPanel title={ LABELS.EMPTY_TRIPS_SCREEN } />
      </div>
    );
  }

  const handleSelectPage = (pageNumber: number) => {
    setFilters(({ ...filter, page: pageNumber }));
    scrollToTop();
  };

  const handleDeleteItemStatus = (value: ITagFilter) => {
    const updatedFilterStatus = deleteFilterStatus(value, filterStatus);
    const updatedFilterValues = deleteFilterValues(value, filter);

    setFilters(updatedFilterValues);
    setFilterStatus(updatedFilterStatus);
  };

  const priceValues = data.totalAmount.toString();

  return (
    <div className={ styles.container }>
      <TripsFilters
        filtersValues={ filter }
        employeeAutocomplete={ autocompleteEmployeeList }
        citiesAutocomplete={ autocompleteCitiesList }
        inputValueEmployee={ queryEmployee }
        inputValueCities={ queryCities }
        setFilters={ handleChangeFilters }
        setInputEmployee={ setQueryEmployee }
        setInputCities={ setQueryCities }
        onClearFilter={ handleResetAllFilter }
        setIsChangeDate={ setIsChangeDate }
      />
      <div className={ styles.wrap }>
        <div className={ styles.container_info_trips }>
          <div className={ styles.info_trip }>
            <div className={ styles.info_title }>{LABELS.TITLE}</div>
            <div className={ styles.info_description }>
              <Text type='bold_18'>{LABELS.SUM_OF_ALL_TRIPS}:&nbsp;</Text>
              <Price
                marginSmall
                typeCurrency='bold_18'
                type='bold_18'
                costType={ CURRENCY_SYMBOLS[ECurrencyCode.TRY] }
                value={ priceValues }
              />
            </div>
          </div>
        </div>
        <FilterStatus
          items={ filterStatus }
          onClick={ handleDeleteItemStatus }
          onReset={ handleResetAllFilter }
        />
        <div className={ styles.trip_view_container }>
          <div className={ styles }>
            <ListTrips data={ data?.items } loading={ isLoadingTrips } />
          </div>
          <Paginate
            page={ filter.page }
            total={ data.itemsCount }
            itemsPerPage={ data.pageLimit }
            onChange={ handleSelectPage }
          />
        </div>
      </div>
    </div>
  );
};

export { TripsPage };
