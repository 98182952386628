import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import React, { ReactNode, useEffect, useState } from 'react';
import { Button, ItemLoader, PageLoader, Paginate, Price, Tag, Text, Tooltip } from 'yw-ui';

import { getText } from '@/i18n';

import { useLazyGetReportsQuery } from '@/app/bi/api/reportsApi.ts';
import { useDownloadMutation, useLazyGetReportIdQuery } from '@/app/bi/api/documentApi.ts';
import { reportsSlice } from '@/app/store/redusers/ReportsSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';

import { Table } from '@/app/components/Table';
import { EmptyDataPanel } from '@/app/components/EmptyDataPanel';
import { FilterInvoice } from '@/app/pages/Reports/components/Filter';

import { downloadFile } from '@/app/bi/utils/downloads.ts';
import { getServiceNameByType } from '@/app/bi/utils/settings.ts';
import { formatDate, getDayjs } from '@/app/bi/utils/formatDate.ts';
import { notification } from '@/app/bi/utils/not.ts';

import ROUTES from '@/app/bi/constants/routes.ts';
import { BUTTON_CLEAR, ENotificationActionType } from '@/app/bi/constants/settings.ts';
import { PATTERN } from '@/app/bi/constants/dateFormats.ts';
import { OPERATION_TYPE, OPERATION_TYPE_MIRROR } from '@/app/bi/constants/invoice.ts';
import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy.ts';

import { EOperationTypesFields, IReportFilterResponse } from '@/app/bi/models/reportsTypes.ts';
import { ITripPreview } from '@/app/bi/models/tripsTypes.ts';
import { ICompany, IFilterItem } from '@/app/bi/models/app.ts';
import { EDocumentType, EPrefixFileName } from '@/app/bi/models/documents.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './styles/index.module.css';

const LABELS = {
  DOWNLOAD_PDF: getText('reports:downloadPDF'),
  TRIP: getText('reports:tableInvoice.trip'),
  OPERATION: getText('reports:tableInvoice.operation'),
  COMPANY: getText('reports:tableInvoice.company'),
  DATE: getText('reports:tableInvoice.date'),
  AMOUNT_RU: getText('reports:tableInvoice.amount'),
  TOOLTIP_DATE: getText('reports:invoice.tooltip.dates'),
  TOOLTIP_HUMAN: getText('reports:invoice.tooltip.human'),
  TOOLTIP_SERVICES: getText('reports:invoice.tooltip.services'),
  TOOLTIP_EMPLOYEE: getText('reports:invoice.tooltip.employees'),
  TOOLTIP_GO_TO_TRIP: getText('reports:invoice.tooltip.goToTrip'),
  INVOICE_TITLE: getText('reports:invoice.title'),
  LOADING_PAGE_REPORTS: getText('reports:loadingPageReports'),
  EMPTY_PANEL: {
    TITLE: getText('reports:emptyPanel.title'),
    SUB_TITLE: getText('reports:emptyPanel.subTitle'),
    BUTTON_TITLE: getText('reports:emptyPanel.buttonTitle'),
  },
  NOTIFICATION: {
    TITLE: getText('reports:notification.title'),
    DESCRIPTION: getText('reports:notification.description'),
  },
};

const initialState: IReportFilterResponse = {
  items: null,
  filterRequest: {
    filterSort: {
      sortedBy: 'TripName',
      descending: true,
    },
    companies: [],
    operationTypes: [],
    operationDate: {
      min: null,
      max: null,
    },
    page: 1,
  },
  page: 1,
  pages: 1,
  pageLimit: 10,
  itemsCount: 0,
};

const Reports = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    setCompany,
    setCompanies,
    setOperationTypes,
    setOperationTypesArray,
    setPageNumber,
    clearAllFilters,
    deleteItemFilterStatus,
  } = reportsSlice.actions;

  const {
    page,
    companies,
    filterSort,
    operationTypes,
  } = useAppSelector((state) => state.reportsSlice);
  const { locale } = useAppSelector((state) => state.appSlice);
  const {
    account: {
      companies: companyApp,
    },
  } = useAppSelector((state) => state.appSlice);

  const [
    getReports, {
      isLoading,
      isError,
    },
  ] = useLazyGetReportsQuery();

  const [getReportDocumentId] = useLazyGetReportIdQuery();
  const [downloadDocument] = useDownloadMutation();

  const [
    loadingOperationId,
    setLoadingOperationId,
  ] = useState<string | null>(null);

  const arrayColumn = ['№', LABELS.TRIP, LABELS.OPERATION, LABELS.COMPANY, LABELS.DATE, LABELS.AMOUNT_RU, ' '];
  const arrayOperationData = [
    OPERATION_TYPE.get(EOperationTypesFields.Incoming) as string,
    OPERATION_TYPE.get(EOperationTypesFields.Outcoming) as string,
    OPERATION_TYPE.get(EOperationTypesFields.Booking) as string,
    OPERATION_TYPE.get(EOperationTypesFields.Change) as string,
  ];
  const [
    reportsData,
    setReportsData,
  ] = useState<IReportFilterResponse>(initialState);

  const getReportsData = async () => {
    const transformArrayCompanies = companies.reduce<string[]>((
      accumulator: string[],
      currentValue,
    ) => {
      if (currentValue.checked) {
        accumulator.push(currentValue.label);
      }

      return accumulator;
    }, []);

    const transformArrayOperationTypes = operationTypes.reduce((
      accumulator: string[],
      currentValue,
    ) => {
      if (currentValue.checked) {
        const getTypeOperate = OPERATION_TYPE_MIRROR.get(currentValue.label);

        accumulator.push(getTypeOperate as string);
      }

      return accumulator;
    }, []);

    try {
      const result = await getReports({
        operationTypes: transformArrayOperationTypes as EOperationTypesFields[],
        companies: transformArrayCompanies,
        filterSort,
        operationDate: {
          // TODO Поправить фильтр по дате позже
          min: dayjs('1970-12-22T08:02:47.043Z'),
          max: getDayjs(),
        },
        page,
      }).unwrap();

      setReportsData(result);
    } catch (e) {

    }
  };

  useEffect(() => {
    setReportsData(initialState);

    if (companies.length) {
      getReportsData();
    }
  }, [companies, operationTypes, page]);

  const transformArrayCompanies = (arrayCompanies: ICompany[]) => arrayCompanies.map((
    label,
  ) => ({
    label: label.name,
    checked: false,
  }));

  const transformArrayOperationTypes = (arrayCompanies: string[]) => arrayCompanies.map((
    label,
  ) => ({
    label,
    checked: false,
  }));

  useEffect(() => {
    const newArrayCompanies = transformArrayCompanies(companyApp);
    const newArrayOperationTypes = transformArrayOperationTypes(arrayOperationData);

    dispatch(setCompanies(newArrayCompanies));
    dispatch(setOperationTypesArray(newArrayOperationTypes));
  }, [companyApp]);

  if (isLoading) {
    return <PageLoader text={ LABELS.LOADING_PAGE_REPORTS } />;
  }

  const handleDownloadPdf = async (reportId: string, invoiceNumber: string) => {
    setLoadingOperationId(reportId);

    try {
      const documentId = await getReportDocumentId({
        operationId: reportId,
        locale,
        fileType: EDocumentType.Pdf,
      }).unwrap();

      const responseFile = await downloadDocument(documentId).unwrap();

      await downloadFile({
        fileBlob: responseFile,
        fileType: EDocumentType.Pdf,
        filenamePrefix: EPrefixFileName.Invoice,
        identifier: invoiceNumber,
      });

      setLoadingOperationId(null);
    } catch (error) {
      notification({
        title: LABELS.NOTIFICATION.TITLE,
        content: (
          <Text type='NORMAL_16' color='gray-7'>
            {LABELS.NOTIFICATION.DESCRIPTION}
          </Text>
        ),
        type: ENotificationActionType.error,
      });
    } finally {
      setLoadingOperationId(null);
    }
  };

  const handleDeleteItemFilterStatus = (item: IFilterItem) => dispatch(deleteItemFilterStatus(item));

  const handleClickButtonEmptyPanel = () => navigate(ROUTES.HOMEPAGE);

  const handleLinkGoTrip = (tripId: string) => window.open(`${ROUTES.TRIP.MAIN}/${tripId}`);

  const handleClearFilter = () => dispatch(clearAllFilters());

  const handleSelectPage = (pageNumber: number) => dispatch(setPageNumber(pageNumber));

  const handleSetCompany = (selectItem: IFilterItem) => dispatch(setCompany(selectItem));

  const handleSetOperationTypes = (value: IFilterItem) => {
    dispatch(setOperationTypes(value));
  };

  const formationArrayFilterStatus = (): IFilterItem[] => [...companies, ...operationTypes];

  const renderFilterStatus = () => {
    const arrayFilterStatus = formationArrayFilterStatus();

    const isSelectFilter = arrayFilterStatus.reduce((
      accumulator: IFilterItem[],
      currentValue,
    ) => {
      if (currentValue.checked) {
        accumulator.push(currentValue);
      }

      return accumulator;
    }, []);

    if (!isSelectFilter.length) return null;

    return (
      <div className={ styles.filter_status_container }>
        {isSelectFilter.map((statusItem, index) => (
          <Tag
            key={ `tag_${index}` }
            label={ statusItem.label }
            onClick={ () => handleDeleteItemFilterStatus(statusItem) }
          />
        ))}
        <Tag
          label={ BUTTON_CLEAR }
          deleteAll
          className={ styles.button_clear_filter }
          onClick={ handleClearFilter }
        />
      </div>
    );
  };

  const renderTooltipContent = ({
    id,
    name,
    checkinDate,
    checkoutDate,
    serviceTypes,
    employees,
  }: ITripPreview): ReactNode => {
    const formationFromData = formatDate(checkinDate, PATTERN.DEFAULT_PATTERN_FILTER);
    const formationToData = formatDate(checkoutDate, PATTERN.DEFAULT_PATTERN_FILTER);

    const formationServicesNames = getServiceNameByType(serviceTypes);

    const countEmployees = employees.length || '';

    return (
      <div className={ styles.container_tooltip_trip }>
        <Text color='white' type='SEMIBOLD_16'>{name}</Text>
        <div className={ styles.tooltip_trip_info }>
          <Text color='white' type='NORMAL_14'>
            {LABELS.TOOLTIP_SERVICES}: {formationServicesNames}
          </Text>
          <Text color='white' type='NORMAL_14'>
            {LABELS.TOOLTIP_DATE}: {formationFromData} — {formationToData}
          </Text>
          <Text color='white' type='NORMAL_14'>
            {LABELS.TOOLTIP_EMPLOYEE}: {countEmployees} {LABELS.TOOLTIP_HUMAN}
          </Text>
        </div>
        <Button
          type='outline'
          className={ styles.tooltip_trip_button }
          onClick={ () => handleLinkGoTrip(id) }
        >
          {LABELS.TOOLTIP_GO_TO_TRIP}
        </Button>
      </div>
    );
  };

  const renderTripNameTooltip = (nameTrip: string, trip: ITripPreview) => (
    <Tooltip
      show
      bodyClassName={ styles.tooltip }
      position='bottom'
      renderContent={ () => renderTooltipContent(trip) }
    >
      <div className={ styles.term_trip_name_container }>
        <span className={ styles.term_trip_name }>{nameTrip}</span>
      </div>
    </Tooltip>
  );

  const renderTableRows = (arrayReports: IReportFilterResponse) => arrayReports.items?.map((
    report,
    index,
  ) => {
    const {
      operationId,
      companyId,
      number,
      companyName,
      operationDate,
      operationType,
      trip,
      amount,
    } = report;

    const tripName = trip && renderTripNameTooltip(trip.name, trip);
    const tripOperationDate = formatDate(operationDate, PATTERN.DEFAULT_PATTERN_FILTER);
    const tripTypes = OPERATION_TYPE.get(EOperationTypesFields[operationType]);

    const amountPrice = Math.abs(amount);

    return (
      <tr key={ `tr_${companyId}_${index}` }>
        <td>{number}</td>
        <td>{tripName}</td>
        <td>{tripTypes}</td>
        <td>
          <span className={ styles.table_company_name }>
            {companyName}
          </span>
        </td>
        <td>{tripOperationDate}</td>
        <td>
          <div className={ styles.amount_price_container }>
            <Price value={ amountPrice } type='NORMAL_14' costType={ CURRENCY_SYMBOLS[ECurrencyCode.TRY] } />
          </div>
        </td>
        <td className={ styles.button_download }>
          <Button
            type='text'
            onClick={ () => handleDownloadPdf(operationId, number || '') }
          >
            {loadingOperationId === operationId ? (
              <div className={ styles.loading_container }>
                <ItemLoader />
              </div>
            ) : LABELS.DOWNLOAD_PDF}
          </Button>
        </td>
      </tr>
    );
  });

  const renderReport = () => {
    if (isLoading) {
      return <PageLoader />;
    }

    if (isError) {
      return <div>Ошибка</div>;
    }

    if (reportsData.items && !reportsData.items.length && !isLoading) {
      return (
        <div className={ styles.empty_panel }>
          <EmptyDataPanel
            title={ LABELS.EMPTY_PANEL.TITLE }
            subTitle={ LABELS.EMPTY_PANEL.SUB_TITLE }
            buttonTitle={ LABELS.EMPTY_PANEL.BUTTON_TITLE }
            onChangeButton={ handleClickButtonEmptyPanel }
          />
        </div>
      );
    }

    return <Table column={ arrayColumn } rows={ () => renderTableRows(reportsData) }/>;
  };

  return (
    <div className={ styles.content_container }>
      <FilterInvoice
        companies={ companies }
        operationType={ operationTypes }
        onChangeCompanies={ handleSetCompany }
        onChangeOperationType={ handleSetOperationTypes }
      />
      <div className={ styles.wrapper }>
        <div className={ styles.container }>
          <div className={ styles.status_container }>
            <h1 className={ styles.info_title }>{LABELS.INVOICE_TITLE}</h1>
            {renderFilterStatus()}
          </div>
          <div className={ styles.table_container }>
            <div className={ styles.table }>
              {renderReport()}
            </div>
            <Paginate
              page={ page }
              total={ reportsData.itemsCount }
              itemsPerPage={ reportsData.pageLimit }
              onChange={ handleSelectPage }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Reports };
