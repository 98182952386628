import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { UseFieldArrayAppend, useFormContext } from 'react-hook-form';
import { IconButton } from 'yw-ui';

import { BONUS_CARDS } from '../../constant';
import { bonusCardSchema, SchemaType } from '../../schema';

const createLabels = (t: TFunction) => ({
  ADD_CARD: t('settings:bonusCard.addCard'),
});

const MAX_FIELD_COUNT = 100;

interface AddButtonProps {
  fieldsCount: number;
  append: UseFieldArrayAppend<SchemaType>;
}

const AddButton = ({ fieldsCount, append }: AddButtonProps) => {
  const { watch } = useFormContext<SchemaType>();
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const bonus = watch(`${BONUS_CARDS}.${fieldsCount - 1}`);
  const isValid = bonusCardSchema.safeParse(bonus).success;

  return (
    <IconButton
      iconType='add'
      iconColor='blue1'
      textColor='blue-1'
      onClick={ () => append({ airlineCode: '', number: '' }) }
      disabled={ !isValid || fieldsCount > MAX_FIELD_COUNT - 1 }
    >
      {LABELS.ADD_CARD}
    </IconButton>
  );
};

export default AddButton;
