import { currentLng } from '@/i18n';

import { ywApiPrivate } from './index.ts';

import { IAirlineInfo } from '../models/tripsTypes.ts';

export interface IAirlineAutocompleteRequest {
  locale: string,
  query: string
}

export const airlineInfo = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getAirlineInfo: build.query<IAirlineInfo[], null>({
      query: () => ({
        url: `/airlines/${currentLng}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAirlineInfoQuery } = airlineInfo;
