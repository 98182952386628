import React from 'react';

import { HotelSidePanels } from '@/app/pages/Cart/components/SidePanels/components/HotelSidePanels';
import { AirlineSidePanel } from '@/app/pages/Cart/components/SidePanels/components/AirlineSidePanel';

import { SERVICE_TYPE } from '@/app/bi/constants/serviceType.ts';

import {
  TCartResponseItem,
  ICartResponseHotelItem,
  ICartResponseAirlineItem,
} from '@/app/bi/models/cart.ts';

interface SidePanelsProps {
  serviceType: string,
  item: TCartResponseItem,
}
const SidePanels = ({ serviceType, item }: SidePanelsProps) => {

  const renderItem = () => {
    switch (serviceType) {
      case SERVICE_TYPE.AIR:
        return <AirlineSidePanel item={ item as ICartResponseAirlineItem }/>;
      case SERVICE_TYPE.HOTEL:
        return <HotelSidePanels item={ item as ICartResponseHotelItem } />;
    }

    return null;
  };

  return renderItem();
};

export { SidePanels };
