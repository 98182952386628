import React, { useEffect, useMemo, useState } from 'react';
import { Button, PageLoader, Text } from 'yw-ui';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import {
  useLazyGetCartQuery,
  useCartDeleteMutation,
  useRemoveCartItemMutation,
  useAddCartItemByTripMutation,
  useAddEmployeeToCartItemMutation,
  useUpdateEmployeeCartItemMutation,
  useRemoveEmployeeToCartItemMutation,
  useUpdateCompanyHotelCartItemMutation,
} from '@/app/bi/api/cartApi.ts';
import { setRenderFn, setShow } from '@/app/store/redusers/SideSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import {
  useLazyGetBookingStatusQuery,
  useLazyGetBookingAccessStatusQuery,
} from '@/app/bi/api/bookingApi.ts';
import {
  resetStore,
  removeTripItem,
  updateTripItem,
  updateCartError,
  updateCartErrors,
  setShowErrorDialog,
} from '@/app/store/redusers/CartSlice.ts';

import { TotalBlock } from '@/app/components/TotalBlock';
import { CartItem } from '@/app/pages/Cart/components/CartItem';
import { EmptyCart } from '@/app/pages/Cart/components/EmptyCart';
import { SidePanels } from '@/app/pages/Cart/components/SidePanels';
import { DialogRemoveAll } from '@/app/pages/Cart/components/DialogRemoveAll';
import { BookingErrorDialog } from '@/app/pages/Checkout/components/BookingErrorDialog';

import { notification } from '@/app/bi/utils/not.ts';

import ROUTES from '@/app/bi/constants/routes.ts';
import ANIMATION from '@/app/bi/constants/animation.ts';
import { ENotificationActionType } from '@/app/bi/constants/settings.ts';

import {
  IAddCartEmployeeRequest,
  IDeleteTripItemObj,
  IRemoveEmployeesRequest,
  IUpdateCartEmployeeRequest,
  IUpdateTripRequest,
  TCartResponseItem,
} from '@/app/bi/models/cart.ts';
import { EBookProgressStatus, EBookStatus } from '@/app/bi/models/booking.ts';

import styles from './index.module.css';

const createLabels = (t: TFunction) => ({
  TITLE: t('cart:title'),
  LOADING: t('cart:loader'),
  REMOVE_ALL: t('components:tripName.removeAll'),
  ERROR_DIALOG_TITLE: t('cart:errorDialogTitle'),
  CLEAR_CART_ERROR_TITLE: t('cart:clearCartErrorTitle'),
});

const CartPage = () => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [
    allItemsLoading,
    setAllItemsLoading,
  ] = useState(false);
  const [
    isCartLoading,
    setIsCartLoading,
  ] = useState(true);
  const [
    bookingLoading,
    setBookingLoading,
  ] = useState(false);
  const [
    allItemsAnimatedClass,
    setAllItemsAnimatedClass,
  ] = useState('');
  const [showDialog, setShowDialog] = useState(false);

  const {
    airlinesPrice,
    hotelsPrice,
    totalPrice,
    unlock,
    showErrorDialog,
    bookingErrors,
    items,
    showHotelsPrice,
    showAirlinesPrice,
  } = useAppSelector((state) => state.cartSlice);
  const { progressStatus } = useAppSelector((state) => state.checkoutSlice);

  const [getBookingAccessStatus, {
    isLoading: isLoadingBookingAccessStatus,
  }] = useLazyGetBookingAccessStatusQuery();
  const [getCart] = useLazyGetCartQuery({
    refetchOnReconnect: true,
  });
  const [getBookingStatus] = useLazyGetBookingStatusQuery();
  const [deleteAll, {
    isLoading: loadingDeleteAll,
  }] = useCartDeleteMutation();
  const [addEmployeeToCart] = useAddEmployeeToCartItemMutation();
  const [removeEmployeeAirline] = useRemoveEmployeeToCartItemMutation();
  const [updateDataEmployee] = useUpdateEmployeeCartItemMutation();
  const [removeCartItem] = useRemoveCartItemMutation();
  const [addCartItemByTrip] = useAddCartItemByTripMutation();
  const [updateCompanyHotelItem] = useUpdateCompanyHotelCartItemMutation();

  const initSearch = async () => {
    setIsCartLoading(true);

    try {
      const bookingStatus = await getBookingStatus().unwrap();

      if (bookingStatus.status === EBookStatus.Ready) {
        await getCart().unwrap();
      } else {
        setBookingLoading(true);
      }
    } catch (e) {} finally {
      setIsCartLoading(false);
    }
  };

  const loadCarts = async () => getCart().unwrap();

  useEffect(() => {
    initSearch();

    return () => {
      dispatch(resetStore());
    };
  }, []);

  useEffect(() => {
    if (progressStatus === EBookProgressStatus.Failed || progressStatus === EBookProgressStatus.Booked) {
      setBookingLoading(false);

      loadCarts();
    }
  }, [progressStatus]);

  const renderDetails = (serviceType: string, item: TCartResponseItem) => (
    <SidePanels serviceType={ serviceType } item={ item } />
  );

  const handleShowDetails = (serviceType: string, item: TCartResponseItem) => {
    dispatch(setShow(true));
    dispatch(setRenderFn(() => renderDetails(serviceType, item)));
  };

  const handleClearCart = async () => {
    setShowDialog(false);
    setAllItemsLoading(true);

    try {
      await deleteAll().unwrap();

      setAllItemsAnimatedClass(ANIMATION.DELETE_CART);

      setTimeout(async () => {
        await getCart().unwrap();
        setAllItemsAnimatedClass('');
        setAllItemsLoading(false);
      }, 1000);
    } catch (e) {
      notification({
        title: LABELS.CLEAR_CART_ERROR_TITLE,
        type: ENotificationActionType.error,
      });

      setAllItemsLoading(false);
      setAllItemsAnimatedClass('');
    }
  };

  const handleDeleteTripItem = async ({
    serviceType,
    tripItemId,
    itemId,
  }: IDeleteTripItemObj) => removeCartItem({
    serviceType,
    tripItemId,
  }).unwrap().then(() => dispatch(removeTripItem({ itemId })));

  const handleAddEmployee = async (data: IAddCartEmployeeRequest) =>
    addEmployeeToCart(data).unwrap().then((res) => {
      dispatch(updateCartError(res.id));
      dispatch(updateTripItem(res));
    });

  const handleRemoveEmployee = async (data: IRemoveEmployeesRequest) =>
    removeEmployeeAirline(data).unwrap().then((res) => dispatch(updateTripItem(res)));

  const handleUpdateDataEmployee = (data: IUpdateCartEmployeeRequest) =>
    updateDataEmployee(data).unwrap().then((res) => dispatch(updateTripItem(res)));

  const handleUpdateCompany = ({ tripItemId, companyId }: { tripItemId: string, companyId: number | null }) =>
    updateCompanyHotelItem({ tripItemId, companyId }).unwrap()
      .then((res) => dispatch(updateTripItem(res)));

  const handleAddCartItemToTrip = async (data: IUpdateTripRequest) =>
    addCartItemByTrip(data).unwrap().then(() => loadCarts());

  const handlePurchaseCart = async () => {
    try {
      const res = await getBookingAccessStatus().unwrap();

      dispatch(updateCartErrors(res));

      if (!res.hasErrors) {
        navigate(`${ROUTES.CART.CHECKOUT}`);
      }
    } catch (e) {}
  };

  const handleClose = async () => {
    setIsCartLoading(true);

    try {
      await getCart().unwrap();
    } catch (e) {} finally {
      setIsCartLoading(false);
    }

    dispatch(setShowErrorDialog(!showErrorDialog));
  };

  const renderLoading = () => <PageLoader text={ LABELS.LOADING } />;

  const renderEmpty = () => <EmptyCart />;

  const renderCartErrorDialog = () => (
    <BookingErrorDialog
      show={ showErrorDialog }
      title={ LABELS.ERROR_DIALOG_TITLE }
      bookingErrors={ bookingErrors }
      onClose={ handleClose }
    />
  );

  const renderTrips = () => items.map((item) => (
    <CartItem
      key={ item.id }
      item={ item }
      errors={ bookingErrors.cartItemErrors }
      allItemsLoading={ allItemsLoading }
      allItemsAnimatedClass={ allItemsAnimatedClass }
      onShowDetails={ handleShowDetails }
      onDeleteItem={ handleDeleteTripItem }
      onAddEmployee={ handleAddEmployee }
      onUpdateDataEmployee={ handleUpdateDataEmployee }
      onUpdateCompany={ handleUpdateCompany }
      onRemoveEmployee={ handleRemoveEmployee }
      onAddCartItemToTrip={ handleAddCartItemToTrip }
    />
  ));

  const renderRemoveAll = () => {
    if (!items.length) return null;

    return (
      <Button type='text' onClick={ () => setShowDialog(true) }>
        {LABELS.REMOVE_ALL}
      </Button>
    );
  };

  const renderContent = () => {
    if (!items.length) return renderEmpty();

    return (
      <>
        <div className={ styles.content }>
          <div className={ styles.items_wrapper }>
            {renderTrips()}
          </div>
          <div className={ styles.total_block_wrapper }>
            <TotalBlock
              loading={ isLoadingBookingAccessStatus }
              isCart
              showHotelsPrice={ showHotelsPrice }
              showAirlinesPrice={ showAirlinesPrice }
              hotelsPrice={ hotelsPrice }
              airlinesPrice={ airlinesPrice }
              totalPrice={ totalPrice }
              disabledButton={ !unlock }
              onClick={ handlePurchaseCart }
            />
          </div>
        </div>
        <DialogRemoveAll
          loading={ loadingDeleteAll }
          show={ showDialog }
          onShowDialog={ setShowDialog }
          onDelete={ handleClearCart }
        />
      </>
    );
  };

  if (isCartLoading || bookingLoading) return renderLoading();

  const bookingErrorDialogHtml = showErrorDialog && renderCartErrorDialog();

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.header }>
        <Text
          type='bold_32'
          fontWeight={ 700 }
          lineHeight='110%'
        >
          {LABELS.TITLE}
        </Text>
        {renderRemoveAll()}
      </div>
      {renderContent()}
      {bookingErrorDialogHtml}
    </div>
  );
};

export { CartPage };
